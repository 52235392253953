import { format } from 'date-fns';
import { TStatusPayment, TypeTransfer } from '@app/dir_group_assignor/payments/modelsForPayment';
import { IForTable } from '@components/_table/meTable';
import { preparePropertyFromDropForSendToServer } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { UtilsService } from '@services/utils.service';

export enum PAYMENTS_STATUS {
  // ---- grey
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  READY = 'READY',

  // ---- red
  TRANSFER_FAILED = 'TRANSFER_FAILED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  REFUNDED = 'REFUNDED',
  TRANSFER_REFUNDED = 'TRANSFER_REFUNDED',
  TRANSFER_REFUND_FAILED = 'TRANSFER_REFUND_FAILED',
  REFUND_FAILED = 'REFUND_FAILED',
  REFUND_PENDING = 'REFUND_PENDING',

  // ---- green
  SUCCEEDED = 'SUCCEEDED',
}

export enum TRANSFER_FIELDS {
  ID = 'id',
  COMPETITION_ID = 'competitionId',
  COMPETITION_NAME = 'competitionName',
  GAME_ID = 'gameId',
  LEVEL = 'level',
  GAME_AGE_DESCRIPTION = 'gameAgeDescription',
  GENDER = 'gender',
  STATUS = 'status',
  STATUS_FORMATTED = 'statusFormatted',
  TYPE = 'type',
  CREATED_AT = 'createdAt',
  FINALIZE_AT = 'finalizedAt',
  TOTAL = 'total',
  OFFICIAL_POSITION_NAME = 'officialPositionName',
  GAME_NUMBER = 'gameNumber',
  CREATED_AT_FORMATTED = 'createdAtFormatted',
  ERROR_MESSAGE = 'errorMessage',

  // CHECKED = 'checked', // !!! for checkBox
  IS_SELECT = 'isSelect', // !!! for checkBox
}

export enum TRANSFER_STATUS_NAME {
  FAILED = 'Failed',
  PAID = 'Paid',
  NOT_PAID = 'Not Paid',
}

export class TransferModel {
  [TRANSFER_FIELDS.ID]?: string;
  [TRANSFER_FIELDS.COMPETITION_ID]?: string;
  [TRANSFER_FIELDS.COMPETITION_NAME]?: string;
  [TRANSFER_FIELDS.GAME_ID]?: string;
  [TRANSFER_FIELDS.LEVEL]?: string;
  [TRANSFER_FIELDS.GAME_AGE_DESCRIPTION]?: string;
  [TRANSFER_FIELDS.GENDER]?: string;
  [TRANSFER_FIELDS.STATUS]?: PAYMENTS_STATUS;
  [TRANSFER_FIELDS.TYPE]?: TypeTransfer;
  [TRANSFER_FIELDS.CREATED_AT]?: string;
  [TRANSFER_FIELDS.FINALIZE_AT]?: string;
  [TRANSFER_FIELDS.OFFICIAL_POSITION_NAME]?: string;
  [TRANSFER_FIELDS.GAME_NUMBER]?: number;
  [TRANSFER_FIELDS.TOTAL]?: number;
  [TRANSFER_FIELDS.CREATED_AT_FORMATTED]?: string;
  [TRANSFER_FIELDS.STATUS_FORMATTED]?: string;
  [TRANSFER_FIELDS.ERROR_MESSAGE]?: string;

  [TRANSFER_FIELDS.IS_SELECT]?: boolean; // for checkbox // CHECKED

  constructor(data: Partial<TransferModel> = {}) {
    Object.assign(this, data, {
      [TRANSFER_FIELDS.CREATED_AT_FORMATTED]: data[TRANSFER_FIELDS.CREATED_AT]
        ? format(new Date(data[TRANSFER_FIELDS.CREATED_AT]), 'MMM d, yyy, HH:mm a')
        : '',
      [TRANSFER_FIELDS.STATUS_FORMATTED]: data[TRANSFER_FIELDS.STATUS]
        ? this.getStatusName(data[TRANSFER_FIELDS.STATUS])
        : '',
    });
  }

  getStatusName(status: PAYMENTS_STATUS): TRANSFER_STATUS_NAME {
    switch (status) {
      case PAYMENTS_STATUS.SUCCEEDED:
        return TRANSFER_STATUS_NAME.PAID;

      case PAYMENTS_STATUS.OPEN:
      case PAYMENTS_STATUS.PENDING:
      case PAYMENTS_STATUS.READY:
        return TRANSFER_STATUS_NAME.NOT_PAID;

      default:
        return TRANSFER_STATUS_NAME.FAILED;
    }
  }
}

export class ClassTransfer implements IForTable {
  id?: string;
  competitionId?: string;
  competitionName?: string;
  gameId?: string;
  level?: string;
  gameAgeDescription?: string;
  gender?: string;
  // status?: PAYMENTS_STATUS;
  status?: TStatusPayment;
  // status_formatted?: string;
  statusFormatted?: string;
  type?: TypeTransfer;

  createdAt?: string; // '11 May 2024 12:00 AM PDT',
  gameDate?: string;
  // createdAt_d_M_yyyy?: string; // 22-09-2023 'd-M-yyyy'
  createdAt_M_d_yyyy?: string; // 09-22-2023 'M-d-yyyy'
  createdAt_formatted?: string; // 'MMM d, yyy, HH:mm a'
  createdAt_formatted_2?: string; // September 19, 2023
  gameDate_formatted?: string;
  // createdAt_day?: number; // 11
  // createdAt_month?: number; // 11
  createdAt_isToday?: boolean;

  finalizedAt?: string;
  total?: number;
  total_formatted?: string;
  officialPositionName?: string;
  gameNumber?: number;
  createdAtFormatted?: string;
  errorMessage?: string;

  ageGenderLevel?: string; // this.otherS.getAgeGenderLevel()

  isSelect?: boolean; // for checkbox

  constructor(transfer?: ClassTransfer) {
    if (!transfer) return this;
    Object.entries(transfer)!.forEach((el) => {
      const key = el[0] as keyof ClassTransfer;
      this[key] = el[1];
    });

    if (transfer.createdAt) {
      // this.createdAt_d_M_yyyy = format(new Date(transfer.createdAt), 'd-M-yyyy');
      this.createdAt_M_d_yyyy = format(new Date(transfer.createdAt), 'M-d-yyyy');
      this.createdAt_formatted = format(new Date(transfer.createdAt), 'MMM d, yyy, HH:mm a');
      this.createdAt_formatted_2 = format(new Date(transfer.createdAt), 'MMMM d, yyyy'); // September 19, 2023
      // this.createdAt_day = +(transfer.createdAt.split(' ')[0]);
      this.createdAt_isToday = UtilsService.checkIsToday(transfer.createdAt)
    } else {
        console.error('MyWallet. ClassTransfer. transfer.createdAt :', transfer.createdAt, transfer)
    }

    if (transfer.gameDate) {
      this.gameDate_formatted = format(new Date(transfer.gameDate), 'MMM d, yyyy');
    }
    // if (transfer.status) this.status_formatted = ClassTransfer.getStatusName(transfer.status);
    if (typeof transfer.total === 'number') this.total_formatted = UtilsService.currencyPipe_transform((transfer.total / 100)) || '';

    return { ...transfer, ...this };
  }

  static getStatusName(status: PAYMENTS_STATUS): TRANSFER_STATUS_NAME {
    switch (status) {
      case PAYMENTS_STATUS.SUCCEEDED:
        return TRANSFER_STATUS_NAME.PAID;

      case PAYMENTS_STATUS.OPEN:
      case PAYMENTS_STATUS.PENDING:
      case PAYMENTS_STATUS.READY:
        return TRANSFER_STATUS_NAME.NOT_PAID;

      default:
        return TRANSFER_STATUS_NAME.FAILED;
    }
  }

// !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
// !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
// !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassTransfer): ClassTransfer {
    if (!obj) return {};
    const objForSendToServer: ClassTransfer = obj;

    delete obj.createdAt_formatted;
    // delete obj.status_formatted;
    delete obj.total_formatted;

    // !!! prepare Property Without Drop In Name
    // !!! если в конструкторе текущего класса вызывал создание другого класса, то здесь надо вызвать preparePropertyFromDropForSendToServer
    // objForSendToServer.competition = ClassCompetition.preparePropertyFromDropForSendToServer(obj.competition);
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

