import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainService } from '@services/main.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocationService, TFieldLoc } from '@components/__blocks/location/location.service';
import { CustomValidators } from '@classes/CustomValidators';
import { PhoneComponent } from '@components/__drop_inputs_matSelect/phone/phone.component';
import { LocationComponent } from '@components/__blocks/location/location.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { DeviceService } from '@services/device.service';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { ClassGroup } from '@models/IGroup';
import { ILocation } from '@models/location';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { UtilsService } from '@services/utils.service';

interface IPopupCreateGroup {
  name: FormControl<string>;
  companyLegalName: FormControl<string>;
  email: FormControl<string>;
  phone: FormControl<string>;
  prefixPhone: FormControl<string>;
  gameType: FormControl<string>;

  logo?: FormControl<string>;
  groupAddress?: FormControl<ILocation>; // GroupAddressDto
  groupAssignorId?: FormControl<string>;
}

@Component({
  selector: 'app-popup-create-group',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, FieldComponent, InputCtrlComponent, ReactiveFormsModule, PhoneComponent,
    LocationComponent, BtnWrapComponent, BtnComponent, SvgComponent, DropFormCtrlComponent, FormsModule],
  templateUrl: './popup-create-group.component.html',
  styleUrls: ['./popup-create-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupCreateGroupComponent {
  form!: FormGroup<IPopupCreateGroup>;
  @ViewChild('phoneComponent') phoneComponent?: PhoneComponent;

  @Input() fields: Array<TFieldLoc> = ['street', 'streetLine2', 'city', 'state', 'zipcode']; // список всех полей
  @Input() requiredFields: Array<TFieldLoc> = this.fields.filter(el => el !== 'streetLine2'); // список обязательных полей

  constructor(
    public mainS: MainService,
    public competitionS: CompetitionService,
    public meS: MeService,
    public otherS: OtherService,
    public dialogRef: MatDialogRef<PopupCreateGroupComponent>,
    private formBuilder: UntypedFormBuilder,
    public locationS: LocationService,
    public deviceS: DeviceService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public dataPopup?: any,
  ) {
    this.locationS.reset();
    this.locationS.setData();
    this.createForm();
  }

  sendForm(): void {
    this.form.markAllAsTouched();
    const locValid = this.locationS.checkErrorLoc(this.requiredFields);
    if (!locValid) return;
    const loc: ILocation = this.locationS.loc;
    const groupAddress: ILocation = { // GroupAddressDto
      street: loc.street,
      city: loc.city,
      state: loc.state,
      zipcode: loc.zipcode,
      streetLine2: loc.streetLine2,
      timezone: loc.timezone,
    };


    const formValue: any = this.form.getRawValue();
    delete formValue.mailingAddress;

    const sendObjGroup: ClassGroup = UtilsService.removeEmptyKeysFromObject(formValue);
    sendObjGroup.groupAddress = groupAddress;
    sendObjGroup.phone = this.phoneComponent?.value;

    this.dialogRef.close(sendObjGroup);
  }

  changePhone(phone: string): void {
  }

  // === FORM ==============
  private createForm(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      companyLegalName: ['', [Validators.required]],
      email: [this.meS.meEmail || '', [Validators.required, CustomValidators.patternEmail]],
      phone: [this.meS.mePhone || ''],
      prefixPhone: [''],
      gameType: [''],

      logo: [''],
      groupAddress: [''],
      groupAssignorId: [''],
    });
  }

  get ctrl(): IPopupCreateGroup {
    return this.form?.controls;
  }

  close(): void {
    this.dialogRef.close();
  }
}
