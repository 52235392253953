<div class='wrapperCompetitions'>
    <ng-container>
      <div class='wrapperCompetitionsBlock wrapperCompetitionsUsers' [formGroup]='form'>
        <div class='headerSetupCompetition'>
          <svgAndText text='New Users' leftSvgName='arrow_squareLeft&24' typeText='text1-blueDark'
          (leftSvgEmit)='navigateBackToMainPage()'></svgAndText>
          <span class="selected-competitions-count">{{ selectedCompetitions.length }} Competitions Selected</span>
        </div>

        <div formArrayName='arrControls' class='contentSetupCompetition'>
          <ng-container *ngFor='let item of arrControls?.controls; let idx=index; let first=first;  let last=last'>
            <div [formGroupName]='idx' class='contentSetupCompetition__itemUser contentSetupCompetition__itemUser--{{last?"last":""}}'>
              <div class='contentSetupCompetition__itemUser__fields'>
                <field text='Name or Email' svgName='question_grey&16'
                       matTooltipSVG='Search an existing group user by name or enter the email of the recipient.'>
                  <inputCtrl #elRef formControlName='name' placeholder='Name' [isFocus]='true'
                             [matMenuTriggerFor]='usersForDropDown?.length ? menuUsersForDropDown:null' #t='matMenuTrigger'
                             (changeVal)='searchUsersInCompetition($event,t)'>
                    <error *ngIf='checkExistError(item)' text='The user is not within the group'></error>
                  </inputCtrl>
                  <mat-menu #menuUsersForDropDown='matMenu' class="search-results-menu">
                    <div class="scrollable-menu">
                    <button *ngFor='let itemDrop of usersForDropDown' (click)='addNewUserFromDropDown(item, itemDrop)'
                            [style]='{width: "346px", maxWidth: "346px"}'>
                      {{itemDrop?.firstName || itemDrop?.name}}, {{itemDrop?.email}}
                    </button>
                    </div>
                  </mat-menu>
                </field>
                <field text='Role'>
                  <app-dropdown formControlName='roleDrop' [array]='arrUserRoleDrop_forInvite' placeholder='Role'>
                    <!-- <copyLink *ngIf='item.controls.roleDrop.value' path='competitions/users'
                              [userRole]='item.controls.roleDrop.value?.upperCase!'
                              [competition]='competitionS.competition' class='o-mg-t-8'></copyLink> -->
                  </app-dropdown>
                </field>

                <field text=' ' class='svgDelete'>
                  <meSvg [svgName]='first ? "delete_square2&44":"delete_square1&44"'
                         (click)='first ? null : form.controls.arrControls?.removeAt(idx)'></meSvg>
                </field>
              </div>
            </div>

            <div *ngIf='last' class='contentSetupCompetition__itemUser__btns'>
              <btnAddAnother page='competition' (emit)='addNew()'></btnAddAnother>
              <btn text='Customize your invitation' bcg='white' svgLeft='magicWand&20' (click)='openPopupCustomizeInvitation()'
                   [disabled]='reqPending'></btn>
              <btn text='Save & Send invitation' bcg='blueDark' (click)='inviteUsersInCompetition()'
                   [disabled]='!form.valid || reqPending || checkExistError(item)'></btn>
            </div>
          </ng-container>

        </div>
      </div>
    </ng-container>
  </div>