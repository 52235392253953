import { ILocation } from '@models/location';
import {
  ClassDrop,
  ClassYesNo,
  getArrayDropFromStringCaseSensitive,
  getDropFromNumber,
  getDropFromString,
  preparePropertyFromDropForSendToServer,
} from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { TUserRoleTitleCase, TUserRoleUpperCase } from '@models/user';
import { const_NA, TypeNA } from '@models/other';
import { UtilsService } from '@services/utils.service';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { TPath } from '@app/app.module';

// === NAVIGATION ===================================================
// !!! используются в CompetitionResolver
export type TTitleNavigationCompetition = 'About' | 'Official Labels' | 'Age Groups' | 'Levels' | 'Pay Scales' | 'Seasons'
  | 'Locations' | 'Teams' | 'Users';
export type TUrlNavigationCompetition = 'list' | 'about' | TKeyofArrInCompetition;

export type TUrlCompetitions = Extract<TPath, 'competitions'>;
export type TUrlCompetition_list = `${TUrlCompetitions}/${Extract<TUrlNavigationCompetition, 'list'>}`;
export type TUrlCompetition_about = `${TUrlCompetitions}/${Extract<TUrlNavigationCompetition, 'about'>}`;
export type TUrlCompetition_officialLabels = `${TUrlCompetitions}/${Extract<TUrlNavigationCompetition, 'officialLabels'>}`;
export type TUrlCompetition_ageGroups = `${TUrlCompetitions}/${Extract<TUrlNavigationCompetition, 'ageGroups'>}`;
export type TUrlCompetition_levels = `${TUrlCompetitions}/${Extract<TUrlNavigationCompetition, 'levels'>}`;
export type TUrlCompetition_payScales = `${TUrlCompetitions}/${Extract<TUrlNavigationCompetition, 'payScales'>}`;
export type TUrlCompetition_seasons = `${TUrlCompetitions}/${Extract<TUrlNavigationCompetition, 'seasons'>}`;
export type TUrlCompetition_locations = `${TUrlCompetitions}/${Extract<TUrlNavigationCompetition, 'locations'>}`;
export type TUrlCompetition_teams = `${TUrlCompetitions}/${Extract<TUrlNavigationCompetition, 'teams'>}`;
export type TUrlCompetition_users = `${TUrlCompetitions}/${Extract<TUrlNavigationCompetition, 'users'>}`;

export const urlCompetitions: TUrlCompetitions = 'competitions';
// export const urlCompetition_list: Extract<TUrlNavigationCompetition, 'list'> = 'list';
// export const urlCompetition_about: Extract<TUrlNavigationCompetition, 'about'> = 'about';
// export const urlCompetition_officialLabels: Extract<TUrlNavigationCompetition, 'officialLabels'> = 'officialLabels';
// export const urlCompetition_ageGroups: Extract<TUrlNavigationCompetition, 'ageGroups'> = 'ageGroups';
// export const urlCompetition_levels: Extract<TUrlNavigationCompetition, 'levels'> = 'levels';
// export const urlCompetition_payScales: Extract<TUrlNavigationCompetition, 'payScales'> = 'payScales';
// export const urlCompetition_seasons: Extract<TUrlNavigationCompetition, 'seasons'> = 'seasons';
// export const urlCompetition_locations: Extract<TUrlNavigationCompetition, 'locations'> = 'locations';
// export const urlCompetition_teams: Extract<TUrlNavigationCompetition, 'teams'> = 'teams';
// export const urlCompetition_users: Extract<TUrlNavigationCompetition, 'users'> = 'users';


export interface INavigationCompetition {
  title: TTitleNavigationCompetition;
  url: TUrlNavigationCompetition;
  methodCompetitions?: TMethodCompetitions;
}

export const arrNavigationCompetitions: Array<INavigationCompetition> = [
  { title: 'About', url: 'about' },
  { title: 'Official Labels', url: 'officialLabels', methodCompetitions: 'methodCompetitionOfficialLabels' }, //
  { title: 'Age Groups', url: 'ageGroups', methodCompetitions: 'methodCompetitionAgeGroups' },
  { title: 'Levels', url: 'levels', methodCompetitions: 'methodCompetitionLevels' },
  { title: 'Pay Scales', url: 'payScales', methodCompetitions: 'methodCompetitionPayScales' },
  { title: 'Seasons', url: 'seasons', methodCompetitions: 'methodCompetitionSeasons' },
  { title: 'Locations', url: 'locations', methodCompetitions: 'methodCompetitionLocations' },
  { title: 'Teams', url: 'teams', methodCompetitions: 'methodCompetitionTeams' },
  { title: 'Users', url: 'users', methodCompetitions: 'methodCompetitionUsers' },
];

export type TMethodCompetitions = Extract<keyof ApiCompetitionService, 'methodCompetitionOfficialLabels' | 'methodCompetitionAgeGroups'
  | 'methodCompetitionLevels' | 'methodCompetitionPayScales' | 'methodCompetitionSeasons' | 'methodCompetitionLocations'
  | 'methodCompetitionTeams' | 'methodCompetitionUsers'>;

export function getUrlNavigationCompetitionFromUrl(url: string): TUrlNavigationCompetition {
  const result = url.replace(urlCompetitions, '')?.split('/')?.filter(Boolean)[0] as TUrlNavigationCompetition;
  if (!result) {
    console.error('getUrlNavigationCompetitionFromUrl :', '  url :', url, '  result :', result);
  }
  return result;
}

export function getMethodCompetitionsByPage(page: TUrlNavigationCompetition): TMethodCompetitions | undefined {
  const result = arrNavigationCompetitions.find(el => el.url === page)?.methodCompetitions;
  if (!result) {
    console.log('getMethodCompetitionsByPage no have method :', '  page :', page, '  result :', result);
  }
  return result;
}

// !!! only for 'officialLabels' | 'ageGroups' | 'levels' | 'payScales' | 'seasons' | 'locations' | 'teams' | 'users'
export function getClassCompetitionsByUrl(urlNavigationCompetition: TUrlNavigationCompetition) {
  switch (urlNavigationCompetition) {
    case 'officialLabels':
      return ClassCompetitionOfficialLabels;
    case 'ageGroups':
      return ClassCompetitionAgeGroup;
    case 'levels':
      return ClassCompetitionLevel;
    case 'payScales':
      return ClassCompetitionPayScale;
    case 'seasons':
      return ClassCompetitionSeason;
    case 'locations':
      return ClassCompetitionLocation;
    case 'teams':
      return ClassCompetitionTeam;
    case 'users':
      return ClassCompetitionUser;

    default:
      console.error('getClassCompetitions urlNavigationCompetition:', urlNavigationCompetition);
      return null;
  }
}

export class ClassPayoutFormat extends ClassDrop {
  titleCase?: TPayoutFormat;
  upperCase?: TPayoutFormatUpperCase;

  constructor(payoutFormatUpperCase?: TPayoutFormatUpperCase) {
    super({});
    if (!payoutFormatUpperCase) return;
    const drop = getDropFromString(payoutFormatUpperCase, 'ClassPayoutFormat');
    this.titleCase = drop.titleCase as TPayoutFormat;
    this.upperCase = drop.upperCase as TPayoutFormatUpperCase;
    if (this.titleCase == 'Notch Pay') {
      this.upperCase = <any>'STRIPE';
    }

    // if (payoutFormatUpperCase === 'NOTCH PAY') drop.svgRightMatOption = 'lightning_gold&20';
    return { ...drop, ...this };
  }
}

export type TPayoutFormatUpperCase = 'NOTCH PAY' | 'OFFLINE';
export type TPayoutFormat = 'Notch Pay' | 'Offline';
export const arrPayoutFormatStringUpperCase: Array<TPayoutFormatUpperCase> = ['NOTCH PAY', 'OFFLINE',];
export const arrPayoutFormatString: Array<TPayoutFormat> = ['Notch Pay', 'Offline'];
export const arrPayoutFormatDrop = arrPayoutFormatStringUpperCase.map(el => new ClassPayoutFormat(el));


// === Game Period ========================================
export type TGamePeriodUpperCase = 'HALVES' | 'QUARTERS' | 'PERIODS';
export type TGamePeriod = 'Halves' | 'Quarters' | 'Periods';
export const arrGamePeriodString: Array<TGamePeriod> = ['Halves', 'Quarters', 'Periods'];
export type TGamePeriodDrop = ClassDrop & { titleCase: TGamePeriod, upperCase: TGamePeriodUpperCase };
export const arrGamePeriodDrop = arrGamePeriodString.map(el => getDropFromString(el, 'arrGamePeriodDrop')) as Array<TGamePeriodDrop>;

// === Game Types =================
export type TGameTypeUpperCase = 'LEAGUE' | 'PLAYOFF' | 'SCRIMMAGE' | 'TOURNAMENT' // | 'other'
export type TGameType = 'League' | 'Playoff' | 'Scrimmage' | 'Tournament' // | 'other'
export const arrGameTypeString: Array<TGameType> = ['League', 'Playoff', 'Scrimmage', 'Tournament'];
export type TGameTypeDrop = ClassDrop & { titleCase: TGameType, upperCase: TGameTypeUpperCase };
export const arrGameTypeDrop = arrGameTypeString.map(el => getDropFromString(el, 'arrGameTypeDrop')) as Array<TGameTypeDrop>;

// === Competition Type ==========
export type TCompetitionTypeUpperCase = 'REC YOUTH' | 'COMPETITIVE YOUTH' | 'AMATEUR / ADULT' | 'HIGH SCHOOL' | 'INTRAMURAL' | 'COLLEGE'
  | 'SEMI-PRO' | 'PRO';
export type TCompetitionType = 'Rec Youth' | 'Competitive Youth' | 'Amateur / Adult' | 'High School' | 'Intramural'
  | 'College' | 'Semi-Pro' | 'Pro'; // | 'other'
export const arrCompetitionTypeString: Array<TCompetitionType | string> = ['Rec Youth', 'Competitive Youth', 'Amateur / Adult', 'High School', 'Intramural', 'College', 'Semi-Pro', 'Pro'];
export type TCompetitionTypeDrop = ClassDrop & { titleCase: TCompetitionType, upperCase: TCompetitionTypeUpperCase };
export const arrCompetitionTypeDrop = arrCompetitionTypeString.map(el => getDropFromString(el, 'arrCompetitionTypeDrop')) as Array<TCompetitionTypeDrop>;

// === Sports =================
export type TSportUpperCase = 'BASKETBALL' | 'BASEBALL' | 'CHEER' | 'DANCE' | 'FOOTBALL' | 'FOOTBALL - FLAG' | 'GYMNASTICS'
  | 'ICE HOCKEY' | 'LACROSSE' | 'MARTIAL ARTS' | 'SOCCER' | 'SOFTBALL' | 'SWIMMING' | 'VOLLEYBALL' | 'WRESTLING';
export type TSport = 'Basketball' | 'Baseball' | 'Cheer' | 'Dance' | 'Football' | 'Football - Flag' | 'Gymnastics' | 'Ice Hockey'
  | 'Lacrosse' | 'Martial Arts' | 'Soccer' | 'Softball' | 'Swimming' | 'Volleyball' | 'Wrestling'; // | 'other'
export const arrSportString: Array<TSport> = ['Basketball', 'Baseball', 'Cheer', 'Dance', 'Football', 'Football - Flag', 'Gymnastics', 'Ice Hockey', 'Lacrosse', 'Martial Arts', 'Soccer', 'Softball', 'Swimming', 'Volleyball', 'Wrestling'];
export type TSportDrop = ClassDrop & { titleCase: TSport, upperCase: TSportUpperCase };
export const arrSportDrop = arrSportString.map(el => getDropFromString(el, 'arrSportDrop')) as Array<TSportDrop>;

// === Gender =============
export type TGender = 'Male' | 'Female' | 'Co-Ed' | TypeNA;
export type TGenderUpperCase = 'MALE' | 'FEMALE' | 'CO-ED' | TypeNA;
export const arrGenderString: Array<TGender> = ['Male', 'Female', 'Co-Ed', const_NA];
export type TGenderDrop = ClassDrop & { titleCase: TGender, upperCase: TGenderUpperCase };
export const arrGenderDrop = arrGenderString.map(el => getDropFromString(el, 'arrGenderDrop')) as Array<TGenderDrop>;

// === Competition ================================================
export const maximumCrewSizeCompetition = 10;
export type TKeyofClassCompetition = keyof ClassCompetition;
export type TKeyofArrInCompetition = Extract<TKeyofClassCompetition, 'officialLabels' | 'ageGroups' | 'levels' | 'payScales' | 'seasons' | 'locations' | 'teams' | 'users'>;

export class ClassCompetition extends ClassDrop {
  id?: string;
  competitionName?: string;
  sports?: string; // BASKETBALL,BASEBALL
  sportsDrop?: Array<TSportDrop>;
  competitionType?: string;
  competitionTypeDrop?: TCompetitionTypeDrop;
  gameType?: string;
  gameTypeDrop?: TGameTypeDrop;
  maxCrewSize?: number;
  maxCrewSizeDrop?: ClassDrop;
  creatorId?: string;
  groupId?: string;
  payoutFormat?: TPayoutFormatUpperCase;
  enableSelfRequest?: ClassYesNo;
  selfApplyLimit?: ClassDrop;
  payoutFormatDrop?: ClassPayoutFormat;
  locationsSize?: number;
  teamsSize?: number;

  officialLabels?: Array<ClassCompetitionOfficialLabels> = [];
  ageGroups?: Array<ClassCompetitionAgeGroup> = [];
  levels?: Array<ClassCompetitionLevel> = [];
  payScales?: Array<ClassCompetitionPayScale> = [];
  seasons?: Array<ClassCompetitionSeason> = [];
  locations?: Array<ClassCompetitionLocation> = [];
  teams?: Array<ClassCompetitionTeam> = [];
  users?: Array<ClassCompetitionUser> = [];

  subAssignors?: Array<string> = []; // !!! массив емайлов или имён
  admins?: Array<string> = []; // !!! массив емайлов или имён
  subAssignors_string?: string = ''; // !!! из массива subAssignors достал все емайлы через запятую
  admins_string?: string = ''; // !!! из массива admins достал все емайлы через запятую

  isSelect?: boolean; // for checkbox

  invitationText?: string; // competitions/users

  constructor(competition?: ClassCompetition) {
    super({ titleCase: competition?.competitionName, upperCase: competition?.id });
    if (competition) {
      Object.entries(competition)?.forEach((el) => {
        const key = el[0] as keyof ClassCompetition;
        this[key] = el[1];
      });
    }

    this.maxCrewSize = competition?.maxCrewSize || 1;

    // === FOR DROPDOWN ============================================
    this.payoutFormatDrop = new ClassPayoutFormat(competition?.payoutFormat);
    this.sportsDrop = competition?.sports?.split(',')?.map(el => getDropFromString(el?.trim(), 'ClassCompetition competition?.sports')) as Array<TSportDrop>;
    this.competitionTypeDrop = getDropFromString(competition?.competitionType!, 'ClassCompetition competition?.competitionType') as TCompetitionTypeDrop;
    this.maxCrewSizeDrop = getDropFromNumber(competition?.maxCrewSize || 0);
    this.gameTypeDrop = getDropFromString(competition?.gameType!, 'ClassCompetition competition?.gameType') as TGameTypeDrop;
    this.teams = competition?.teams?.map((el) => new ClassCompetitionTeam(el, competition?.id!));
    this.locations = competition?.locations?.map((el) => new ClassCompetitionLocation(el, competition?.id!));
    this.seasons = competition?.seasons?.map((el) => new ClassCompetitionSeason(el, competition?.id!));
    this.levels = competition?.levels?.map((el) => new ClassCompetitionLevel(el, competition?.id!));
    this.ageGroups = competition?.ageGroups?.map((el) => new ClassCompetitionAgeGroup(el, competition?.id!));
    if (competition?.subAssignors?.length) this.subAssignors_string = competition.subAssignors.join(', ');
    if (competition?.admins?.length) this.admins_string = competition.admins.join(', ');

    return { ...competition, ...this };
  }

  // !!! property-исключения которые нужно обрабатывать для отправки на сервер по-другому. Например sports = "BASKETBALL,BASEBALL"
  private arrExcludesProperty?: Array<keyof ClassCompetition> = ['sportsDrop'];

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassCompetition): ClassCompetition {
    if (!obj) return {};
    const objForSendToServer: ClassCompetition = obj;
    if (objForSendToServer.sportsDrop?.length) {
      objForSendToServer.sports = objForSendToServer.sportsDrop?.map(el => el.upperCase)?.join(',');
      delete objForSendToServer.arrExcludesProperty;
      delete objForSendToServer.sportsDrop;
    }

    // !!! prepare Property Without Drop In Name
    // !!! если в конструкторе текущего класса вызывал создание другого класса, то здесь надо вызвать preparePropertyFromDropForSendToServer
    objForSendToServer.teams = obj.teams?.map(el => ClassCompetitionTeam.preparePropertyFromDropForSendToServer(el))?.filter(Boolean);
    objForSendToServer.locations = obj.locations?.map(el => ClassCompetitionLocation.preparePropertyFromDropForSendToServer(el))?.filter(Boolean) as Array<ClassCompetitionLocation>;
    objForSendToServer.seasons = obj.seasons?.map(el => ClassCompetitionSeason.preparePropertyFromDropForSendToServer(el))?.filter(Boolean);
    objForSendToServer.levels = obj.levels?.map(el => ClassCompetitionLevel.preparePropertyFromDropForSendToServer(el))?.filter(Boolean);
    objForSendToServer.ageGroups = obj.ageGroups?.map(el => ClassCompetitionAgeGroup.preparePropertyFromDropForSendToServer(el))?.filter(Boolean);

    return preparePropertyFromDropForSendToServer(objForSendToServer, obj.arrExcludesProperty);
  }
}
export class ClassDay extends ClassDrop {
  dayName?: string;
  dayValue?: string;

  constructor(day?: Partial<ClassDay>) {
    super({ titleCase: day?.dayName, upperCase: day?.dayValue });
    if (day) {
      Object.assign(this, day);
    }
  }
}


export class ClassPaymentMethod extends ClassDrop {
  paymentBrand?: string;
  last4?: string;
  expiryDate?: string;
  cssClass?: string;
  icon?: string;
  preparedForAutoTopup?: boolean;

  constructor(paymentMethod?: Partial<ClassPaymentMethod>) {
    // Create a formatted title for the dropdown like "Visa **** 1234 (Exp: 12/24)"
    const title = `${paymentMethod?.paymentBrand} **** ${paymentMethod?.last4} (Exp: ${paymentMethod?.expiryDate})`;
    
    // Pass the title as 'titleCase' and the method ID as 'upperCase' to the parent constructor
    super({ titleCase: title, upperCase: paymentMethod?.upperCase });

    if (paymentMethod) {
      Object.assign(this, paymentMethod);
    }
  }
}



// === Official Labels =============================
export class ClassCompetitionOfficialLabels {
  id?: string;
  competitionId?: string;
  officialPosition?: number; // officialId // crewSize
  labels?: Array<ClassCompetitionOfficialLabelsItem>;

  constructor(obj?: ClassCompetitionOfficialLabels, competitionId?: string) {
    if (obj) {
      Object.entries(obj)?.forEach((el) => {
        const key = el[0] as keyof ClassCompetitionOfficialLabels;
        this[key] = el[1];
      });
    }
    this.competitionId = competitionId;

    this.labels = obj?.labels?.map(el => {
      const updatedCrewItem: ClassCompetitionOfficialLabelsItem = { ...el };
      return new ClassCompetitionOfficialLabelsItem(updatedCrewItem);
    });
    return { ...obj, ...this };
  }

  static preparePropertyFromDropForSendToServer(obj?: ClassCompetitionOfficialLabels): ClassCompetitionOfficialLabels {
    if (!obj) return {};
    const objForSendToServer: ClassCompetitionOfficialLabels = obj;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

export class ClassCompetitionOfficialLabelsItem {
  id?: string;
  indexNumber?: number; // 1
  label?: string; // "name",

  disabled?: boolean; // если crewSize < 4 то нужно disabled лишних

  constructor(obj?: ClassCompetitionOfficialLabelsItem) {
    if (obj) {
      Object.entries(obj)?.forEach((el) => {
        const key = el[0] as keyof ClassCompetitionOfficialLabelsItem;
        this[key] = el[1];
      });
    }
    return { ...obj, ...this };
  }

  static preparePropertyFromDropForSendToServer(obj?: ClassCompetitionOfficialLabelsItem): ClassCompetitionOfficialLabelsItem {
    if (!obj) return {};
    const objForSendToServer: ClassCompetitionOfficialLabelsItem = obj;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

// === AgeGroup =======================
export class ClassCompetitionAgeGroup extends ClassDrop {
  competitionId?: string; // ClassCompetition.id
  id?: string; // "exampleId1",
  gameAgeDescription?: string; // "exampleGameAgeDescription1",
  gamePeriod?: TGamePeriodUpperCase; // 'HALVES' | 'QUARTERS' | 'PERIODS'
  gamePeriodDrop?: TGamePeriodDrop;
  periodLength?: number; // 60,
  totalDurations?: number; // 90,
  defaultCrewSize?: number; // delete
  officialSelfAssign?: boolean; // true,
  officialSelfRequest?: boolean; // true,
  selfApplyLimit?: string;

  constructor(competitionAgeGroup?: ClassCompetitionAgeGroup, competitionId?: string) {
    super({ titleCase: competitionAgeGroup?.gameAgeDescription, upperCase: competitionAgeGroup?.id });
    if (competitionAgeGroup) {
      Object.entries(competitionAgeGroup)?.forEach((el) => {
        const key = el[0] as keyof ClassCompetitionAgeGroup;
        this[key] = el[1];
      });
    }
    this.competitionId = competitionId;
    this.gamePeriodDrop = arrGamePeriodDrop.find(el => el.upperCase === competitionAgeGroup?.gamePeriod);
    return { ...competitionAgeGroup, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassCompetitionAgeGroup): ClassCompetitionAgeGroup {
    if (!obj) return {};
    const objForSendToServer: ClassCompetitionAgeGroup = obj;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

// === LEVELS =============================
export class ClassCompetitionLevel extends ClassDrop {
  id?: string; // string || TDefaultNameMatOption
  competitionId?: string;
  level?: string; // string || TDefaultNameMatOption
  certificationRequired?: boolean;
  certificationRequiredDrop?: ClassYesNo;

  constructor(competitionLevel?: ClassCompetitionLevel, competitionId?: string) {
    super({ titleCase: competitionLevel?.level, upperCase: competitionLevel?.id });
    // if (!competitionLevel) return {};
    if (competitionLevel) {
      Object.entries(competitionLevel)?.forEach((el) => {
        const key = el[0] as keyof ClassCompetitionLevel;
        this[key] = el[1];
      });
    }
    this.competitionId = competitionId;
    this.certificationRequiredDrop = new ClassYesNo(competitionLevel?.certificationRequired);
    return { ...competitionLevel, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassCompetitionAgeGroup): ClassCompetitionAgeGroup {
    if (!obj) return {};
    const objForSendToServer: ClassCompetitionAgeGroup = obj;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

// === PayScale =============================
export class ClassCompetitionPayScale {
  id?: string;
  competitionId?: string;
  gameType?: TGameTypeUpperCase;
  gameTypeDrop?: TGameTypeDrop;
  age?: ClassCompetitionAgeGroup;
  level?: ClassCompetitionLevel;
  crew?: Array<ClassCompetitionPayScaleCrew>; // !!! если название поменяется, поменять в CompetitionsPayScalesComponent createForm()

  constructor(competitionPayScale?: ClassCompetitionPayScale, competitionId?: string) {
    if (competitionPayScale) {
      Object.entries(competitionPayScale)?.forEach((el) => {
        const key = el[0] as keyof ClassCompetitionPayScale;
        this[key] = el[1];
      });
    }
    this.competitionId = competitionId;
    this.gameTypeDrop = getDropFromString(competitionPayScale?.gameType!, 'ClassCompetitionPayScale competitionPayScale.gameType') as TGameTypeDrop;
    this.age = new ClassCompetitionAgeGroup(competitionPayScale?.age, competitionId);
    this.level = new ClassCompetitionLevel(competitionPayScale?.level, competitionId);
    // !!! при получении с сервера надо сортировать массив payScale.crew по количеству в нем property 'official1' | 'official2' | 'official3' | 'official4' | 'official5' | 'official6' | 'official7' | 'official8' | 'official9' | 'official10'
    if (competitionPayScale?.crew?.length) {
      this.crew = competitionPayScale.crew.map(crewItem => {
        const updatedCrewItem: ClassCompetitionPayScaleCrew = {
          ...crewItem,
          // totalRate: getTotalRate(crewItem),
          // serialNumberCrewItem: getSerialNumberCrewItem(crewItem),
          // payScaleId: competitionPayScale.id,
        };
        return new ClassCompetitionPayScaleCrew(updatedCrewItem, getSerialNumberCrewItem(crewItem), competitionPayScale.id!);
      });
      this.crew = sortArrCrew(this.crew);
    } else { // !!! 1. при добавлении payScale 'Add new+' передавать amountCrewItem==1, т.к. добавляется только 1элемент crewItem
      this.crew = createNewArrCrew(1, competitionPayScale?.id!);
    }
    return { ...competitionPayScale, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassCompetitionPayScale): ClassCompetitionPayScale {
    if (!obj) return {};
    const objForSendToServer: ClassCompetitionPayScale = obj;

    objForSendToServer.age = ClassCompetitionAgeGroup.preparePropertyFromDropForSendToServer(obj.age);
    objForSendToServer.level = ClassCompetitionLevel.preparePropertyFromDropForSendToServer(obj.level);

    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

export type TKeyofOnlyOfficial = keyof Pick<ClassCompetitionPayScaleCrew, 'official1' | 'official2' | 'official3' | 'official4' | 'official5' | 'official6' | 'official7' | 'official8' | 'official9' | 'official10'>
export const arrKeyofOnlyOfficial: Array<TKeyofOnlyOfficial> = UtilsService.arrayFromNumber(maximumCrewSizeCompetition).map(el => 'official' + el as TKeyofOnlyOfficial);

// !!! получить название проперти ('official1','official2' и т.д.)
export function getKeyofOnlyOfficialByNumber(number: number): TKeyofOnlyOfficial | null {
  if (number < 1 || number > 10 || typeof number !== 'number') {
    console.error('getKeyofOnlyOfficialByNumber() :', number);
    return null;
  } else {
    return arrKeyofOnlyOfficial.find(el => el.includes(number.toString()))!;
  }
}

// !!! какое количество 'official1','official2'... в crewItem
// !!! if ignoreEmptyFields==true , то НЕ надо считать такие поля 'official':'' (со значением пустой строки), т.к. при создании на странице payScales минимум добавляются === maxCrewSize
export function getSerialNumberCrewItem(crewItem: ClassCompetitionPayScaleCrew, ignoreEmptyFields = false): number {
  if (!crewItem) {
    console.error(`getAmountKeyofOnlyOfficial() no have crewItem :`, crewItem);
    return 0;
  }
  let arr = Object.entries(crewItem).filter(el => arrKeyofOnlyOfficial.includes(el[0] as TKeyofOnlyOfficial));
  let result = arr?.length;
  if (ignoreEmptyFields) {
    result = arr.filter(el => typeof el[1] !== 'string' || UtilsService.isNull(el[1]))?.length;
  }
  if (result < 1 || result > maximumCrewSizeCompetition) console.error(`maximumCrewSize должен быть от 1 до ${maximumCrewSizeCompetition} :`);
  return result;
}

export class ClassCompetitionPayScaleCrew {
  official1?: number;
  official2?: number;
  official3?: number;
  official4?: number;
  official5?: number;
  official6?: number;
  official7?: number;
  official8?: number;
  official9?: number;
  official10?: number;
  groupAssignorRate?: number;
  groupAssignorRateVisible?: boolean;
  subAssignorRate?: number;
  otherRate?: number;
  totalRate?: number;

  id?: string;
  payScaleId?: string;

  serialNumberCrewItem?: number; // !!! с сервера не приходит => количество official1, official2 и т.д.. Тоесть это порядковый номер crewItem. в каком порядке отображать (начинается с 1)

  // !!! 1. при получении с сервера serialNumberCrewItem берется из количества 'official1', 'official2' и т.д.
  // !!! 2. при добавлении payScale 'Add new+'
  // !!! 3. при нажатии на стрелочку => добавление нового элемента в массив crew changeCrewSize() передавать количество(number) элементов которые нужно добавить
  constructor(obj: ClassCompetitionPayScaleCrew, serialNumberCrewItem: number, payScaleId: string) {
    if (serialNumberCrewItem < 1 || serialNumberCrewItem > maximumCrewSizeCompetition || typeof serialNumberCrewItem !== 'number') {
      console.error('ClassCompetitionPayScaleCrew serialNumberCrewItem:', serialNumberCrewItem);
    }
    Object.entries(obj)?.forEach((el) => {
      const key = el[0] as keyof ClassCompetitionPayScaleCrew;
      this[key] = el[1];
    });
    this.payScaleId = payScaleId;
    this.serialNumberCrewItem = serialNumberCrewItem;

    // !!! удалить лишних official10, official9 и т.д.
    UtilsService.arrayFromNumber(maximumCrewSizeCompetition).forEach(el => {
      const keyofOnlyOfficial = getKeyofOnlyOfficialByNumber(el) as TKeyofOnlyOfficial;
      if (el > serialNumberCrewItem) {
        delete this[keyofOnlyOfficial];
      } else {
        this[keyofOnlyOfficial] = obj[keyofOnlyOfficial] || 0;
      }
    });
    this.totalRate = getTotalRate({ ...obj, ...this });

    return { ...obj, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassCompetitionPayScaleCrew): ClassCompetitionPayScaleCrew {
    if (!obj) return {};
    const objForSendToServer: ClassCompetitionPayScaleCrew = obj;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

export function getTotalRate(crewItem: ClassCompetitionPayScaleCrew, str?: string): number {
  if (!crewItem) {
    console.error('getTotalRate() no have crewItem:', crewItem);
    return 0;
  }
  let amountOfficials: number = 0;
  Object.entries(crewItem).forEach(el => {
    if (el[0].includes('official')) {
      const key = el[0] as TKeyofOnlyOfficial;
      const value = el[1];
      if (typeof +value === 'number') amountOfficials = amountOfficials + +value;
    }
  });
  const result: number = amountOfficials + +(crewItem.groupAssignorRate || 0) + +(crewItem.subAssignorRate || 0) + +(crewItem.otherRate || 0);
  return result;
}

// andrei при добавлении payScale 'Add new+'
// !!! 1. при добавлении payScale 'Add new+' передавать amountCrewItem==1, т.к. добавляется только 1элемент crewItem
// !!! 2. при нажатии на стрелочку => добавление нового элемента в массив crew changeCrewSize() передавать количество(amountCrewItem) элементов которые нужно добавить
function createNewArrCrew(amountCrewItem: number, payScaleId: string): Array<ClassCompetitionPayScaleCrew> {
  if (amountCrewItem < 1 || amountCrewItem > maximumCrewSizeCompetition || typeof amountCrewItem !== 'number') {
    console.error('createNewArrCrew() amountCrewItem:', amountCrewItem);
    return [];
  }

  const result = UtilsService.arrayFromNumber(amountCrewItem).map(el => {
    return new ClassCompetitionPayScaleCrew({}, el, payScaleId);
  });
  return sortArrCrew(result);
}

// !!! при получении с сервера надо сортировать массив payScale.crew по количеству в нем property 'official1' | 'official2' | 'official3' | 'official4' | 'official5' | 'official6' | 'official7' | 'official8' | 'official9' | 'official10'
function sortArrCrew(arrCrew: Array<ClassCompetitionPayScaleCrew>): Array<ClassCompetitionPayScaleCrew> {
  return arrCrew.sort((crewItem_1, crewItem_2) => {
    if (!crewItem_1.serialNumberCrewItem || !crewItem_2.serialNumberCrewItem) {
      console.error('sortArrCrew() no have serialNumberCrewItem:', crewItem_1, crewItem_2);
      return 1;
    }
    if (crewItem_1.serialNumberCrewItem > crewItem_2.serialNumberCrewItem) {
      return 1;
    } else {
      return -1;
    }
  });
}

// === Competition SEASONS ===================================
export class ClassCompetitionSeason extends ClassDrop {
  id?: string;
  competitionId?: string;
  name?: string;
  startDate?: string;
  endDate?: string;

  constructor(competitionSeason?: ClassCompetitionSeason, competitionId?: string) {
    super({ titleCase: competitionSeason?.name, upperCase: competitionSeason?.id });
    if (competitionSeason) {
      Object.entries(competitionSeason)?.forEach((el) => {
        const key = el[0] as keyof ClassCompetitionSeason;
        this[key] = el[1];
      });
    }
    // if (!competitionId) console.error('ClassCompetitionSeason no have competitionId :', competitionId);
    this.competitionId = competitionId;
    return { ...competitionSeason, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassCompetitionSeason): ClassCompetitionSeason {
    if (!obj) return {};
    const objForSendToServer: ClassCompetitionSeason = obj;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

// === Competition LOCATION =========================
export class ClassCompetitionLocation extends ClassDrop {
  id?: string;
  competitionId?: string;
  name?: string;
  address?: ILocation;
  courtNames?: Array<string>; // !!! с сервера приходит массив строк
  courtNamesDrop?: Array<ClassDrop>;
  currentCourtName?: string; // когда при создании/редактировании игры в дропдауне выбираешь
  currentCourtNameDrop?: ClassDrop; // когда при создании/редактировании игры в дропдауне выбираешь

  // !!! если competitionId==null то это в каком-то другом месте вызван этот класс и там не нужен competitionId
  constructor(competitionLocation?: ClassCompetitionLocation, competitionId?: string) {
    super({ titleCase: competitionLocation?.name, upperCase: competitionLocation?.id });
    // if (!competitionLocation) retrn {};
    if (competitionLocation) {
      Object.entries(competitionLocation)?.forEach((el) => {
        const key = el[0] as keyof ClassCompetitionLocation;
        this[key] = el[1];
      });
    }
    if (competitionId)
      this.competitionId = competitionId;
    if (competitionLocation?.courtNames) {
      competitionLocation.courtNames = competitionLocation.courtNames.sort();
      this.courtNamesDrop = getArrayDropFromStringCaseSensitive(competitionLocation?.courtNames!);
    }
    this.currentCourtNameDrop = getArrayDropFromStringCaseSensitive([competitionLocation?.currentCourtName!])[0];
    return { ...competitionLocation, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassCompetitionLocation): ClassCompetitionLocation | undefined {
    if (!obj) return undefined;
    const objForSendToServer: ClassCompetitionLocation = obj;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

// === Competition TEAMS =========================
export class ClassCompetitionTeam extends ClassDrop {
  id?: string;
  competitionId?: string;
  teamName?: string;

  constructor(team?: ClassCompetitionTeam, competitionId?: string) {
    super({ titleCase: team?.teamName, upperCase: team?.id });
    if (team) {
      Object.entries(team)?.forEach((el) => {
        const key = el[0] as keyof ClassCompetitionTeam;
        this[key] = el[1];
      });
    }
    this.competitionId = competitionId;
    return { ...team, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassCompetitionTeam): ClassCompetitionTeam {
    if (!obj) return {};
    const objForSendToServer: ClassCompetitionTeam = obj;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

// === Competition USERS =========================
export type TCompetitionUserStatus = 'PENDING' | 'ACTIVE';

export class ClassCompetitionUser {
  competitionId?: string;
  id?: string;
  name?: string;
  email?: string;
  role?: TUserRoleUpperCase;
  roleDrop?: TUserRoleDrop_forInvite;
  dateAdded?: string;
  status?: TCompetitionUserStatus;
  preferredName?: string;
  firstName?: string;
  secondName?: string;

  constructor(competitionUser?: ClassCompetitionUser, competitionId?: string) {
    if (competitionUser) {
      Object.entries(competitionUser)?.forEach((el) => {
        const key = el[0] as keyof ClassCompetitionUser;
        this[key] = el[1];
      });
    }
    this.competitionId = competitionId;
    this.roleDrop = getDropFromString(competitionUser?.role!, 'ClassCompetitionUser competitionUser.role') as TUserRoleDrop_forInvite;
    return { ...competitionUser, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassCompetitionUser): ClassCompetitionUser {
    if (!obj) return {};
    const objForSendToServer: ClassCompetitionUser = obj;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

export type IResponseCompetitionUsers = Pick<ClassCompetition, 'id' | 'users' | 'invitationText'>;

// === for invite /competitions/users =============================
export const arrUserRoleString_forInvite: Array<TUserRoleUpperCase> = ['ADMIN', 'SUB_ASSIGNOR']; // Миша сказал пока что только 1 роль админа поставить на странице competitions/users
export type TUserRoleDrop_forInvite = ClassDrop & { titleCase: TUserRoleTitleCase, upperCase: TUserRoleUpperCase };
// export const arrUserRoleDrop_forInvite = arrUserRoleString_forInvite.map(el => getDropFromString(el, 'arrUserRoleDrop_forInvite')) as Array<TUserRoleDrop_forInvite>;
export const arrUserRoleDrop_forInvite = [
  { upperCase: 'ADMIN', titleCase: 'Admin', source: 'arrUserRoleDrop_forInvite' },
  { upperCase: 'SUB_ASSIGNOR', titleCase: 'Sub-Assignor', source: 'arrUserRoleDrop_forInvite' },
];


