import { Router, RouterOutlet } from '@angular/router';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { MainService } from '@services/main.service';
import { WebsocketService } from '@services/websocket.service';
import { CommonModule } from '@angular/common';
import { MeService } from '@services/me.service';
import { HelperClass } from '@classes/Helper-Classes';
import { DeviceService } from '@services/device.service';
import { MenuNavigationComponent } from '@app/shell/components/menu-navigation/menu-navigation.component';
import { DashboardService } from '@app/dir_group_assignor/dashboard/dashboard.service';
import { MatMenuModule } from '@angular/material/menu';
import { urlDashboard } from '@app/app.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs';
import Median from "median-js-bridge";
import { MenuMobileComponent } from "./components/menu-mobile/menu-mobile.component";
import { NotificationBannerComponent } from '@app/notification-banner/notification-banner.component';

@UntilDestroy()
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  standalone: true,
  imports: [CommonModule, MenuNavigationComponent, RouterOutlet, MatMenuModule, MenuMobileComponent, NotificationBannerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex-column',
  },
})
export class ShellComponent extends HelperClass implements OnInit, AfterViewInit {
  // @ViewChild('wrapperShellRef') private wrapperShellRef?: ElementRef;
  isRunningOnMobileApp: boolean = false; 
  parentOpenMenuEvent = new EventEmitter<void>();
  parentCloseMenuEvent = new EventEmitter<void>();
  showNotificationBanner: boolean = false;
  
  constructor(
    public mainS: MainService,
    private webSocketS: WebsocketService,
    private router: Router,
    public meS: MeService,
    public deviceS: DeviceService,
    public dashboardS: DashboardService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    // !!! Add Date & Time & name. So that Nikita understands that the beta has really been updated
    // console.log('=============== ShellComponent => Andrei 24/06/2024 19:35 =>', this.router.url);
    this.getMe();
    if (this.meS.needGetOnboarding) this.getOnboarding();
    if (!this.webSocketS.stompClientMessages) this.webSocketS.createSocketMessages();  // ВЕБСОКЕТ for messages
  }

  ngOnInit(): void {
    this.isRunningOnMobileApp = Median.isNativeApp();
    this.checkUrl();
    this.deviceS.isMobile$.pipe(untilDestroyed(this)).subscribe(isMobile => {
      if (isMobile) {
        (document.querySelector('.contentShell') as HTMLElement).style.height = '80dvh';
      }
    });
  }

  ngAfterViewInit() {
  }

  getOnboarding(): void {
    if (this.router.url?.includes(urlDashboard)) return; // для dashboard в компоненте DashboardComponent вызывается
    this.dashboardS.getOnboarding().pipe(untilDestroyed(this)).subscribe((res) => {
      // console.log('dashboardS.getOnboarding() :', res)
    });
  }

  getMe(): void {
    // this.mainS.getMe().toPromise()
    //   .then((meUser) => {
    //   })
    //   .catch((err) => {
    //   });
    this.mainS.getMe().pipe(
      switchMap(res => this.mainS.getMeArrCurrentGroup()),
      untilDestroyed(this),
    )
      .subscribe((res) => {
      });
  }

  // ====================
  onboarding(): void { // !!! FOR TEST Onboarding
    // console.error('FOR TEST Onboarding :', )
    this.mainS.forTestOnboarding = true;
    // console.log('ShellComponent onboarding() redirect to urlDashboard:', )
    this.router.navigate([urlDashboard]);
  }

  openMenu(): void {
    this.parentOpenMenuEvent.emit();
  }

  closeMenu(): void {
    this.parentCloseMenuEvent.emit();
  }

  private checkUrl(): void {
    const currentUrl = window.location.href;
    this.showNotificationBanner = currentUrl.includes('v3.beta.joinnotch.com');
  }
}
