<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: gameS.arrCurrentLinkDrop,
              objCurrentLink: (gameS.currentLink$|async)!,
              currentLink: (gameS.currentLink$|async)?.currentLink!,
              dataTable: (dataTable$|async)!,
              arrContent: (arrContent$|async),
              loadingTable: (meTableS.loadingTable$|async),
             } as obj' class='o-wrapPage' wrapPage [widthPage]='widthPage'
     cdkScrollable dataTable>
  <!--  === TABLE ==============================-->
  <div class='o-content t-wrapperTable'>
    <tableHeader></tableHeader>
    <div class='t-table'>
      <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
        <div *ngIf='(idx < 5) && { gameCANCELLED: item?.gameStatus == "CANCELLED", report: item?.gameReport } as objGame'
             class='t-table__item' itemTable [item]='item' [urlNavigate]='urlGameInfo'
             [style]='objGame.gameCANCELLED ? styleForGame_CANCELLED : null'>
          <app-tableCeilChx [item]='item' [text]='(obj.desktop ? item.gameNumber : "#" + item.gameNumber)'></app-tableCeilChx>
          <!-- !!! this component app-tableBtnNumber ONLY FOR MOBILE for table -->
          <app-tableBtnNumber *ngIf='obj.desktop' [game]='item'></app-tableBtnNumber>
          <app-tableCeil [idxCeil]='1' [text1]='item.date_EE_MMMd_yyy'
                         [text2]='item.date_shortTime_timezone'></app-tableCeil>
          <app-tableCeil [idxCeil]='2' [text1]='item.ageGenderLevel'
                         [text2]='item.competition?.competitionName || "TBD"'></app-tableCeil>
          <app-tableCeil [idxCeil]='3' [text1]='item.locNameCourtName' [text2]='item.cityState'
                         [matTooltipText]='item.matTooltipForLocation'></app-tableCeil>
          <app-tableCeil [idxCeil]='4' [text1]='(item.homeTeam?.teamName || "TBD") + " vs."'
                         [text2]='item.awayTeam?.teamName || "TBD"' [commaForMobile]='false'></app-tableCeil>

          <app-table-cell-game-notes ceilTable [idxCeil]='5'
                                     stopPropagation
                                     [game]="item">
          </app-table-cell-game-notes>
          <photos *ngIf='obj.desktop' type='game' [game]='item' [showSmallCircle]='true' ceilTable
                  [idxCeil]='6'></photos>
          <app-tableCeil *ngIf='obj.mobile' [idxCeil]='6' [text1]='item.text_assignGO_outOf_goLength'
                         [bordNone_forMobile]='true'
                         [svgRightForMobile]='item.amountFreeRoles === 0 ? "circle_chx3&20" : undefined'></app-tableCeil>
          <!--  === ACTIONS FOR ITEM TABLE ==========================================-->
          <app-tableCeilActions *ngIf='obj.desktop' [matMenuTriggerFor]='menuItemTable'
                                stopPropagation></app-tableCeilActions>
          <mat-menu #menuItemTable='matMenu'>
            <ng-container [ngTemplateOutlet]='actionsForItemTable'></ng-container>
          </mat-menu>
          <div *ngIf='obj.mobile' class='t-actionsItemTable t-actionsItemTable--{{obj.desktop}}'
               stopPropagation>
            <btn text='View' bcg='blueDark'
                 (click)='router.navigate([urlGameInfo,item?.id])'
                 btnHeight='36'></btn>
            <btn text='More Options' bcg='white' [matMenuTriggerFor]='menuItemTable' btnHeight='36'></btn>
            <btn *ngIf="idx === 4" text='Manage All' bcg='blueDark' (emit)="manageAll()" btnHeight='36' style=" border-top: 1px solid #D0D5DD; margin-top: 10px"></btn>
          </div>

          <ng-template #actionsForItemTable>
            <button [ngStyle]='obj.mobile ? {width: "125px"} : {width: "200px"}' class='hiddenMatOption'></button>
            <button *ngIf='obj.desktop' (click)='router.navigate([urlGameInfo,item?.id])'>View</button>
            <button (click)='goToEditGame(item,idx)'>Edit</button>
            <button (click)='goToEditGame(item,idx, true)'>Clone</button>
            <button *ngIf='item!|isPossibleGameToCancelled' (click)='cancelGames(item,idx)'>Cancel</button>
            <button *ngIf='item!|isPossibleGameToDelete' (click)='deleteGameFromServer(item,idx)'>Delete</button>
            <button *ngIf='!objGame.gameCANCELLED' (click)='assignS.goToAssign(item)'>Go to Assign</button>
          </ng-template>
        </div>
      </ng-container>
      <!--      <app-tableEmpty></app-tableEmpty>-->
      <app-tableEmpty [tableEmpty]='filtersS.searchValue|getTableEmpty'>
        <btnImportGames class='class_ngContent_btn_for_nothingList'></btnImportGames>
      </app-tableEmpty>
    </div>
    <app-safari-bottom></app-safari-bottom>
  </div>

  <!-- <app-tableInfiniteLoading></app-tableInfiniteLoading> -->
  <app-tableInfiniteLoading *ngIf="obj.desktop || (obj.mobile && (obj.arrContent?.length ?? 0) < 5)"></app-tableInfiniteLoading>
</div>

<!--=== MENU FOR CREATE GAME =============================-->
<mat-menu #menuCreateGame='matMenu'>
  <button (click)='router.navigate([urlCreateGame])'>Single Game</button>
  <button routerLink='/game/import'>CSV Import</button>
</mat-menu>