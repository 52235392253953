import { ClassUser, TUserRoleUpperCase } from '@models/user';
import { ClassGame, TStatusGame } from '@app/dir_group_assignor/games/game';
import { ClassCompetition, TPayoutFormat, TPayoutFormatUpperCase } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { ClassSettingsRequest } from '@models/response-and-request';
import { IDatePeriod } from '@components/__drop_inputs_matSelect/drop-date-range/drop-date-range.component';
import { ClassDrop, getDropFromString } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

export type TLinkPayments = 'Overview' | 'Assignor Fees' | 'Officials Fees' | 'Balances' | 'Payers' | 'Rewards';
export type TLinkPaymentsCamelCase = 'overview' | 'assignorFees' | 'officialsFees' | 'balances' | 'payers' | 'rewards';
export type TLinkPaymentsItem = ClassDrop & {
  titleCase: TLinkPayments,
  upperCase: TLinkPaymentsCamelCase
};
export const linkPaymentsItems: Array<TLinkPaymentsItem> = [
  { titleCase: 'Overview', upperCase: 'overview' },
  { titleCase: 'Assignor Fees', upperCase: 'assignorFees' },
  { titleCase: 'Officials Fees', upperCase: 'officialsFees' },
  { titleCase: 'Balances', upperCase: 'balances' },
  { titleCase: 'Payers', upperCase: 'payers' },
  { titleCase: 'Rewards', upperCase: 'rewards' },
];

export enum ELinkPayment {
  overview = 'Overview',
  assignorFees = 'Assignor Fees',
  officialsFees = 'Officials Fees',
  balances = 'Balances',
  payers = 'Payers',
  rewards = 'Rewards',
}

// === OVERVIEW =======================================
export interface ITestArrForAnalytics {
  title?: string;
  amount?: string | number;
  percent?: string | number;
  arrow?: boolean; // true => arrow top
  matTooltipQuestion?: string;
  isAdded: boolean; // !!! for PopupEditChartsComponent
}

export interface ITestArrForCompetitions {
  crown?: TSvgName;
  title?: string;
  amount?: string | number;
  percent?: number;
}

// === BALANCES =======================================
export type TStatusPayment =
  'OPEN'
  | 'PENDING'
  | 'READY'
  | // grey
  'TRANSFER_FAILED'
  | 'FAILED'
  | 'CANCELLED'
  | 'REFUNDED'
  | 'TRANSFER_REFUNDED'
  | 'TRANSFER_REFUND_FAILED'
  | 'REFUND_FAILED'
  | 'REFUND_PENDING'
  | // red
  'SUCCEEDED'  // color $success-700 // bcg $success-50
  | 'INCOMPLETE'


export interface IPayment {
  id?: string;
  status?: TStatusPayment;
  createdAt?: string;
  totalAmount?: number;
  fee?: number;

  isSelect?: boolean;
}

export interface IBalanceOperation {
  competitionId?: string;
  type?: string; // Transfer Out || Payment In"
  status?: TStatusPayment; // "OPEN" "PENDING" "SUCCEEDED" "FAILED" "CANCELED" "REFUNDED" "REVERSED"
  createdAt?: string;
  total?: number;
  fee?: number;
  total_formatted?: string | number;

  isSelect?: boolean;
}

export type TypePay = 'ACH' | 'CARD';


// ========================================================================
export interface ISendObjCreatePaymentMethod {
  website?: string;
  ip?: string;
  userAgent?: string;
  accountId?: string;
  token?: string;
  paymentMethod?: string;
  // bankAccountToken?: string;
  accountNumber?: string;
  routingNumber?: string;
  specialEmail?: string;
  plaidInfo?: IPlaidInfo;
}

export interface IPlaidInfo {
  plaidId?: string;
  bankAccountToken?: string;
}

export interface IPaymentMethod { // был IPaymentMethod
  id?: string; // для банковского метода приходит в списке
  accountId?: string; // для банковского метода приходит в списке . но в методе POST не приходит после создания
  cardDto?: ICard;
  bankAccountDto?: IBankAccount;
  defaultMethod?: boolean;
  last4?: string;
  detached?: boolean;

  isSelect?: boolean;
  preparedForAutoTopup?: boolean;
}

export interface ICard {
  brand?: string;
  expiryDate?: string;
}

export interface IBankAccount {
  bankName?: string;
}

// === Assignor Fees =================
export interface IAssignorFees {
  id?: string;
  competition?: ClassCompetition;
  game?: ClassGame;
  amount?: number;
  status?: string;
  payoutFormat?: string,

  isSelect?: boolean;
}

// === officials Fees =====================
// from, to, List<UUID> competitions, List<UUID> seasons, payoutFormat:string
export interface IOptionsGetProjectedOutgoings {
  from?: string;
  to?: string;
  competitions?: Array<string>;
  seasons?: Array<string>;
  payoutFormat?: TPayoutFormatUpperCase;
}

export interface IResponseGetProjectedOutgoings { // andrei create class
  officialFees: number;
  assignorFees: number;
  totalFees: number;
  groupAssignorFees: number;
}

// ============================================================
export type TStatusCreatePayment = 'OPEN' | 'TRANSFER_FAILED' | 'SUCCEEDED' | 'PENDING' | 'FAILED' | 'READY' | 'CANCELLED'
  | 'REFUNDED' | 'TRANSFER_REFUNDED' | 'TRANSFER_REFUND_FAILED' | 'REFUND_FAILED' | 'REFUND_PENDING'| 'REQUIRES_CONFIRMATION' |  'INCOMPLETE'

export interface ISendObjCreatePaymentForCompetition {
  amount?: number;
  fee?: IFee;
  meta?: IMeta;
}

export interface IResCreatePaymentForCompetition {
  id?: string;
  status?: TStatusCreatePayment;
  userInitiatedId?: string;
  paymentMethod?: IPaymentMethod;
  createdAt?: string;
  total?: number;
  fee?: number;
  receiptUrl?: string;
  redirectUrl?: string;
  errorMessage?: string;
}

export interface IFee {
  processingFee?: number;
  transactionFee?: number;
}

export interface IMeta {
  property1?: string;
  // property2?: string;
  // property name*
  // additional property
}

// === Transfer ===============================================
// export type TStatusTransfer = 'OPEN' | 'PENDING' | 'SUCCEEDED' | 'FAILED' | 'CANCELED' | 'REFUNDED' | 'REVERSED';
export type TypeTransfer = 'STRIPE' | 'OFFLINE';

export interface ITransfersOfficialSum {
  STRIPE: number;
  OFFLINE: number;
}

export interface IOptionsForTransfers {
  transferType?: TypeTransfer;
  games?: Array<string>;
  competition?: ClassCompetition;
  payoutFormat?: TPayoutFormat; // TPayoutFormatUpperCase

  datePeriod?: IDatePeriod; // !!! из компонента передаю, потому что в форме в таком виде. В сервисе преобразовываю в from & to
}

export interface ISettingsForTransfers extends ClassSettingsRequest {
  transferType?: TypeTransfer;
  competitionId?: string;
  games?: Array<string>;
  // seasons?: Array<string>;
  seasons?: string;
  payoutFormat?: TPayoutFormatUpperCase; // TPayoutFormatUpperCase TPayoutFormat
  status?: string; // for page Payers
}

// === TOPUP ================================
export interface ITopup {
  backupMethod?: IPaymentMethod; // IPaymentMethod;
  day?: string;
  enabled?: boolean;
  id?: string;
  primaryMethod?: IPaymentMethod; // IPaymentMethod;
  resultBalance?: number;
  on?: string;
  // timezone?: number; // посмотреть потом в каком виде приходит таймзона здесь
  // topupIsExist?: boolean;
}

export const arrOnForWeekly: Array<ClassDrop> = [
  getDropFromString('Monday', 'arrOnForWeekly'),
  getDropFromString('Tuesday', 'arrOnForWeekly'),
  getDropFromString('Wednesday', 'arrOnForWeekly'),
  getDropFromString('Thursday', 'arrOnForWeekly'),
  getDropFromString('Friday', 'arrOnForWeekly'),
];
export const arrOnForMonthly: Array<ClassDrop> = [
  getDropFromString('1st of the month', 'arrOnForMonthly'),
  getDropFromString('14th of the month', 'arrOnForMonthly'),
  getDropFromString('28th of the month', 'arrOnForMonthly'),
];

// === Payer ===============================
export interface IPayer {
  id?: string;
  game?: ClassGame;
  competition?: ClassCompetition;
  totalAmount?: number;
  payer?: IPayerUser;
  payoutFormat?: string;
  // recentTransactions?: Array<IBalance>;

  officialsFees?: number;
  assignorFees?: number;
  billedAmount?: number;

  isSelect?: boolean;
}

export interface IPayerUser extends ClassUser {
}

// !!! from 2 version ========================================
export type TPaymentStatus = 'OPEN' | 'SUCCEEDED' | 'FAILED' | 'REFUNDED' | 'READY' | 'TRANSFER_FAILED' // оплата form ClassGameOfficial.paymentStatus?: TPaymentStatus;
export type TPayoutStatus = 'SUCCEEDED' | 'PENDING' | 'FAILED' | 'OPEN' | 'READY' | 'CANCELLED'; // выплата form IPayout.status
export const ArrayPayStatus: Array<TPayoutStatus> = ['OPEN', 'READY', 'PENDING', 'FAILED', 'SUCCEEDED', 'CANCELLED'];
//                             ADMIN       |   OFFICIAL    |   GAME_OFFICIAL:
// Game role assign:           OPEN        |   OPEN        |   null
// Transfer failed:            FAILED      |   OPEN        |   TRANSFER_FAILED
// Transfer succeeded:         SUCCEEDED   |   READY       |   READY
// Payout requested:           SUCCEEDED   |   PENDING     |   PENDING
// Payout failed:              SUCCEEDED   |   FAILED      |   FAILED
// Payout succeeded:           SUCCEEDED   |   SUCCEEDED   |   SUCCEEDED

export const arrTopUpSchedule: Array<ClassDrop> = [
  getDropFromString('DAILY', 'modelsForPayment.ts arrTopUpSchedule'),
  getDropFromString('WEEKLY', 'modelsForPayment.ts arrTopUpSchedule'),
  getDropFromString('MONTHLY', 'modelsForPayment.ts arrTopUpSchedule'),
];

export interface IBalance { // andrei create class
  available: string | number;
  payoutsDue: string | number;
  pending: string | number;
  current: string | number;
  lowBalance?: boolean;
}

export interface IBalanceForHtml {
  title: keyof IBalance;
  coin: string;
  coinForArrow: string;
  text: string;

  isActive: boolean;
  serialNumber: number;
}

export const EBalance: { [key: keyof IBalance | string]: string } = {
  current: 'Notch Pay Balance',
  payoutsDue: 'Projected Outgoings',
  available: 'Total Overdue',
  // pending: 'Pending',
  // lowBalance: 'lowBalance',
};

export interface IPayout {
  id?: string;
  actor?: ClassUser;
  createdAt?: string;
  currentAvailable?: number;
  customer?: {
    available?: number;
    id?: string;
    payoutsDue?: number;
    pending?: number;
    role?: TUserRoleUpperCase;
    user?: { id?: string }
  };
  // division?: IDivision;
  // gameOfficial?: ClassGameOfficial;
  gameRole?: string;
  // league?: ILeague;
  modifiedAt?: string;
  paymentMethod?: string;
  recipient?: ClassUser;
  status?: TPayoutStatus;
  total?: number;
  receiptUrl?: string;
  errorMessage?: string;
  availableOn?: string; // для чего это. Из свагера взял
}

export interface IConfigPay {
  page?: string | number;
  size?: string | number;
  // Витя
  statuses?: TPayoutStatus | `${TPayoutStatus},${TPayoutStatus}` | `${TPayoutStatus},${TPayoutStatus},${TPayoutStatus}` | `${TPayoutStatus},${TPayoutStatus},${TPayoutStatus},${TPayoutStatus}` | `${TPayoutStatus},${TPayoutStatus},${TPayoutStatus},${TPayoutStatus},${TPayoutStatus}`;
  sort?: 'modifiedAt,asc' | 'modifiedAt,desc';
}

export class ClassPayMethodAndAmount {
  payMethod?: IPaymentMethod = undefined;
  amount: number = 0;

  constructor(payMethod?: IPaymentMethod, amount?: number) {
    if (payMethod) this.payMethod = payMethod;
    if (amount) this.amount = amount;
  }
}

export interface IPaymentOutItem {
  allOfficials?: number;
  game?: ClassGame;
  gameStatus?: TStatusGame;
  officialsPaid?: number;
  payouts?: Array<IPayout>;
}

