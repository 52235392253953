import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { ClassSettingsRequest } from '@models/response-and-request';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UtilsService } from '@services/utils.service';
import { MeService } from '@services/me.service';
import { ForTestService } from '@classes/forTest';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CompetitionsForFiltersResolver  {
  settings?: ClassSettingsRequest;
  groupId?: string; // !!! groupId from queryParams|this.settings

  constructor(
    private meS: MeService,
    private gameS: GameService,
    private forTestS: ForTestService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.meS.meId) {
      // !!! NO DELETE. это для страниц авторизации. Тоесть если не залогинен, то не надо getArrCompetition(), потому что вернется 401 ошибка
      return of(true);
    }

    if (Object.entries(UtilsService.removeEmptyKeysFromObject(route.queryParams))?.length) { // !!! если есть queryParams
      this.settings = route.queryParams;
      if (this.settings?.groupId) {
        this.groupId = this.settings.groupId;
      }
    }

    // const fakeRes = this.forTestS.fakeResponse as IResponse<ClassCompetition>;
    // fakeRes.content = [this.forTestS.fakeCompetition, this.forTestS.fakeCompetition];
    // return of(fakeRes)
    // !!! groupId для судьи
    return this.gameS.getArrCompetition(this.groupId, true)
      .pipe(
        map(res => true),
        catchError(() => of(false)),
        untilDestroyed(this),
      );
  }

}
