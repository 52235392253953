import { Injectable } from '@angular/core';
import { BaseApi, THttpMethod } from '@services/base-api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OtherService } from '@services/other.service';
import { ClassSettingsRequest, IRequestOptions, IResponse } from '@models/response-and-request';
import { Observable, of } from 'rxjs';
import {
  ClassCompetition,
  ClassCompetitionAgeGroup,
  ClassCompetitionLevel,
  ClassCompetitionLocation,
  ClassCompetitionOfficialLabels,
  ClassCompetitionPayScale,
  ClassCompetitionSeason,
  ClassCompetitionTeam,
  ClassCompetitionUser,
  IResponseCompetitionUsers,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { catchError, map, tap } from 'rxjs/operators';
import { UtilsService } from '@services/utils.service';
import { MeService } from '@services/me.service';
import { LodashService } from '@services/lodash.service';
import { SelfLimitedResponse, TUserRoleUpperCase } from '@models/user';
import * as _ from 'lodash';
@Injectable({ providedIn: 'root' })
export class ApiCompetitionService extends BaseApi {

  constructor(
    public http: HttpClient,
    public otherS: OtherService,
    private meS: MeService,
    private lodashS: LodashService,
  ) {
    super(http, otherS);
  }

  // === CASH =========================================================
  private cachedData: Map<string, any> = new Map();
  payoutFormat: string | null = null;

  // apiString => какой ключ надо удалить // например передать this.apiOfficials, который хранит в себе такую строку '/api/core/officials/v1'
  deleteApiCache(apiString: string): void {
    this.cachedData.forEach((value, key) => {
      if (key.includes(apiString)) this.cachedData.delete(key);
    });
  }

  deleteAllCache(): void {
    this.cachedData.clear();
  }

  // === COMPETITION =====================================
  readonly urlCompetition = '/api/core/competitions/v1';

  getArrCompetition(options?: IRequestOptions, needUse_cachedData = false): Observable<IResponse<ClassCompetition>> { // content: Array<ClassCompetition>
    // https://notch.atlassian.net/browse/NOT30-436 если не передать size, то по умолчанию с бэка приходит 20. Нужно делать доп.загрузку для дропдаунов
    if (!options) options = { params: { size: 50 } };
    const params = options?.params as ClassSettingsRequest;
    if (params.page && params.page > 0) {
      params.page = params.page - 1;
    } else {
      params.page = 0;
    }
    if (options?.params && !params?.size) {
      params.size = 50;
      options = { ...options, params };
    }
    const endPoint = `${this.urlCompetition}/all`;

    if (needUse_cachedData) {
      const cachedData = this.cachedData.get(endPoint);
      if (cachedData) return of(cachedData);
    }

    return this.get(endPoint, options).pipe(
      map((res: IResponse<ClassCompetition>) => {
        const arrayCompetitions: Array<ClassCompetition> = res?.content?.map(el => new ClassCompetition(el)) || [];
        const result: IResponse<ClassCompetition> = { ...res, content: arrayCompetitions };
        this.cachedData.set(endPoint, result);
        return result;
      }),
    );
  }

  getCompetition(idCompetition: string): Observable<ClassCompetition> {
    return this.get(`api/core/competitions/v1/${idCompetition}`).pipe(
      map((res: ClassCompetition) => new ClassCompetition(res)),
    );
  }

  methodCompetition(sendObj: ClassCompetition, httpMethod: THttpMethod): Observable<ClassCompetition> {
    const url = `${this.urlCompetition}/info`;
    let result: Observable<ClassCompetition>;
    if (!sendObj.id) delete sendObj.id;

    if (httpMethod === 'get') {
      result = this.get(`${url}/${sendObj.id}`);
    } else if (httpMethod === 'delete') {
      result = this.delete(`${url}/${sendObj.id}`);
    } else {
      sendObj = ClassCompetition.preparePropertyFromDropForSendToServer(sendObj!);
      sendObj.creatorId = this.meS.meId;
      result = this[httpMethod](url, sendObj);
    }
    return result.pipe(
      map((res) => new ClassCompetition(res)),
    );
  }

  methodCompetitionOfficialLabels(sendObj: ClassCompetition, httpMethod: THttpMethod, idItem?: string)
    : Observable<Pick<ClassCompetition, 'officialLabels'>> {
    const url = `${this.urlCompetition}/officialLabels`;
    const competitionId = sendObj.id!;
    let result: Observable<{ officialLabels: Array<ClassCompetitionOfficialLabels>; }>;

    const newOfficialLabels: Array<ClassCompetitionOfficialLabels> = sendObj.officialLabels?.map((res) => {
      const newOfficialLabel: ClassCompetitionOfficialLabels = UtilsService.removeEmptyKeysFromObject(res);
      return newOfficialLabel;
    }) as Array<ClassCompetitionOfficialLabels>;
    const newSendObj: { officialLabels: Array<ClassCompetitionOfficialLabels>; } = { officialLabels: newOfficialLabels };

    if (httpMethod == 'get') {
      if (!competitionId) this.logError(sendObj, '  methodCompetitionOfficialLabels ');
      result = this.get(`${url}/${competitionId}`);
    } else {
      newSendObj.officialLabels?.forEach((el) => el.id ? null : delete el?.id);
      result = this[httpMethod](url, newSendObj);
    }

    return result.pipe(
      map((res) => { // !!! с сервера приходят пустые labels, которые не заполнены. Поэтому я возвращаю отсюда полный массив
        if (res?.officialLabels?.length) {
          res.officialLabels.forEach((item) => {
            if (item?.labels?.length) {
              item.labels = Array.from(Array(res.officialLabels.length).keys())?.map((i) => {
                return item.labels?.find((el) => el?.indexNumber === i + 1) || { id: '', indexNumber: i + 1, label: '' };
              });
            }
          });
        }
        return { ...res, officialLabels: res?.officialLabels.map(el => new ClassCompetitionOfficialLabels(el, competitionId)) };
      }),
    );
  }

  methodCompetitionAgeGroups(sendObj: ClassCompetition, httpMethod: THttpMethod, idItem?: string)
    : Observable<Pick<ClassCompetition, 'ageGroups'>> {
    const url = `${this.urlCompetition}/ageGroups`;
    const competitionId = sendObj.id!;
    let result: Observable<{ ageGroups: Array<ClassCompetitionAgeGroup> }>;

    if (httpMethod === 'get') {
      if (!competitionId) this.logError(sendObj, '  methodCompetitionAgeGroups ');
      result = this.get(`${url}/${competitionId}`);
    } else if (httpMethod === 'delete') {
      result = this.delete(`${url}/${idItem}`);
    } else { // put post
      const forSendToServer = {
        ageGroups: sendObj.ageGroups?.map(el => {
          return ClassCompetitionAgeGroup.preparePropertyFromDropForSendToServer(el!)!;
        })!,
      };
      result = this[httpMethod](url, forSendToServer);
    }

    return result.pipe(
      map((res) => {
        const ageGroups = res?.ageGroups?.map(el => new ClassCompetitionAgeGroup(el, competitionId)!)!;
        const result = { ...res, ageGroups };
        return result;
      }),
    );
  }

  methodCompetitionLevels(sendObj: ClassCompetition, httpMethod: THttpMethod, idItem?: string)
    : Observable<Pick<ClassCompetition, 'levels'>> {
    const url = `${this.urlCompetition}/levels`;
    const competitionId = sendObj.id!;
    let result: Observable<{ levels: Array<ClassCompetitionLevel> }>;

    if (httpMethod === 'get') {
      if (!competitionId) this.logError(sendObj, '  methodCompetitionLevels  ');
      result = this.get(`${url}/${competitionId}`);
    } else if (httpMethod === 'delete') {
      result = this.delete(`${url}/${idItem}`);
    } else { // put post
      if (sendObj?.levels) sendObj.levels?.forEach((el) => el.id ? null : delete el?.id);
      const forSendToServer = {
        levels: sendObj.levels?.map(el => {
          return ClassCompetitionLevel.preparePropertyFromDropForSendToServer(el!)!;
        })!,
      };
      result = this[httpMethod](url, forSendToServer);
    }

    return result.pipe(
      map((res) => {
        const result = {
          ...res, levels: res?.levels?.map((el) => new ClassCompetitionLevel(el, competitionId)),
        };
        return result;
      }),
    );
  }

  methodCompetitionPayScales(sendObj: ClassCompetition, httpMethod: THttpMethod, idItem?: string)
    : Observable<Pick<ClassCompetition, 'payScales'>> {
    const url = `${this.urlCompetition}/payScales`;
    const competitionId = sendObj.id!;
    let result: Observable<{ payScales: Array<ClassCompetitionPayScale>; }>;
    const newPayScales: Array<ClassCompetitionPayScale> = UtilsService.deepClone(sendObj)?.payScales?.map((payScale) => {
      if (payScale?.crew) {
        payScale.crew = payScale.crew?.map((crew) => UtilsService.removeEmptyKeysFromObject(crew))
          .map((res) => {
            const arr: Array<any> = Object.entries(res).map((r) => {
              return [r[0], this.otherS.amountToNumber(r[1] as any)];
            });
            return _.fromPairs(arr);
          });
      }
      return payScale;
    })!;
    const newSendObj = { payScales: newPayScales };

    if (httpMethod === 'get') {
      if (!competitionId) this.logError(sendObj, '  methodCompetitionPayScales ');
      result = this.get(`${url}/${competitionId}`);
    } else if (httpMethod === 'delete') {
      result = this.delete(`${url}/${idItem}`);
    } else { // put post
      const forSendToServer = {
        payScales: newSendObj?.payScales?.map(el => {
          return ClassCompetitionPayScale.preparePropertyFromDropForSendToServer(el!)!;
        })!,
      };
      result = this[httpMethod](url, forSendToServer);
    }

    return result.pipe(
      map((res: Pick<ClassCompetition, 'payScales'>) => {
        const result = {
          ...res, payScales: res?.payScales?.map((el) => new ClassCompetitionPayScale(el, competitionId)),
        };
        return result;
      }),
    );
  }

  methodCompetitionSeasons(competition: ClassCompetition, httpMethod: THttpMethod, idItem?: string)
    : Observable<Pick<ClassCompetition, 'seasons'>> {
    const sendObj: ClassCompetition = UtilsService.removeEmptyKeysFromObject(competition);
    const url = `${this.urlCompetition}/seasons`;
    const competitionId = sendObj.id!;
    let result: Observable<{ seasons: Array<ClassCompetitionSeason>; }>;
    const newSeasons: Array<ClassCompetitionSeason> = sendObj?.seasons?.map((res) => {
      const newSeason: ClassCompetitionSeason = UtilsService.removeEmptyKeysFromObject(res);
      return newSeason;
    }) as Array<ClassCompetitionSeason>;
    const newSendObj: { seasons: Array<ClassCompetitionSeason>; } = { seasons: newSeasons };

    if (httpMethod === 'get') {
      if (!competitionId) this.logError(sendObj, '  methodCompetitionSeasons ');
      result = this.get(`${url}/${competitionId}`);
    } else if (httpMethod === 'delete') {
      result = this.delete(`${url}/${idItem}`);
    } else { // put post
      const forSendToServer = {
        seasons: newSendObj?.seasons?.map(el => {
          return ClassCompetitionSeason.preparePropertyFromDropForSendToServer(el!)!;
        })!,
      };
      result = this[httpMethod](url, forSendToServer);
    }
    return result.pipe(
      map((res) => {
        const result = {
          ...res, seasons: res.seasons?.map((el) => new ClassCompetitionSeason(el, competitionId)),
        };
        return result;
      }),
    );
  }

  methodCompetitionLocations(sendObj: ClassCompetition, httpMethod: THttpMethod, idItem?: string)
    : Observable<Pick<ClassCompetition, 'locations'>> {
    const url = `${this.urlCompetition}/locations`;
    const competitionId = sendObj.id!;
    let result: Observable<{ locations: Array<ClassCompetitionLocation>; }>;

    if (httpMethod === 'get') {
      if (!competitionId) this.logError(sendObj, ' methodCompetitionLocations ');
      result = this.get(`${url}/${competitionId}`);
    } else if (httpMethod === 'delete') {
      result = this.delete(`${url}/${idItem}`);
    } else { // put post
      const forSendToServer = {
        locations: sendObj?.locations?.map(el => {
          return ClassCompetitionLocation.preparePropertyFromDropForSendToServer(el!)!;
        })!,
      };
      result = this[httpMethod](url, forSendToServer);
    }

    return result.pipe(
      map((res) => {
        const result = {
          ...res, locations: res.locations?.map((el) => new ClassCompetitionLocation(el, competitionId)),
        };
        return result;
      }));
  }

  methodCompetitionTeams(sendObj: ClassCompetition, httpMethod: THttpMethod, idItem?: string)
    : Observable<Pick<ClassCompetition, 'teams'>> {
    const url = `${this.urlCompetition}/teams`;
    const competitionId = sendObj.id!;
    let result: Observable<{ teams: Array<ClassCompetitionTeam>; }>;

    if (sendObj?.teams) sendObj.teams = sendObj.teams?.map((team) => UtilsService.removeEmptyKeysFromObject(team));

    if (httpMethod === 'get') {
      if (!competitionId) this.logError(sendObj, '  methodCompetitionTeams ');
      result = this.get(`${url}/${competitionId}`);
    } else if (httpMethod === 'delete') {
      result = this.delete(`${url}/${idItem}`);
    } else { // put post
      const forSendToServer = {
        teams: sendObj?.teams?.map(el => {
          return ClassCompetitionTeam.preparePropertyFromDropForSendToServer(el!)!;
        })!,
      };
      result = this[httpMethod](url, forSendToServer);
    }

    return result.pipe(
      map((res) => {
        const result = {
          ...res, teams: res.teams?.map((el) => new ClassCompetitionTeam(el, competitionId)),
        };
        return result;
      }));
  }

  methodCompetitionUsers(sendObj: ClassCompetition, httpMethod: THttpMethod, idItem?: string)
    : Observable<Pick<ClassCompetition, 'users'>> {
    // <!--  1. нет в системе-->
    // <!--  2. есть , но нет в компетигщшне-->
    // <!--  3. есть и есть в компетишне-->
    let url = `${this.urlCompetition}/users`; // '/api/core/competitions/v1/users'
    const competitionId = sendObj.id!;
    if (httpMethod === 'post' || httpMethod === 'put') url += '/invite'; // !!! for page competitions/users (в этом методе на бэке юзер сразу добавляется в текущий компетишн)

    let result: Observable<Pick<ClassCompetition, 'users'>>;

    if (sendObj?.users) {
      sendObj.users = sendObj.users?.map((el) => UtilsService.removeEmptyKeysFromObject(el))
        .map((el) => {
          const newEl = { ...el, role: this.otherS.prepareConstStr(el?.role!, 'toUpper') };
          return ClassCompetition.preparePropertyFromDropForSendToServer(newEl);
        });
    }

    if (httpMethod === 'get') {
      if (!competitionId) this.logError(sendObj, ' methodCompetitionUsers ');
      result = this.get(`${url}/${competitionId}`);
    } else if (httpMethod === 'delete') {
      result = this.delete(`${url}/${idItem}`);
    } else { // put post
      if (httpMethod == 'put') httpMethod = 'post';
      result = this[httpMethod](url, sendObj);
    }
    return result.pipe(
      catchError((err) => {
        return of(err);
      }),
      map((res) => {
        if ((res as { content: Array<ClassCompetitionUser> })?.content) { // for { content: Array<ClassCompetitionUser> }
          const content = (res as { content: Array<ClassCompetitionUser> })?.content;
          const result = {
            users: content?.map((el) => new ClassCompetitionUser(el, competitionId)) || [],
          };
          return result;
        } else if (Array.isArray(res)) { // for Array<ClassCompetitionUser>
          const content = res as Array<ClassCompetitionUser>;
          const result = { users: content?.map((el) => new ClassCompetitionUser(el, competitionId)) };
          return result;
        } else {
          // !!! if httpMethod === 'delete' то возвращается с сервера {id: string}
          return { users: [] };
        }
      }),
    );
  }

  // !!! for page competitions/users
  inviteUsersInCompetition(sendObj: IResponseCompetitionUsers)
    : Observable<Pick<ClassCompetition, 'users'>> {
    const competitionId = sendObj.id!;
    if (!competitionId) this.logError(sendObj, '  inviteUsersInCompetition ');
    // <!--  1. нет в системе-->
    // <!--  2. есть , но нет в компетигщшне-->
    // <!--  3. есть и есть в компетишне-->
    if (sendObj?.users) {
      sendObj.users = sendObj.users?.map((el) => UtilsService.removeEmptyKeysFromObject(el))
        .map((el) => {
          const newEl = { ...el, role: this.otherS.prepareConstStr(el?.role!, 'toUpper') };
          return ClassCompetition.preparePropertyFromDropForSendToServer(newEl);
        });
    }
    return this.post(`${this.urlCompetition}/users/invite`, sendObj).pipe(
      catchError((err) => {
        return of(err);
      }),
      map((res: IResponseCompetitionUsers) => {
        if (res?.users?.length) {
          return { users: res?.users?.map((el) => new ClassCompetitionUser(el, competitionId)) || [] };
        } else {
          return { users: [] };
        }
      }),
    );
  }

  // !!! page /competitions/users => поиск в поле "Name or Email"
  searchUsersInCompetition(searchText: string, competitionId: string): Observable<Array<ClassCompetitionUser>> {
    return this.get(`${this.urlCompetition}/users/search?letters=${searchText}`).pipe(
      catchError((err) => {
        return of(err);
      }),
      map((res) => {
        if (Array.isArray(res)) { // for Array<ClassCompetitionUser>
          const content = res as Array<ClassCompetitionUser>;
          const result = content?.map((el) => new ClassCompetitionUser(el, competitionId));
          return result;
        } else {
          return [];
        }
      }),
    );
  }

  // !!! для получения ссылки для регистрации юзера (пока что только для админа)
  // !!! для регистрации судьи есть другой метод this.getLinkOfficialToGroup()
  getLink_for_inviteUserToCompetition(competitionId: string, userRoleUpperCase: TUserRoleUpperCase): Observable<string> {
    let url = `${this.urlCompetition}/users/copyLink`; // '/api/core/competitions/v1/users'
    return this.post(`${url}?competitionId=${competitionId}&role=${userRoleUpperCase}`);
  }

  // ================================
  updateSelfLimit(limit: string = '', competitionId: string, userId: string, currentRole: string, ageGroupExclusions: (string | undefined)[]) {
    console.log('updateSelfLimit :', competitionId);
    if (!competitionId) {
      return of({});
    }
    if (limit && limit?.toString().toLowerCase() === 'unlimited') {
      limit = `15000`;
    }
    const apiUrl = `/api/core/competitions/v1/info/rules/${competitionId}`;
    const headers = new HttpHeaders()
      .set('user-id', userId)
      .set('role-current', currentRole);
    return this.http.put(apiUrl, { selfApplyLimit: Number(limit), ageGroupExclusions: ageGroupExclusions }, { headers });
  }

  getSelfLimited(competitionId: string, needUse_cachedData = false): Observable<SelfLimitedResponse> {
    if (!competitionId) {
      return of({ selfApplyLimit: '0', ageGroupExclusions: [] });
    }
    const apiUrl = `/api/core/competitions/v1/info/rules/${competitionId}`;

    if (needUse_cachedData) {
      const cachedData = this.cachedData.get(apiUrl);
      if (cachedData) return of(cachedData);
    }

    return (this.http.get(apiUrl) as Observable<SelfLimitedResponse>)
      .pipe(
        tap((res) => {
          this.cachedData.set(apiUrl, res);
        }),
      );
  }

  // !!! получение количества игр в которых используется данный компетишн
  async checkActiveGamesByCompetition(competitionId?: string): Promise<{ activeGames: number, allGames: number }> {
    try {
      if (!competitionId) return { allGames: 0, activeGames: 0 };

      const allGames = await this.http.get<number>(`api/core/games/v1/count/${competitionId}`).toPromise();
      const activeGames = await this.http.get<number>(`api/core/games/v1/count/${competitionId}?gameStatus=ACTIVE`).toPromise().catch(err => {
        console.log(err);
      }).finally(() => {
        return 0;
      });

      return {
        activeGames: activeGames ?? 0,
        allGames: allGames ?? 0,
      };
    } catch (error) {
      console.error('Error fetching count:', error);
      throw error;
    }
  }

  // === OTHER ======================================
  private logError(sendObj: ClassCompetition, method: string): void {
    console.error(`${method} no have competitionId:`, sendObj);
  }

  getCompetitionInfo(idCompetition: string): Observable<ClassCompetition> {
    return this.get(`api/core/competitions/v1/info/${idCompetition}`).pipe(
      map((res: ClassCompetition & { payoutFormat: string }) => {
        this.payoutFormat = res.payoutFormat;
        return new ClassCompetition(res);
      })
    );
  }
}
