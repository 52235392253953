import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { HelperClass } from '@classes/Helper-Classes';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { PopupCustomizeInvitationComponent } from '@components/popup-customize-invitation/popup-customize-invitation.component';
import { IDataPopup, PopupService } from '@services/popup.service';
import { BtnAddAnotherComponent } from '@components/btn-add-another/btn-add-another.component';
import {
  CompetitionsNavigationComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/competitions-navigation/competitions-navigation.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MainService } from '@services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  arrUserRoleDrop_forInvite,
  ClassCompetitionUser,
  IResponseCompetitionUsers,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ClassUser, TUserRoleDrop } from '@models/user';
import { ExcludeStrPipe } from '@pipes/exclude-str.pipe';
import {
  CompetitionsListUsersComponent,
} from '@app/dir_group_assignor/competitions/components/competitions-users/competitionsListUsers/competitions-list-users.component';
import { BehaviorSubject, delay, finalize, of, switchMap } from 'rxjs';
import {
  DeleteItemForCompetitionsComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/delete-item-for-competitions/delete-item-for-competitions.component';
import { CheckExistUsersPipe } from '@app/dir_group_assignor/competitions/components/competitions-users/pipes/check-exist-users.pipe';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { CopyLinkComponent } from '@components/copy-link/copy-link.component';
import { OtherService } from '@services/other.service';
import { EMAIL_REGEXP } from '@classes/CustomValidators';
import { ForTestService } from '@classes/forTest';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

interface IFormCompetitionsUsers {
  arrControls?: FormArray<FormGroup<IFormItemCompetitionsUsers>>;
  invitationText: FormControl<string>;
}

interface IFormItemCompetitionsUsers {
  id: FormControl<string>;
  competitionId: FormControl<string>;
  name: FormControl<string>;
  roleDrop: FormControl<TUserRoleDrop>;
  userId: FormControl<string>;
  email: FormControl<string>;
}

@Component({
  selector: 'app-competitions-selected-page',
  standalone: true,
  imports: [CommonModule, FieldComponent, ReactiveFormsModule, InputCtrlComponent, SvgComponent, SvgAndTextComponent, BtnWrapComponent, BtnComponent, DropFormCtrlComponent, BtnAddAnotherComponent, CompetitionsNavigationComponent, MatProgressSpinnerModule, ExcludeStrPipe, CompetitionsListUsersComponent, DeleteItemForCompetitionsComponent, CheckExistUsersPipe, MatMenuModule, ErrorComponent, GetWidthMatMenuPipe, DropdownComponent, CopyLinkComponent],
  templateUrl: './competitions-selected-page.component.html',
  styleUrl: './competitions-selected-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CompetitionsSelectedPageComponent extends HelperClass implements OnInit  {
  @Input() selectedCompetitions: any[] = [];
  @Output() back = new EventEmitter<void>();
  form!: FormGroup<IFormCompetitionsUsers>;
  readonly arrUserRoleDrop_forInvite = arrUserRoleDrop_forInvite;
  pageUsersSub$ = new BehaviorSubject<'newUsers' | 'listUsers'>('listUsers'); // !!! было newUsers 11/04/24

  get pageUsers(): 'newUsers' | 'listUsers' {
    return this.pageUsersSub$.getValue();
  }

  usersForDropDown: Array<ClassCompetitionUser & ClassUser> = [];
  searchText = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    public competitionS: CompetitionService,
    public popupS: PopupService,
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    private otherS: OtherService,
    private route: ActivatedRoute,
    private router: Router,
    private forTestS: ForTestService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.createForm();
  }

  ngOnInit() {
  }

  openPopupCustomizeInvitation(): void {
    const dataPopup: IDataPopup = {
      width: '480px',
      text: this.form.controls?.invitationText?.value || '',
    };

    this.popupS.open(PopupCustomizeInvitationComponent, dataPopup).then((res: any) => {
      if (typeof res !== 'string') return;
      this.form.controls?.invitationText?.patchValue(res);
      this.cd.detectChanges();
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({ arrControls: this.formBuilder.array([]), invitationText: [''] });
    this.arrControls.push(this.formBuilder.group({
      competitionId: this.competitionS.competition?.id || '',
      id: '',
      name: ['', Validators.required],
      roleDrop: ['', Validators.required],
      userId: '',
      email: '',
    }));
    if (!this.arrControls.controls?.length) this.addNew(undefined, ' subscribeToCompetition ');
  }

  get arrControls(): FormArray<FormGroup<IFormItemCompetitionsUsers>> {
    return this.form?.controls?.arrControls!;
  }

  addNew(item?: ClassCompetitionUser, forTest?: string): void {
    const newFormGroup: FormGroup<IFormItemCompetitionsUsers> = this.formBuilder.group({
      id: [item?.id || ''],
      competitionId: [item?.competitionId || ''],
      name: [item?.name || '', Validators.required],
      roleDrop: [item?.roleDrop || '', Validators.required],
      userId: [''],
      email: [''],
    });
    this.arrControls.push(newFormGroup);
  }

  addNewUserFromDropDown(itemCtrl: FormGroup<IFormItemCompetitionsUsers>, itemDrop: ClassUser): void {
    const propertyName_isEmail = EMAIL_REGEXP.test(itemDrop.name || '');
    if (propertyName_isEmail) itemDrop.email = itemDrop.name;
    delete itemDrop.name;

    const newUserFromDrop = {
      competitionId: this.competitionS.competition?.id || '',
      id: itemDrop.id,
      userId: itemDrop.userId,
      email: itemDrop.email,
      name: itemDrop.email,
      roleDrop: itemCtrl.value.roleDrop,
    };
    itemCtrl.patchValue(newUserFromDrop);
    this.searchText = '';
    this.cd.detectChanges();
  }

  searchUsersInCompetition(searchText: string, matMenuTrigger?: MatMenuTrigger): void {
    this.searchText = searchText;
    if (!searchText?.trim()) return;
    of(searchText).pipe(
      delay(300),
      switchMap((res) => {
        return this.apiCompetitionS.searchUsersInCompetition(searchText, this.competitionS.competition.id!);
      }),
      untilDestroyed(this),
    ).subscribe((res) => {
      if (matMenuTrigger) { 
        matMenuTrigger?.closeMenu();
        this.usersForDropDown = res;
        this.cd.detectChanges(); 
        matMenuTrigger?.openMenu();
      } else { 
        this.competitionS.competition.users = res;
      }
    });
  }
  
  inviteUsersInCompetition(): void {
    if (this.startRequest()) return;
  
    if (!this.selectedCompetitions || this.selectedCompetitions.length === 0) {
      console.error('No competitions selected.');
      this.endRequest();
      return;
    }
  
    const formValue = this.form?.getRawValue();
  
    if (!formValue || !formValue.arrControls) {
      console.error('Form controls are undefined.');
      this.endRequest();
      return;
    }
  

    const arrControls = formValue.arrControls.flatMap((user) => {
      const email = EMAIL_REGEXP.test(user.name || '') ? user.name : user.email; 
      return this.selectedCompetitions.map((competition) => ({
        competitionId: competition.id,
        email: email || '', 
        role: user.roleDrop?.upperCase || '', 
      }));
    });
  
    const sendObj: IResponseCompetitionUsers = { users: arrControls };
  
    console.log('Payload being sent:', sendObj);
  
    this.competitionS.inviteUsersInMultipleCompetition(sendObj)
      .pipe(
        finalize(() => this.endRequest()),
        untilDestroyed(this),
      )
      .subscribe({
        next: (res) => {
          console.log('Invitations sent successfully:', res);
          this.pageUsersSub$.next('listUsers');
          this.back.emit();
        },
        error: (err) => {
          console.error('Error sending invitations:', err);
        },
      });
  }
  
  checkExistError(itemCtrl: FormGroup<IFormItemCompetitionsUsers>): boolean {
    const isEmail = EMAIL_REGEXP.test(itemCtrl?.value?.name || '');
    if (isEmail) return false;
    const result = !this.usersForDropDown?.length && !!this.searchText && !itemCtrl?.controls?.userId?.value;
    return result;
  }

  openPageNewUser(): void {
    this.pageUsersSub$.next('newUsers');
    this.createForm();
    this.searchText = '';
    this.cd.detectChanges();
  }

  navigateBackToMainPage(): void {
    this.back.emit();
  }
  

}
