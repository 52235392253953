import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameNotesPopupService } from '@services/game-notes-popup.service';
import { ClassGame, TStatusGame } from '@app/dir_group_assignor/games/game';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { DeviceService } from '@services/device.service';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { StateGameNotesWindow } from '@app/dir_group_assignor/games/components/game-notes/game-notes.component';

@Component({
  selector: 'app-table-cell-game-notes',
  standalone: true,
  imports: [CommonModule, SvgComponent, StopPropagationDirective],
  templateUrl: './table-cell-game-notes.component.html',
  styleUrls: ['./table-cell-game-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellGameNotesComponent implements OnChanges {
  isVisible!: boolean;
  popupWindowsState!: StateGameNotesWindow;
  @Input() game?: ClassGame;

  constructor(public gameNotesPopupService: GameNotesPopupService, public deviceService: DeviceService) { }

  popupGame() {
    this.gameNotesPopupService.popupGameNotesForTableByGame(this.game!);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['game']) {
      this.updateValue();
    }
  }

  updateValue() {
    this.isVisible = this.getIsVisible();
    this.popupWindowsState = this.gameNotesPopupService.getPopupWindowsStateForTable(this.game?.gameStatus!);
  }

  public getIsVisible(): boolean {
    const isEmpty = this.game?.gameDescription == null || this.game?.gameDescription?.length == 0;
    const isMobileCancel = this.deviceService.isMobile && this.game?.gameStatus == 'CANCELLED';
    return (isEmpty || isMobileCancel) == false;
  }
}
