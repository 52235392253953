import { Injectable } from "@angular/core";
import { ClassGame, TStatusGame } from "@app/dir_group_assignor/games/game";
import { GameNotesComponent, StateGameNotesWindow } from "@app/dir_group_assignor/games/components/game-notes/game-notes.component";
import { PopupService } from "./popup.service";
import { MainService } from "./main.service";
import { MeService } from "./me.service";


@Injectable({ providedIn: 'root' })
export class GameNotesPopupService {

    constructor(
        private popupService: PopupService,
        private mainService: MainService,
        private meService: MeService,

    ) {
    }
    public getPopupWindowsStateForTable(gameStatus: TStatusGame): StateGameNotesWindow {
        if (this.meService.ADMIN == false && this.meService.GROUP_ASSIGNOR == false)
            return 'readonly';
        if (gameStatus == 'ACTIVE')
            return 'preview';
        else
            return 'readonly';
    }
    popupGameNotesForTableByGame(game: ClassGame): void {
        this.popupService.open(GameNotesComponent, ({
            text: game.gameDescription,
            stateWindow: this.getPopupWindowsStateForTable(game.gameStatus!)
        } as any)).then(description => {
            if (!description)
                return;
            game!.gameDescription = description;
            this.mainService.methodGame({ id: game.id }, 'get').toPromise()
                .then((game?: ClassGame) => {
                    game!.gameDescription = description;
                    game!.date = new Date(game!.date!).toISOString().replace('Z', '');
                    this.mainService.methodGame(game!, 'put', false).toPromise()
                        .then((res?: ClassGame) => {
                        });
                });
        });
    }

    popupGameNotes(text: string, stateWindow: StateGameNotesWindow): Promise<string> {
        return this.popupService.open(GameNotesComponent, ({
            text: text,
            stateWindow: stateWindow
        } as any));
    }

}
