<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              me: (meS.me$|async),
              width1_forContactItem: 239,
             } as obj'
     class='wrapperMeProfileOfficialInfo wrapperMeProfileOfficialInfo--{{isEdit}}' [formGroup]='form'>

  <div *ngIf='obj.desktop' class='headerContact'>
    <headTitle text='My Profile' typeText='text1-blueDark' svgLeft='arrow_squareLeft&24' (svgLeftEmit)='otherS.locationBack()'></headTitle>
    <ng-container [ngTemplateOutlet]='actionsTemplate'></ng-container>
  </div>

  <titleAndNavigationForProfile *ngIf='obj.mobile' [typePageProfile]='meProfileOfficialS.typePageProfile'
                                [showNavigation]='!isEdit'></titleAndNavigationForProfile>

  <div *ngIf='!isEdit' class='contentContact' [class.contentContact--desktop]='obj.desktop'>
    <photo [data]='obj.me' [w]='obj.desktop ? "160" : "96"' fs='48' typeText='text1-blueDark' class='contentContact__photo'></photo>
    <div class='contentContact__name'>{{ ctrl.firstName?.value }} {{ ctrl.secondName?.value }}</div>
    <ng-container *ngIf='obj.mobile' [ngTemplateOutlet]='actionsTemplate'></ng-container>
    <div class="panel-standart-mobile panel-standart-mobile--read">
      <contactItem text1='Phone Number' [text2]='ctrl.phone?.value|formatPhone' [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Email' [text2]='ctrl.email?.value' [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Residential Address' [text2]='ctrl.address?.value?.street'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Street Address Line 2' [text2]='ctrl.address?.value?.streetLine2'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='City & State' [text2]='ctrl.address?.value?.city + ", " + ctrl.address?.value?.state'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Zip Code' [text2]='ctrl.address?.value?.zipcode' [width1]='obj.width1_forContactItem'></contactItem>

      <contactItem text1='Date of Birth' [text2]='ctrl.dateOfBirth_formatted?.value' [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Age' [text2]='(ctrl.dateOfBirth?.value!|getAgeByDateOfBirth)?.toString()'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <ng-container>
        <div class='certId-container' [style.border-bottom]="ctrl.organizationUserId?.value ? 'none' : ' 1px solid #D0D5DD'">
          <contactItem text1='Cert ID' [text2]='(ctrl.organizationUserId?.value | formatOrganizationUserId)'
                       [width1]='obj.width1_forContactItem'></contactItem>
          <div *ngIf='ctrl.organizationUserId?.value' class='status-container'>
            <div *ngIf='ctrl.certified' class='eligible-button'>Eligible</div>
            <div *ngIf='!ctrl.certified' class='not-eligible-button'>Not Eligible</div>
          </div>
        </div>
      </ng-container>

      <contactItem text1='Governing Body' [text2]='ctrl.organization?.value'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Referee Grade' [text2]='ctrl.licenseName?.value'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Social Security Number (SSN)' [text2]='ctrl.socialSecurity?.value'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Emergency Contact' [text2]='ctrl.emergencyName?.value' [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Phone No.' [text2]='ctrl.emergencyPhoneNumber?.value|formatPhone'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem *ngIf='ctrl.dateOfBirth?.value!|isLessAge18' text1='Secondary Email' [text2]='ctrl.secondaryEmail?.value'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Relationship' [text2]='ctrl.emergencyRelationshipDrop?.value?.titleCase' [width1]='obj.width1_forContactItem'
                   class='o-bordNone'></contactItem>
    </div>
  </div>

  <!--  === FOR EDIT ============================================-->
  <div *ngIf='isEdit' class='contentContact' [class.contentContact--desktop]='obj.desktop'>
    <fieldRow *ngIf="obj.desktop" text='Personal info' text2='Update your personal details here.' class='o-itemEditContact'
              [class.o-itemEditContact--border]="obj.desktop">
    </fieldRow>

    <div *ngIf="obj.mobile" class="header">
      <div class="header-label">
        Personal info
      </div>
      <div class="header-text">
        Update your personal details here.
      </div>
    </div>
    <fieldRow text='' text2='This will be displayed on your profile.' class='hide-on-mobile o-itemEditContact o-itemEditContact--border'
              [width]='widthLeftField' alignItems='center'>
      <div class='o-itemEditContact__photo'>
        <photo [data]='obj.me' w='64'></photo>
        <!--        !!! NO DELETE ============================-->
      </div>
    </fieldRow>
    <div class="panel-standart-mobile panel-standart-mobile--edit">
      <fieldRow text='First Name' class='o-itemEditContact' [width]='widthLeftField'>
        <inputCtrl formControlName='firstName' placeholder='First Name' [w]='widthInputForDesktop'></inputCtrl>
      </fieldRow>
      <fieldRow text='Last Name' class='o-itemEditContact o-itemEditContact--border' [width]='widthLeftField'>
        <inputCtrl formControlName='secondName' placeholder='Second Name' [w]='widthInputForDesktop'></inputCtrl>
      </fieldRow>
      <fieldRow text='Date of Birth' class='o-itemEditContact' [width]='widthLeftField'>
        <selectDate placeholder='Select Date of Birth' [value]='ctrl.dateOfBirth?.value' (emit)='ctrl.dateOfBirth.patchValue($event)'
                    [w]='widthInputForDesktop'></selectDate>
      </fieldRow>
      <fieldRow text='Social Security Number (SSN)' class='o-itemEditContact o-pad-b-0' [width]='widthLeftField'>
        <inputCtrl formControlName='socialSecurity' placeholder='Enter Last 4 Digits' [isNumber]='true' [allowFirstZero]='true'
                   [maxLength]='4' [w]='widthInputForDesktop'></inputCtrl>
      </fieldRow>
      <fieldRow text='Email Address' class='o-itemEditContact o-pad-b-0' [width]='widthLeftField'>
        <div class='o-itemEditContact__col_2'>
          <inputCtrl formControlName='email' placeholder='Email' [showErrText]='true'></inputCtrl>
          <btn *ngIf='ctrl.email?.valid && ctrl.email?.value !== obj.me?.email' text='Confirm' bcg='blueDark'
               (emit)='sendCodeTo_email_or_phone("email")' btnWidth='84' btnHeight='36' [disabled]='reqPending'></btn>
        </div>
      </fieldRow>
      <fieldRow text='Phone No.' class='o-itemEditContact o-itemEditContact--border' [width]='widthLeftField'>
        <div class='o-itemEditContact__col_2'>
          <phone #phoneComponent [phoneValue]='ctrl.phone?.value' [disabled]='!!ctrl.phone?.disabled' text=''></phone>
          <div class='game-reminder'>
            <div class='gameReminderOn' (click)='this.gameReminderOnClicked()'>
              <meSvg [svgName]='ctrl?.gameReminderOn?.value | getChxSvgName'></meSvg>
              Text me game reminders
            </div>

            <div class='disclaim-text-1'>
              By entering my cell phone number, I agree to receive game reminders from Notch.
            </div>
            <div class='disclaim-text-2'>
              Message frequency varies by assignments. Message + data rates may apply.
              Reply STOP to unsubscribe or HELP for help. View
              <span style='font-weight: 600;'>privacy policy </span> <a class='a-privacy-policy' target='_blank'
                 href='https://www.joinnotch.com/privacy'>here</a> .
            </div>
          </div>
          <btn *ngIf='phoneComponent?.valid && (phoneComponent.value|replace:["+"]) !== (obj.me?.phone!|replace:["+"])'
               text='Confirm' bcg='blueDark' (emit)='sendCodeTo_email_or_phone("phone",phoneComponent.value)' [btnWidth]='obj.mobile?"100%":311' [disabled]='reqPending'></btn>
        </div>
      </fieldRow>
      <location page='contact' [fields]='fields' [requiredFields]='requiredFields'
                class='o-itemEditContact o-itemEditContact--border o-pad-b-0' [widthForFieldRow]='widthLeftField'
                [replaceNameField]='{street: "Street Address",streetLine2:"Street Address Line 2"}'></location>
      <fieldRow text='Cert ID' class='o-itemEditContact'>
        <inputCtrl formControlName='organizationUserId' placeholder='Certification ID' [w]='widthInputForDesktop'
                   (input)='onCertificationIdInput($event)'></inputCtrl>
      </fieldRow>
      <fieldRow text='Emergency Contact Name' class='o-itemEditContact o-pad-b-0' [width]='widthLeftField'>
        <inputCtrl formControlName='emergencyName' placeholder='Emergency Contact Name' w='100%'></inputCtrl>
      </fieldRow>
      <fieldRow text='Relationship' class='o-itemEditContact o-pad-b-0' [width]='widthLeftField'>
        <app-dropdown formControlName='emergencyRelationshipDrop' [array]='arrEmergencyRelationshipDrop' placeholder='Relationship' class="select-normal-16-400 "
                      width='100%'></app-dropdown>
      </fieldRow>
      <fieldRow *ngIf='ctrl.dateOfBirth?.value!|isLessAge18' text='Secondary Email' class='o-itemEditContact o-pad-b-0'
                [width]='widthLeftField'>
        <div class='o-itemEditContact__col_2'>
          <inputCtrl formControlName='secondaryEmail' placeholder='Email' [showErrText]='true'></inputCtrl>

          <div class="sameAsPrimaryEmail" (click)='this.sameAsPrimaryEmailOnClicked()'>
            <meSvg [svgName]='this.sameAsPrimaryEmail | getChxSvgName'></meSvg>
            <div>Same as primary email</div>
          </div>


        </div>
      </fieldRow>
      <fieldRow text='Phone No.' class='o-itemEditContact' [width]='widthLeftField'>
        <div class='o-itemEditContact__col_2'>
          <inputCtrl formControlName='emergencyPhoneNumber' placeholder='-'></inputCtrl>
        </div>
      </fieldRow>
    </div>
    <ng-container *ngIf='obj.mobile' [ngTemplateOutlet]='actionsTemplate'></ng-container>
  </div>

  <!--  === ACTIONS BUTTONS ===========================================-->
  <ng-template #actionsTemplate>
    <div *ngIf='!isEdit' class='headerContact__btns'>
      <btn text='Edit' bcg='blueDark' [btnWidth]='obj.mobile ? "100%":58' [btnHeight]='obj.mobile ? 36:""' (click)='isEdit=true'></btn>
    </div>
    <div *ngIf='isEdit' class='headerContact__btns'>
      <btn text='Cancel' bcg='white' (click)='cancel()' [disabled]='reqPending' [btnWidth]='obj.mobile ? "100%":89'
           class='headerContact__btns__cancel'></btn>
      <btn text='Save' bcg='blueDark' (click)='submit()' [spinner]='reqPending' [btnWidth]='obj.mobile ? "100%":65'
           [disabled]='!form.valid'></btn>
    </div>
  </ng-template>
</div>