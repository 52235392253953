import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { catchError, map } from 'rxjs/operators';
import { MeService } from '@services/me.service';
import { idCompetitionForPath, str_setup_competition } from '@app/app.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';

// !!! в резолвере сначала вызывается competitionS.getCompetitionById()
// !!! потом competitionS.getData()
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CompetitionResolver  {
  competitionId_fromUrl?: string; // !!! если нет id, то это setup_competition или competitions/list
  isSetupCompetition = true; // !!! это setup_competition

  constructor(
    private competitionS: CompetitionService,
    private meS: MeService,
  ) {
  }

  // !!! для страниц 'list' не подключен CompetitionResolver
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.competitionId_fromUrl = route.params[idCompetitionForPath];
    this.isSetupCompetition = route.params[idCompetitionForPath] === str_setup_competition;

    if (!this.meS.meId) {
      // !!! NO DELETE. это для страниц авторизации. Тоесть если не залогинен, то не надо this.getCompetitionById(), потому что вернется 401 ошибка
      return of(true);
    }

    // !!! если есть competitionId в url И если это НЕ setup_competition, то надо запросы на сервер отправлять
    if (this.competitionId_fromUrl && !this.isSetupCompetition) {
      let observable: Observable<ClassCompetition | undefined | boolean>;
      // !!! если текущий компетишн === id компетишна из url, то НЕ надо заново запрос на сервер отправлять
      if (this.competitionId_fromUrl === this.competitionS.competition?.id) {
        observable = of(true);
      } else {
        observable = this.competitionS.getCompetitionById(this.competitionId_fromUrl);
      }

      return observable.pipe(
        switchMap((res) => this.competitionS.getData(this.competitionId_fromUrl!)),
        map(res => true),
        untilDestroyed(this),
        catchError(() => of(false)),
      );
    } else {
      return of(true);
    }
  }

}
