import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, NO_ERRORS_SCHEMA, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { MeService } from '@services/me.service';
import { HelperClass } from '@classes/Helper-Classes';
import { NavigationStart, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MainService } from '@services/main.service';
import { DashboardService } from '@app/dir_group_assignor/dashboard/dashboard.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClassUser, TUserRoleUpperCase, userRole_ADMIN, userRole_GROUP_ASSIGNOR, userRole_OFFICIAL } from '@models/user';
import Median from "median-js-bridge";
import { MenuNavigationComponent } from '../menu-navigation/menu-navigation.component';
import { colorObj } from "../../../shared/classes/CSS";
import {
  authRoutes,
  urlAssign,
  urlDashboard,
  urlGames,
  urlMyGames,
  urlMyWallet,
  urlOfficials,
  urlOpenGames,
  urlReports,
} from '@app/app.module';
import { RoutesService } from '@services/routes.service';
import { BtnComponent } from '@components/btn/btn.component';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'menuMobile',
  standalone: true,
  imports: [CommonModule, RouterLinkActive, RouterLink, MenuNavigationComponent, BtnComponent],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuMobileComponent extends HelperClass implements OnInit {
  protected readonly userRole_OFFICIAL = userRole_OFFICIAL;
  protected readonly userRole_GROUP_ASSIGNOR = userRole_GROUP_ASSIGNOR;
  protected readonly userRole_ADMIN = userRole_ADMIN;
  tabMenuItems: any[] = [];

  @Output() openMenuEvent = new EventEmitter();
  @Output() closeMenuEvent = new EventEmitter();

  constructor(
    public dashboardS: DashboardService,
    public deviceS: DeviceService,
    public meS: MeService,
    public mainS: MainService,
    public cd: ChangeDetectorRef,
    private router: Router,
    private routesS: RoutesService
  ) {
    super(cd);

  }

  ngOnInit() {
    this.meS.me$.pipe(untilDestroyed(this)).subscribe((res?: ClassUser) => {
      if (res?.email && res?.emailValidated)
        Median.onesignal.login(res?.email ?? "");

      if (!res?.roleCurrent) return;
      this.setMobileMenu(res?.roleCurrent);
      Median.onesignal.tags.setTags({ tags: { role: res?.roleCurrent ?? "" } });
    });

    this.subscribeToAuthRouting();
  }

  subscribeToAuthRouting(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      untilDestroyed(this),
    ).subscribe((res: any) => {
      if (res && res.url) {
        if (authRoutes.includes(res.url.substring(1)))
          Median.tabNavigation.setTabs({ 'enabled': false, 'items': [] });
        else if (this.tabMenuItems.length === 0)
          Median.tabNavigation.setTabs({ 'enabled': true, 'items': this.tabMenuItems });
      }
    })
  }

  setMobileMenu(userRole: TUserRoleUpperCase) {
    if (userRole === userRole_OFFICIAL) {
      this.tabMenuItems = [{
        "icon": "fal fa-home-alt",
        "label": "Home",
        "url": `/${urlDashboard}`,
        "regex": `.*/${urlDashboard}.*`
      }, {
        "icon": "fal fa-calendar",
        "label": "My Games",
        "url": `/${urlMyGames}`,
        "regex": `.*/${urlMyGames}.*`
      }, {
        "icon": "fal fa-calendar-check",
        "label": "Open Games",
        "url": `/${urlOpenGames}`,
        "regex": `.*/${urlOpenGames}.*`
      }, {
        "icon": "fal fa-flag-alt",
        "label": "Post Game",
        "url": `/${urlReports}`,
        "regex": `.*/${urlReports}.*`
      }, {
        "icon": "fal fa-wallet",
        "label": "My Wallet",
        "url": `${urlMyWallet}`,
        "regex": `.*${urlMyWallet}.*`
      }];
    } else if (userRole === userRole_GROUP_ASSIGNOR) {
      this.tabMenuItems = [{
        "icon": "fal fa-home-alt",
        "label": "Home",
        "url": `/${urlDashboard}`,
        "regex": `.*/${urlDashboard}.*`
      }, {
        "icon": "fal fa-calendar",
        "label": "Assign",
        "url": `/${urlAssign}`,
        "regex": `.*/${urlAssign}.*`
      }, {
        "icon": "fal fa-whistle",
        "label": "Games",
        "url": `/${urlGames}`,
        "regex": `.*/${urlGames}.*`
      }, {
        "icon": "fal fa-flag-alt",
        "label": "Post Game",
        "url": `/${urlReports}`,
        "regex": `.*/${urlReports}.*`
      }, {
        "icon": "fal fa-users",
        "label": "Officials",
        "url": `/${urlOfficials}`,
        "regex": `.*/${urlOfficials}.*`
      }];
    } else if (userRole === userRole_ADMIN) {
      this.tabMenuItems = [{
        "icon": "fal fa-whistle",
        "label": "Games",
        "url": `/${urlGames}`,
        "regex": `.*/${urlGames}.*`
      }, {
        "icon": "fal fa-ellipsis-h",
        "label": "More",
        "url": "/internal-menu"
      }];
    }

    Median.tabNavigation.setTabs({ 'enabled': true, 'items': this.tabMenuItems });
    Median.tabNavigation.selectTab(0);
    Median.statusbar.set({
      'style': 'auto',
      'color': userRole === userRole_OFFICIAL ? colorObj.newGreen : colorObj.blueDark,
      'overlay': false,
      'blur': false
    });

    Median.jsNavigation.url.addListener((url: any) => {
      if (url.url.indexOf("internal-menu") !== -1) {
        this.openMenuEvent.emit();
      }
      else {
        const cleanedPathUrl = this.getPathFromUrl(url.url);
        this.closeMenuEvent.emit();
        this.changeLink(cleanedPathUrl);
      }
    });
  }

  getPathFromUrl(url: string): string {
    try {
      if (url.startsWith("/"))
        return url;

      const parsedUrl = new URL(url);
      return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
    } catch (error) {
      console.error('Invalid URL:', error);
      return '/dashboard';
    }
  }

  changeLink(url: string): void {
    if (this.routesS.urlWithoutQueryParams === url.slice(1)) return;
    this.router.navigate([url]);
  }
}
