<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              isViewAll: (myWalletS.isViewAll$|async),
             } as obj'
     class='o-wrapPage wrapperWallet' wrapPage [widthPage]='myWalletS.widthPage'>

  <div class='o-content'>
    <ng-container *ngIf='!obj.isViewAll'>
      <headTitle text='My Wallet' [typeText]='obj.desktop ? "text1-blueDark":"text2-blueDark"'></headTitle>

      <div class='wallet-container'>
        <div class='wallet-block green'>
          <div class='wallet-block__first-line'>
            <div *ngIf='obj.desktop' class='wallet-block__first-line--text'>
              <span class='o-text4'>Available Payout</span>
              <span class='o-text8'>The amount of money you can get now!</span>
            </div>
            <div class='o-text0'>{{ (balance / 100) | currency:'USD' }}</div>
          </div>
          <div *ngIf='obj.mobile' class='o-text6-blueDark o-text-center'>US Dollars</div>
          <btn text='Payout' bcg='newGreen' btnHeight='36' [disabled]='balance === 0 || !balance' (click)='onPayout()' class='o-mg-t-auto'></btn>
        </div>

        <div class='wallet-block grey'>
          <div class='wallet-block__first-line'>
            <div class='wallet-block__first-line--text'>
              <span class='o-text4'>Payment method</span>
              <span class='o-text8'>Your default payment method used for getting paid.</span>
            </div>
            <div>
              <spinner *ngIf='loading; else addBtnTpl' [diameter]='20' [isNoMargin]='true'></spinner>
              <ng-template #addBtnTpl>
                <meSvg *ngIf='obj.desktop' svgName='plus_square&20' [w]='44' [h]='44' (click)='onPaymentMethodActionClick()'></meSvg>
              </ng-template>
            </div>
          </div>

          <div class='card-container g-flex' [class.g-flex--align-center]='!accounts.length' (click)='onPaymentMethodActionClick()'>
            <ng-container *ngIf='accounts.length; else noAccountsTpl'>
              <app-account-payment *ngIf='accountDefault' [isDisabled]='loading' class='g-flex__item' [account]='accountDefault'
                                   mode='view'></app-account-payment>
            </ng-container>

            <ng-template #noAccountsTpl>
              <div class='no-cards-label o-text7 g-flex g-flex--align-center'>
                <meSvg svgName='plus_1&20'></meSvg>
                <div>Add New Payment Method</div>
              </div>
            </ng-template>
          </div>

          <btn *ngIf='obj.mobile' text='Manage' bcg='blueDark' (emit)='onPaymentMethodActionClick()' btnHeight='36'></btn>
        </div>
      </div>

      <div class='wallet-block grey forRefreshPage'>
        <div class='forRefreshPage__text'>
          <span>If you are expecting to see a balance in your Notch Wallet, it may be necessary to refresh the page temporarily.</span>
          <span>
        <span>Please note: </span>
        once the center referee submits a report and your assignor approves it, funds will appear in your Wallet.
      </span>
          <span>You can check the status of a report in Post Game.</span>
        </div>
        <btn text='Refresh' bcg='blueDark' [btnWidth]='obj.desktop ? "94":""' btnHeight='40' (click)='otherS.reload()'></btn>
      </div>
    </ng-container>

    <myWalletTable></myWalletTable>
  </div>
</div>

