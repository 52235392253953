import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceService } from '@services/device.service';
import { HeadTitleComponent } from '@components/head-title/head-title.component';

@Component({
  selector: 'app-game-notes',
  standalone: true,
  imports: [CommonModule, FormsModule, BtnWrapComponent, BtnComponent, HeadTitleComponent],
  templateUrl: './game-notes.component.html',
  styleUrls: ['./game-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameNotesComponent {
  public text: string = "";
  public isReadOnly!: boolean;
  public stateWindow!: StateGameNotesWindow;
  constructor(
    public deviceS: DeviceService,
    public dialogRef: MatDialogRef<GameNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { text: string, stateWindow: StateGameNotesWindow },
    public cd: ChangeDetectorRef,
  ) {
    if (data.text)
      this.text = data.text;
    this.updateState(data.stateWindow);
  }

  updateState(stateWindow: StateGameNotesWindow) {
    this.stateWindow = stateWindow;
    switch (stateWindow) {
      case 'edit':
        this.isReadOnly = false
        break;
      case 'preview':
      case 'readonly':
        this.isReadOnly = true
        break;
    }
  }

  save(): void {
    this.dialogRef.close(this.data.text);
  }

  edit() {
    this.updateState('edit');
  }
}

export type StateGameNotesWindow = 'edit' | 'preview' | 'readonly';
