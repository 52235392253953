import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MainService } from '@services/main.service';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClassPayMethodAndAmount,
  IPaymentMethod,
  ISendObjCreatePaymentMethod,
  TypePay,
} from '@app/dir_group_assignor/payments/modelsForPayment';
import {  FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { OtherService } from '@services/other.service';
import { FundService } from '@services/funds.service';
import { PlaidService } from '@services/plaid.service';
import { switchMap, take } from 'rxjs/operators';
import { DeviceService } from '@services/device.service';
import { SafePipe } from '@pipes/safe.pipe';
import {
  ToggleBankCardComponent,
} from '@app/dir_group_assignor/payments/additional-components/toggle-bank-card/toggle-bank-card.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { StripeFormComponent } from '@components/stripe-form/stripe-form.component';
import { LineComponent } from '@components/line/line.component';
import { BtnComponent } from '@components/btn/btn.component';
import { GetSelectedElemsPipe } from '@pipes/get-selected-elems.pipe';
import { PayMethodComponent } from '@app/dir_group_assignor/payments/additional-components/pay-method/pay-method.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { HelperClass } from '@classes/Helper-Classes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopupConfirmPaymentComponent } from '@components/__popup-windows/popup-confirm-payment/popup-confirm-payment.component';
import { Observable, of } from 'rxjs';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersService } from '@components/filters/filters.service';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { ComingSoonMobile } from '@app/dir_group_assignor/dashboard/components/coming-soon-mobile/coming-soon-mobile.component';
import { DropDateRangeComponent } from '@components/__drop_inputs_matSelect/drop-date-range/drop-date-range.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { PaymentBalancesMobileComponent } from '../payment-balances-mobile/payment-balances-mobile.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { MatMenuModule } from '@angular/material/menu';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { ContainerTooltipComponent } from '@components/__tooltip/container-tooltip/container-tooltip.component';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { StatusComponent } from '@components/status/status.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { BlockChartComponent } from '@app/dir_group_assignor/payments/additional-components/block-chart/block-chart.component';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { RoundBalancePipe } from '@app/dir_group_assignor/payments/pipes/round-balance.pipe';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { FiltersSelectedIconComponent } from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { TooltipDefaultComponent } from '@components/__tooltip/tooltip-default/tooltip-default.component';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { ClassCompetition, ClassDay, ClassPaymentMethod } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { IDataPopup, PopupService } from '@services/popup.service';
import { PopupConfirmComponent } from '@components/__popup-windows/popup-confirm/popup-confirm.component';
import { StripePopupComponent } from '@components/stripe-popup/stripe-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';




interface TopupData {
  topupDate: string;
  outflow: number;
  balance: number;
  buffer: number;
  netPayin: number;
}

interface TopupHistory {
  id: string;
  status: string;
  createdAt: string;
  totalAmount: number;
  fee: number;
  paymentMethod: string;
}

@UntilDestroy()
@Component({
  selector: 'app-payments-balance-auto-topup',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SafePipe, ToggleBankCardComponent, InputCtrlComponent, StripeFormComponent, LineComponent, BtnComponent, GetSelectedElemsPipe, PayMethodComponent, SvgAndTextComponent, PopupConfirmPaymentComponent, DropdownComponent, FiltersComponent,
    CommonModule, DropDateRangeComponent, DropFormCtrlComponent, FormsModule, SvgAndTextComponent, SvgComponent, BtnComponent, PaymentBalancesMobileComponent,
    MatMenuModule, NothingListComponent, SpinnerComponent, StatusComponent, HeadTitleComponent, GetStylesPipe, RoundBalancePipe, ReactiveFormsModule, BlockChartComponent, DropdownComponent, FiltersComponent, FiltersSelectedComponent, FiltersSelectedIconComponent, LinkPageComponent, WrapPageDirective, GetTableEmptyPipe, PaginationComponent, TooltipDefaultComponent, ContainerTooltipComponent, InputCtrlComponent, PaginationWithSettingsComponent, TableEmptyComponent, TableInfiniteLoadingComponent, TableHeaderComponent, ComingSoonMobile, LinkPageSearchFilterComponent, BtnWrapComponent, PopupConfirmComponent, StripePopupComponent],
  templateUrl: './payments-balance-auto-topup.component.html',
  styleUrls: ['./payments-balance-auto-topup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FiltersService, MeTableService, SettingsRequestService, CurrencyPipe],
})
export class PaymentsBalanceAutoTopupComponent extends HelperClass implements  OnInit, AfterViewInit {
  selectedMethod?: IPaymentMethod;
  showConfirmPopup: boolean = false;

  // === FROM 2 VERSION ===========================================================
  srcIFrame?: any;
  payMethodAndAmount: ClassPayMethodAndAmount = new ClassPayMethodAndAmount(); // изменять ссылку, чтобы дочерний компонент отреагировал => new ClassPayMethodAndAmount(method, amount)
  rememberBankCtrl = new FormControl(false);
  showNewCard = false;
  form!: FormGroup;
  daysOfWeek: ClassDay[] = [
    new ClassDay({ dayName: 'Monday', dayValue: 'monday' }),
    new ClassDay({ dayName: 'Tuesday', dayValue: 'tuesday' }),
    new ClassDay({ dayName: 'Wednesday', dayValue: 'wednesday' }),
    new ClassDay({ dayName: 'Thursday', dayValue: 'thursday' }),
    new ClassDay({ dayName: 'Friday', dayValue: 'friday' })
  ];

  tabOptions = [
    { titleCase: 'Top-Up Schedule', upperCase: 'schedule' },
    { titleCase: 'Top-Up History', upperCase: 'history' }
  ];

  originalValues: any = {};
  isEnabled: boolean = true;
  showButtons: boolean = true;
  readonly widthPage = 1022;
  paymentMethodsForDropdown: ClassPaymentMethod[] = [];
  selectedDay: ClassDay | null = { dayName: 'Select Day', dayValue: '' };
  isLoading = false;

  isDropdownOpen = false;
  topupData: TopupData[] = [];
  topupHistory: TopupHistory[] = [];
  paymentMethods: IPaymentMethod[] = [];
  @ViewChild('dropdown') dropdown!: ElementRef;
  activeTab: 'schedule' | 'history' = 'schedule';
  activeTabMobile: { titleCase: string; upperCase: string } = { titleCase: 'Top-Up Schedule', upperCase: 'schedule' };
  
 currentMobileLink: ClassDrop = { titleCase: 'All Competitions', upperCase: 'all' };
  selectedPaymentMethod: IPaymentMethod | undefined;
  myGroup: any;
  showAddNewCard: boolean = false;
  isSaveChangesVisible: boolean = false;
  shouldCreateAutoTopup: boolean = false;
  isApproved: boolean = false;

  constructor(
    public cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private payS: PaymentService,
    private plaidS: PlaidService,
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    public otherS: OtherService,
    public deviceS: DeviceService,
    private currencyPipe: CurrencyPipe,
    public popupS: PopupService,
    public fundS: FundService,
    private dialog: MatDialog,
  ) {
    super(cd);
    this.fundS.setStripeFields(['cardNumber', 'cardExpiry', 'cardCvc', 'cardName', 'zip']);
  }

  ngOnInit() {
    this.createForm();
    this.plaidSubscribe();
    this.loadTopupData();
    this.loadTopupHistory();
    this.setCompetitionIdFromQueryParams();
    setTimeout(() => {
      this.getAutoTopupDetails();
  }, 0);
    this.myGroup = new FormGroup({
      bufferAmount: new FormControl()
    });  
    this.form.valueChanges.subscribe(() => {
      this.checkForChanges();
    });
    this.resetActiveTabMobile();
  }  

  setActiveTab(tab: 'schedule' | 'history'): void {
    this.activeTab = tab;
    this.cd.detectChanges();
  }

  setActiveTabMobile(tab: { titleCase: string; upperCase: string }): void {
    this.activeTabMobile = tab;
    this.activeTab = tab.upperCase as 'schedule' | 'history';
    this.cd.detectChanges();
  }

processPaymentMethods(): void {
  if (this.paymentMethods && this.paymentMethods.length > 0) {
      this.paymentMethodsForDropdown = this.paymentMethods.map(method => {
          const paymentBrand = method.cardDto?.brand || method.bankAccountDto?.bankName || 'Unknown Method';
          const last4 = method.last4 ? `**** ${method.last4}` : '****';
        //  const titleCase = `${paymentBrand} ${last4}`;
        const titleCase = `${paymentBrand} ${last4}`
        .toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase());

          return new ClassPaymentMethod({
              paymentBrand: paymentBrand,
              last4: last4,
              titleCase: titleCase, 
              upperCase: method.id,
              preparedForAutoTopup: method.preparedForAutoTopup || false,
          });
      });

  } else {
      this.paymentMethodsForDropdown = [];
  }

  const addNewOptions = [
      new ClassPaymentMethod({
          titleCase: 'Add a Payment Card',
          upperCase: 'ADD_NEW_CARD',
          cssClass: 'add-new-option',
          icon: 'assets/plus/plus_1&20.svg'
      }),
      new ClassPaymentMethod({
          titleCase: 'Add New Bank Account',
          upperCase: 'ADD_NEW_BANK',
          cssClass: 'add-new-option',
          icon: 'assets/plus/plus_1&20.svg'
      })
  ];

  this.paymentMethodsForDropdown = [...this.paymentMethodsForDropdown, ...addNewOptions];

 // console.log('Processed Payment Methods for Dropdown:', this.paymentMethodsForDropdown);
}

  onCompetitionChange(selectedCompetition: ClassCompetition): void {
  }

  ngAfterViewInit(): void {
  }

  get competitionsArray(): ClassCompetition[] {
    return this.payS.arrCompetition$.getValue() || [];
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      daySelection: [''],
      paymentMethod: [this.selectedPaymentMethod],
      bufferAmount: [''] 
    });
  }
  
  
  

  plaidSubscribe(): void {
    this.plaidS.newBankMethodFromPlaid$
      .pipe(untilDestroyed(this))
      .subscribe((res: IPaymentMethod | null) => {
        if (res) {
          this.setCompetitionIdFromQueryParams(); 
          this.selectedPaymentMethod = undefined;
        } else {
          this.selectedPaymentMethod = undefined;
          this.form.get('paymentMethod')?.setValue(null);
        }
        this.cd.detectChanges();
      });
  }

  loadTopupData(): void {
    this.getAutoTopupDetails();
  }
  loadTopupHistory(): void {
    this.getAutoTopupHistory();
  }
  onDayChange(selectedDay: ClassDay): void {
   // console.log('Selected day:', selectedDay.dayName);
    this.selectedDay = selectedDay;
    this.form.get('daySelection')?.setValue(selectedDay.dayValue);
  }
  

 get dayOptionsForDropdown(): ClassDay[] {
  return this.daysOfWeek;
}

  

  setCompetitionIdFromQueryParams(): void {
    this.route.queryParams
      .pipe(
        untilDestroyed(this),
        switchMap((params) => {
          if (params?.competitionId) {
            this.form.get('competitionId')?.setValue(params.competitionId);
            return this.payS.getPaymentMethodsCompetition(params.competitionId);
          } else {
            this.router.navigate(['balances']);
            return of(null);
          }
        })
      )
      .subscribe((res) => {
        if (res) {
          this.paymentMethods = res;
          this.processPaymentMethods();
          this.setSelectedPaymentMethod();
          this.cd.detectChanges();
        }
      });
  }

  setSelectedPaymentMethod(): void {
    const paymentMethodId = this.form.get('paymentMethod')?.value;
    if (paymentMethodId) {
      const matchingMethod = this.paymentMethodsForDropdown.find(
        method => method.upperCase === paymentMethodId
      );
      if (matchingMethod) {
        this.selectedPaymentMethod = matchingMethod;
        this.cd.detectChanges();
      } else {
        console.warn('No matching payment method found');
      }
    }
  }


  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }


  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (this.dropdown && !this.dropdown.nativeElement.contains(targetElement)) {
      this.isDropdownOpen = false;
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
  const clickedInside = this.dropdown?.nativeElement.contains(event.target);
  if (!clickedInside) {
    this.isDropdownOpen = false;
  }
}

showStripeForm: boolean = false;

  addNewCard(): void {
    this.showStripeForm = true;
    this.cd.detectChanges();
  }

  handlePaymentMethodCreated(paymentMethod: IPaymentMethod): void {
    this.showStripeForm = false;
    this.getCurrentMethods(paymentMethod);
    this.processPaymentMethods();
    this.selectedPaymentMethod = paymentMethod;
    console.log("Form structure:", this.form.controls);
    const paymentMethodControl = this.form.get('paymentMethod');
    if (paymentMethodControl) {
      paymentMethodControl.setValue(paymentMethod.id);
      // console.log("Payment method set to:", paymentMethodControl.value);
      this.cd.markForCheck();
      this.cd.detectChanges();
    }
  }

  getCurrentMethods(addNewMethod?: IPaymentMethod): void {
    if (addNewMethod) {
      this.paymentMethods.unshift(addNewMethod);
    }
  }

  goBack(): void {
    window.history.back();
  }

  onPaymentMethodChange(selectedPaymentMethod: ClassPaymentMethod): void {
    if (selectedPaymentMethod.upperCase === 'ADD_NEW_CARD') {
      this.showStripeForm = true;
    } else if (selectedPaymentMethod.upperCase === 'ADD_NEW_BANK') {
      this.getTokenAndLibraryPlaidCreateBank();
    } else if (!selectedPaymentMethod.preparedForAutoTopup) {
      const competitionId = this.getCompetitionId();
      if (selectedPaymentMethod.upperCase) this.openExistingConfirmationPopup(selectedPaymentMethod.upperCase, competitionId);
      this.selectedPaymentMethod = selectedPaymentMethod;
      this.form.get('paymentMethod')?.setValue(selectedPaymentMethod.upperCase);
    } else {
      this.selectedPaymentMethod = selectedPaymentMethod;
      this.form.get('paymentMethod')?.setValue(selectedPaymentMethod.upperCase);
    }
    this.cd.detectChanges();
  }

  openExistingConfirmationPopup(paymentId: string, competitionId: string): void {
    const isMobile = this.deviceS.isMobile;
    const dialogRef = this.dialog.open(PopupConfirmPaymentComponent, {
      data: {
        textTitle: 'Confirm Payment',
        text: 'By clicking "Confirm" you authorize Notch to debit the bank account specified above for any amount owed for charges arising from your use of Notch Pay services to pay in and fund a competition balance, pursuant to the Notch terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to Notch with 30 (thirty) days notice.',
        showCloseButton: false,
        closeOnApprove: true,
      },
      width: isMobile ? '90vw' : 'auto',
      maxWidth: isMobile ? '90vw' : 'auto'
    });

    dialogRef.componentInstance.approve.subscribe(() => {
      this.isApproved = true;
      this.confirmPaymentForCompetition(paymentId, competitionId, dialogRef);
    });

    dialogRef.componentInstance.close.subscribe(() => {
      dialogRef.close();
    });
    
    dialogRef.afterClosed().subscribe(() => {
      if (!this.isApproved) {
        this.clearSelectedPaymentMethod(); 
      }
    });
  }

  clearSelectedPaymentMethod(): void {
    window.location.reload();
  }
  
  confirmPaymentForCompetition(paymentId: string, competitionId: string, dialogRef: MatDialogRef<PopupConfirmPaymentComponent>): void {
    this.isLoading = true;
    this.mainS.confirmPaymentMethodAdd(paymentId, competitionId).toPromise()
      .then(() => {
        dialogRef.close();
      }).finally(() => {
      });
      setTimeout(() => {
        this.isLoading = false; 
        this.cd.detectChanges(); 
      }, 1000);
  }



  saveChanges(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const formData = this.form.value;
      const competitionId = this.route.snapshot.queryParams['competitionId'];
      const dayOfWeek = formData.daySelection || this.selectedDay?.dayValue;
      // const bufferAmount = formData.bufferAmount;
      // const bufferAmount = parseFloat(formData.bufferAmount.replace(/[^0-9.-]+/g, ''));
      const bufferValue = this.form.get('bufferAmount')?.value;
      const bufferAmount = parseFloat(
        typeof bufferValue === 'string' ? bufferValue.replace(/[^0-9.-]+/g, '') : bufferValue
      );
      const paymentMethod = this.form.get('paymentMethod')?.value;
      const enabled = true;
  
      if (competitionId) {
        const payload = {
          competitionId: competitionId,
          dayOfWeek: dayOfWeek.toUpperCase(),
          paymentMethod: paymentMethod,
          buffer: {
            amount: bufferAmount,
            currency: 'USD',
          },
          enabled: enabled,
        };
        if (!this.shouldCreateAutoTopup) {
          this.mainS.autoTopupUpdate(competitionId, payload).subscribe(
            (response) => {
              // console.log('Auto Top-Up updated successfully:', response);
            },
            (error) => {
              console.error('Error updating Auto Top-Up:', error);
            }
          );
        } else {
          this.mainS.autoTopupCreate(competitionId, payload).subscribe(
            (response) => {
              this.isEnabled = true;
              this.showButtons = true;
              this.cd.detectChanges();
            },
            (error) => {
              console.error('Error creating Auto Top-Up:', error);
            }
          );
        }
        setTimeout(() => {
          this.getAutoTopupDetails();
          this.isLoading = false; 
          this.cd.detectChanges(); 
        }, 2000);
      } else {
        console.error('Competition ID is required.');
      }
    } else {
      console.error('Invalid form data:', this.form.value);
    }
  }

  getAutoTopupHistory() {
    const competitionId = this.route.snapshot.queryParams['competitionId'];
    this.mainS.getAutoTopupHistory(competitionId).subscribe(
      (response) => {
        if (response && response.content) {
          this.topupHistory = response.content.map((item: any) => ({
            id: item.id,
            status: item.status,
            createdAt: item.createdAt,
            totalAmount: item.totalAmount,
            fee: item.fee,
            paymentMethod: item.paymentMethodType
          }));
          this.cd.detectChanges();
        }
      },
      (error) => {
        console.error('Error fetching auto-topup history:', error);
      }
    );
  }
  
  

  getAutoTopupDetails() {
    const competitionId = this.route.snapshot.queryParams['competitionId']; 
    this.mainS.getAutoTopupDetails(competitionId).subscribe(
      (response) => {
        if (response && response.autoTopup) {
          if (response.schedule && response.schedule.scheduledPayments) {
            this.topupData = response.schedule.scheduledPayments.map((payment: { date: any; outflow: { amount: any; }; balance: { amount: any; }; buffer: { amount: any; }; netPayIn: { amount: any; }; }) => ({
                topupDate: payment.date,
                outflow: payment.outflow.amount,
                balance: payment.balance.amount,
                buffer: payment.buffer.amount,
                netPayin: payment.netPayIn.amount,
            }));
        }
        this.isEnabled = response.autoTopup.enabled;
          const { dayOfWeek, buffer, paymentMethod } = response.autoTopup;
         // console.log(response.autoTopup)
          const selectedDay = this.daysOfWeek.find(day => day?.dayValue?.toUpperCase() === dayOfWeek);
          if (selectedDay) {
            this.selectedDay = selectedDay;
            this.form.get('daySelection')?.setValue(selectedDay.dayValue);
          }
          if (buffer?.amount) {
            const formattedBufferAmount = this.currencyPipe.transform(buffer.amount, 'USD', 'symbol', '1.2-2');
            this.form.get('bufferAmount')?.setValue(this.currencyPipe.transform(buffer.amount, 'USD', 'symbol', '1.2-2'), { emitEvent: false });
            console.log(formattedBufferAmount, 'formattedBufferAmount')
            this.cd.markForCheck(); 
          //  this.form.get('bufferAmount')?.setValue(buffer.amount);
          }
          if (paymentMethod) {
           // console.log('this.paymentMethodsForDropdown', this.paymentMethodsForDropdown);
            const matchingMethod = this.paymentMethodsForDropdown.find(pm => pm.upperCase === paymentMethod);
            if (matchingMethod) {
              this.selectedPaymentMethod = matchingMethod;
              this.form.get('paymentMethod')?.setValue(matchingMethod.upperCase);
            } else {
              console.warn('No matching payment method found for:', paymentMethod);
            }
          }
          this.originalValues = {
            daySelection: response.autoTopup.dayOfWeek,
            bufferAmount: this.currencyPipe.transform(response.autoTopup.buffer.amount, 'USD', 'symbol', '1.2-2'),
            paymentMethod: response.autoTopup.paymentMethod
          };
          this.shouldCreateAutoTopup = !(
            this.originalValues.daySelection &&
            this.originalValues.bufferAmount &&
            this.originalValues.paymentMethod
          );
          this.form.patchValue({
            daySelection: response.autoTopup.dayOfWeek,
            bufferAmount: this.currencyPipe.transform(response.autoTopup.buffer.amount, 'USD', 'symbol', '1.2-2'),
            paymentMethod: response.autoTopup.paymentMethod
          });
          this.cd.detectChanges();
        }
      },
      (error) => {
        if (error.status === 404) {
          console.error('Auto Top-Up details not found (404)', error);
          this.showButtons = false;
          this.shouldCreateAutoTopup = true;
        } else {
          console.error('Error fetching Auto Top-Up details:', error);
        }
      }
    );
  }

  checkForChanges(): void {
    const currentValues = this.form.value;
    const isChanged =
      currentValues.daySelection !== this.originalValues.daySelection ||
      currentValues.bufferAmount !== this.originalValues.bufferAmount ||
      currentValues.paymentMethod !== this.originalValues.paymentMethod;

    this.isSaveChangesVisible = isChanged;
    this.cd.detectChanges();
  }

  
  getTokenAndLibraryPlaidCreateBank(): void {
    this.isLoading = true;
    // const competitionId = this.route.snapshot.queryParams['competitionId'];
    // this.plaidS.getTokenAndLibraryPlaidCreateBank(competitionId);
    try {
      const competitionId = this.route.snapshot.queryParams['competitionId'];
      this.plaidS.getTokenAndLibraryPlaidCreateBank(competitionId);
      setTimeout(() => {
        this.isLoading = false; 
      }, 2000); 
    } catch (error) {
      console.error('Error during Plaid process:', error);
      this.isLoading = false;
    }
  }
  getCompetitionId(): string {
    return this.route.snapshot.queryParams['competitionId'] || '';
  }

  handleCancel(): void {
    this.showStripeForm = false;
    this.selectedPaymentMethod = undefined;
    this.form.get('paymentMethod')?.setValue(null); 
    this.paymentMethodsForDropdown = [...this.paymentMethodsForDropdown]; 
    this.form.reset();
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  cancelTopUp(): void {
    const competitionId = this.route.snapshot.queryParams['competitionId'];
  
    // Ensure we have a valid competition ID before proceeding
    if (!competitionId) {
      console.error('Competition ID is required.');
      return;
    }
  
    this.deviceS.isMobile$.pipe(take(1)).subscribe(isMobile => {
    const dataPopup: IDataPopup = {
      width: '400px',
      textTitle: 'Are you sure you want to cancel your auto top-up?',
      text: 'By turning off this feature, automatic top-ups will be disabled and your balance may run out, potentially causing interruptions in paying your officials. You can always re-enable top-ups later. Do you want to proceed?',
      textBtnCancel: 'Go Back',
      textBtnApply: 'Cancel Top-Up',
      colorBtnApply: 'red',
      swapBtn: isMobile,
      marginTopForBtn: 24,
    };
  
    // Open the confirmation popup
    this.popupS.open(PopupConfirmComponent, dataPopup).then((res: any) => {
      if (!res) return; // If the user cancels, exit

      // Build the payload for the update
      const bufferValue = this.form.get('bufferAmount')?.value;
      const dayOfWeek = this.form.get('daySelection')?.value?.toUpperCase() || '';
      const paymentMethod = this.form.get('paymentMethod')?.value || '';
      const bufferAmount = parseFloat(
        typeof bufferValue === 'string' ? bufferValue.replace(/[^0-9.-]+/g, '') : bufferValue
      );
  
      const payload = {
        competitionId: competitionId,
        dayOfWeek: dayOfWeek,
        paymentMethod: paymentMethod,
        buffer: {
          amount: bufferAmount,
          currency: 'USD'
        },
        enabled: false 
      };
      this.isLoading = true;
      this.mainS.autoTopupUpdate(competitionId, payload).pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            this.getAutoTopupDetails();
            this.isLoading = false;
            this.cd.detectChanges();
          },
          (error) => {
            console.error('Error canceling Top-Up:', error);
            this.isLoading = false;
          }
        );
    });
  });
  }
  
  
  get placeholderText(): string {
    return this.activeTabMobile.titleCase || 'Select View';
  }
  
  resetActiveTabMobile(): void {
    this.setActiveTabMobile(this.tabOptions[0]); 
  }
  
}


