import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MeService } from '@services/me.service';
import { catchError, map, tap } from 'rxjs/operators';
import { AdjustmentStatusesService } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustment-statuses.service';

@Injectable({ providedIn: 'root' })
export class AdjustmentStatusesResolver  {

  constructor(
    private adjustmentStatusesS: AdjustmentStatusesService,
    private meS: MeService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.meS.meId) {
      // !!! NO DELETE. это для страниц авторизации. Тоесть если не залогинен, то не надо this.getAdjustmentStatuses(), потому что вернется 401 ошибка
      return of(true);
    }
    return this.getAdjustmentStatuses();
  }

  getAdjustmentStatuses(): Observable<boolean> {
    return this.adjustmentStatusesS.methodAdjustmentStatuses('get', false)
      .pipe(
        // tap(res => console.log('AdjustmentStatusesResolver :', res)),
        map(res => true),
        catchError(() => of(false)),
      );
  }
}
