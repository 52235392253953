<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: gameS.arrCurrentLinkDrop,
              objCurrentLink: (gameS.currentLink$|async)!,
              currentLink: (gameS.currentLink$|async)?.currentLink!,
              loadingTable: (meTableS.loadingTable$|async),
            } as obj' class='wrapAssignOfficials'
     [class.wrapAssignOfficialsForMobile]='assignS.showTableOfficialsForMobile'>

  <div class='headerAssignOfficials'>
    <meSvg *ngIf='obj.mobile' svgName='arrow_square_left&32' [w]='24' (click)='assignS.showTableOfficialsForMobile=false'></meSvg>
    Official Availability
    <app-containerTooltip position='bottom' [width]='216'>
      <meSvg svgName='question_grey&16' [w]='16' class='ngContent_trigger'></meSvg>
      <tooltipDefault [contentInnerHTML]='officialAvailabilityText' class='ngContent_tooltip'></tooltipDefault>
    </app-containerTooltip>
  </div>

  <div class='searchAssignOfficials'>
    <inputCtrl [(ngModel)]='search2' placeholder='Search by Name' svgPrefix='search_grey&20'></inputCtrl>
  </div>

  <div cdkDropList #cdkDropListRef='cdkDropList' [cdkDropListData]='assignS.officials' cdkDropListSortingDisabled cdkScrollable
       class='cdkDropListAssignOfficials'>
    <ng-container *ngIf='obj.desktop ? assignS.officials.length : (assignS.officials.length && !(assignS.loadingOfficialsSub$|async))'>
      <div *ngFor='let userItem of assignS.officials; let first=first; let idx=index; let last=last'
           class='userAssignOfficials' cdkDrag [cdkDragData]='userItem' [cdkDragDisabled]='obj.mobile'
           appDirectiveForAssignGameOfficial #appDirectiveForAssignGameOfficial='appDirectiveForAssignGameOfficial'>
        <ng-container *ngIf='{ NOT_SET: userItem.availabilityByGame?.availabilityStatus === "NOT_SET",
                               AVAILABLE: userItem.availabilityByGame?.availabilityStatus === "AVAILABLE",
                               UNAVAILABLE: userItem.availabilityByGame?.availabilityStatus === "UNAVAILABLE",
                               ASSIGNED_TO_ANOTHER_GAME: userItem.availabilityByGame?.availabilityStatus === "ASSIGNED_TO_ANOTHER_GAME",
                               svgObj: (assignS.selectedGame!|getSvgOfficialAvailability:userItem),
                               age: (userItem.dateOfBirth!|getAgeByDateOfBirth),
                               miles: (userItem?.availabilityByGame?.distance|roundMiles),
                             } as objUserItem'>

          <div class='userAssignOfficials__name'>
            <div style="display: flex; flex-direction: row;">
              <div (touchend)='appDirectiveForAssignGameOfficial.selectOfficial_forMobile(userItem)'> {{ userItem|getUserName }} </div>
              <meSvg *ngIf="userItem.certified" style="margin-left: 5px; align-content: center" svgName='USSF-Primary' [h]='24' [w]='24'
                     notchToolTipClassName='official-availability'
                     [notchTooltip]="generateTooltip(userItem)">
              </meSvg>
            </div>
            <span *ngIf='assignS.showTableOfficialsForMobile && (userItem|isUserAssignedInSelectedGameAndSavedInServer)'>
              Currently assigned
            </span>
          </div>

          <div class='userAssignOfficials__svgAvailability'>
            <meSvg *ngIf='objUserItem.svgObj' [svgName]='objUserItem.svgObj?.svgName!' [h]='16' [w]='16'
                   notchToolTipClassName='official-availability' [notchTooltip]='objUserItem.svgObj?.tooltip!'
                   #queryListTooltip='notchTooltip'></meSvg>
          </div>

          <div class='userAssignOfficials__age'>{{ userItem.age || '-' }}</div>
          <div class='userAssignOfficials__miles'>{{ objUserItem.miles ? objUserItem.miles + " miles" : "-" }}</div>

          <app-containerTooltip position='beforeBottomLeft' [width]='156' class='userAssignOfficials__forTooltipPhone'>
            <meSvg svgName='vector-phone' [w]='16' class='ngContent_trigger'></meSvg>
            <tooltipPhone [user]='userItem' class='ngContent_tooltip'></tooltipPhone>
          </app-containerTooltip>

        </ng-container>
      </div>
    </ng-container>

    <div *ngIf='!assignS.officials.length && !(assignS.loadingOfficialsSub$|async)' class='textEmptyListAssignOfficials'>
      It appears you do not have any officials available.
    </div>

    <spinner *ngIf='(assignS.loadingOfficialsSub$|async)'></spinner>

    <div *ngIf='assignS.officials.length && !(assignS.loadingOfficialsSub$|async)' class='btnShowMoreAssignOfficials'
         [class.o-disabledClickAndOpacity]='assignS.loadingOfficials'
         (click)='add_getListOfficials()'>
      <div *ngIf='!(assignS.dataOfficials|isLastPage)' class='btnShowMoreAssignOfficials__text1'>Show more
        <meSvg svgName='arrow_chevronBottom&20'></meSvg>
      </div>
      <div class='btnShowMoreAssignOfficials__text2'>
        Viewing {{ assignS.officials?.length }} of {{ assignS.dataOfficials?.totalElements }} officials
      </div>
    </div>
  </div>

</div>