<div class='wrapperHeadTitle wrapperHeadTitle--{{type}}' [class.wrapperHeadTitle--center]='textCenter'>
       <ng-content select='.ngContentLeft'></ng-content>

       <meSvg *ngIf='svgLeft' [svgName]='svgLeft' (click)='svgLeftEmit.emit()' class='wrapperHeadTitle__arrow'
              [matTooltip]='titleArrow' [w]='svgLeftWidth'></meSvg>


       <div class='wrapperHeadTitle__text o-{{typeText}} {{isPopup?"isPopup":""}}'>{{ text }}</div>

       <ng-content></ng-content>

       <meSvg *ngIf='svgRight' [svgName]='svgRight' (click)='svgRightEmit.emit()'
              class='wrapperHeadTitle__svgRight {{svgRightMargin!=="right" ? "wrapperHeadTitle__svgRight--marginR_"+svgRightMargin : ""}}'
              [class.wrapperHeadTitle__svgRight--cross]='isCross'
              [matTooltip]='titleCross' [w]='svgRightWidth'></meSvg>

       <ng-content select='.ngContentRight'></ng-content>
</div>