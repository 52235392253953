<div *ngIf='{card: payMethod?.cardDto,
             bank: payMethod?.bankAccountDto,
             nameBank: payMethod?.cardDto ? payMethod?.cardDto?.brand : payMethod?.bankAccountDto?.bankName,
             } as obj' class='wrapperPayMethod {{isBorder ? "wrapperPayMethod--border":""}}'
     [class.wrapperPayMethod--isSelect]='payMethod.isSelect'
     (click)='clickEmitPayMethod()'>
  <div class='wrapperPayMethod__forHover {{isHover ? "wrapperPayMethod__forHover--true":""}}'>
    <div class='wrapperPayMethod__content'>
<!--      [width]='46' [height]='32'-->
      <logoPaySystem *ngIf='payMethod' [payMethod]='payMethod'></logoPaySystem>

      <div class='infoPayMethod'>
        <div class='infoPayMethod__title'>{{obj.nameBank}} {{payMethod?.last4 ? ' ending in ' + payMethod?.last4 : ''}}</div>
        <div *ngIf='obj.card' class='infoPayMethod__expiry'>
          expiry {{obj.card?.expiryDate?.slice(0, 2)}}/{{obj.card?.expiryDate?.slice(2)?.trim()}}
        </div>
      </div>
    </div>

    <chx *ngIf='isChx' [ngModel]="!!payMethod.isSelect" (ngModelChange)="payMethod.isSelect = $event" [chxWidth]='chxWidth' (click)='clickEmitPayMethod()'></chx>
    <meSvg *ngIf='isEnlarge' svgName='arrow_chevronBottom&20' [revV]='revV'></meSvg>
  </div>
</div>
