<!--!!! FOR TEST ===================-->
<!--<div class='testMatMenu' [matMenuTriggerFor]='menu'></div>-->
<!--<mat-menu #menu='matMenu'>-->
<!--  <div (click)='dashboardS.openPopupCreateGroup()' class='o-cur-pointer o-mg-t-5'>CreateGroup</div>-->
<!--  <div (click)='meS.setMeUser({roleCurrent: "OFFICIAL"})' class='o-cur-pointer o-mg-t-5'>Switch to official</div>-->
<!--</mat-menu>-->

<!--  === ONBOARDING STEPS ================================================ -->
<app-stepsOnboarding *ngIf='!(dashboardS.onboardingSkipped$|async); else allStepsCompleted_forDashboard_template'></app-stepsOnboarding>

<!--<div class='o-mg-t-32 o-mg-b-24'>dashboardS.onboardingSkipped$=>{{(dashboardS.onboardingSkipped$|async)}}</div>-->

<!--  === DASHBOARD =============================-->
<ng-template #allStepsCompleted_forDashboard_template>
  <!--  <div class='o-mg-t-32 o-mg-b-24'>meS.meRole=>{{meS.meRole}}</div>-->
  <app-dashboardOfficial *ngIf='meS.OFFICIAL'></app-dashboardOfficial>
  <app-dashboardGroupAssignor *ngIf='meS.GROUP_ASSIGNOR'></app-dashboardGroupAssignor>
  <app-dashboardGroupAssignor *ngIf='meS.SUB_ASSIGNOR'></app-dashboardGroupAssignor>
  <app-dashboardAdmin *ngIf='meS.ADMIN'></app-dashboardAdmin>
</ng-template>