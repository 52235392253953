import { Injectable } from '@angular/core';
import { UtilsService } from '@services/utils.service';
import * as _ from 'lodash';
import { curryN } from 'lodash/fp';

export type TypeStringCase = 'camel' | 'capitalize' | 'kebab' | 'lower' | 'snake' | 'escape' | 'unescape' | 'trim' | 'truncate'
export const arrTypeStringCaseNoAddCase: Array<TypeStringCase> = ['capitalize', 'escape', 'unescape', 'trim', 'truncate']; // для этих методов не надо добавлять слово 'Case'

@Injectable({ providedIn: 'root' })
export class LodashService {
  // _ = require('lodash/core'); // Load the core build.
  fp = _;      // FP build methods
  array = _; // Array methods
  object = _; // Object methods
  at = _;      // 'at' method
  curryN = curryN; // curryN function

  constructor() {
  }

  // getStrCase(str: string, type: TypeStringCase): string {
  // const tempType = arrTypeStringCaseNoAddCase.includes(type) ? type : type + 'Case';
  // if (type == 'capitalize') {
  //   // return this._.startCase(this._.toLower(str)) // remove спец символы
  //   return str.replace(/\w+/g, this._.capitalize);
  // }
  // return this._[tempType](str);
  // return UtilsService.getStrCase(str, type);
  // }

  // === STRING ===
  // _.camelCase('__FOO_BAR__') => 'fooBar'
  // _.capitalize('FRED') => 'Fred'
  // _.kebabCase('Foo Bar') => 'foo-bar'
  // _.lowerCase('--Foo-Bar--') => 'foo bar' // _.toLower() // _.toUpper() // _.upperCase()
  // _.snakeCase('--FOO-BAR--') => 'foo_bar'
  // _.escape('fred, & pebbles') => 'fred, &amp; pebbles' // Преобразует символы "&", "<", ">", '"' и "'" в строке в соответствующие им объекты HTML
  // _.unescape() противоположность _.escape()
  // _.trim('-_-abc-_-', '_-') => 'abc'
  // _.truncate() // добавляет в конце 3точки ...
  // _.words('fred, barney, & pebbles') => ['fred', 'barney', 'pebbles']

  // examples https://lodash.com/docs/4.17.15 =========
  // === ARRAY ===
  // _.isEqual({ 'a': 1 },{ 'a': 1 }) => true // _.eq(value, other) а этот проверяет именно ссылки
  // _.cloneDeep(); // глубокое клонирование , изменение ссылки на объект
  // _.compact(array) // ['0', 1, false, true, null, undefined, {}, [], ''] => ['0', 1, true, {}, []]
  // _.difference([ 0, 1, 5 ], [0, 1, 2, 3 ]) => [5] // только с примитивами работает
  // _.differenceBy([2.1, 1.2], [2.3, 3.4], Math.floor) => [1.2] || _.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x') => [{ 'x': 2 }] // не проверял
  // _.differenceWith([{ 'x': 1, 'y': 2 }, { 'x': 2, 'y': 1 }], [{ 'x': 1, 'y': 2 }], _.isEqual) => [{ 'x': 2, 'y': 1 }] // не проверял
  // _.drop(array, [n=1]) _.dropRight(array, [n=1]) // удаляет элементы с начала/конца массива. n-это количество элем // есть ещё по условию _.dropWhile _.dropRightWhile
  // _.fromPairs([['a', 1], ['b', 2]]) => { 'a': 1, 'b': 2 }
  // _.intersection([2, 1б {a: 1}, this.obj], [2, 3б {a: 1}, this.obj]); obj = {aaa: 111} => [2, this.obj] // получить массив одинаковых значений. Сравнение по ссылкам, если объект // intersectionBy intersectionWith есть ещё
  // _.pull(['a', 'b', 'c', 'a', 'b', 'c'], 'a', 'c') => ['b', 'b'] // pullAll ['a', 'c'] // pullAllBy pullAllWith pullAt
  // _.remove(array, [predicate=_.identity]) // как arr.filter() только этот изменяет исходный массив
  // _.take([1, 2, 3], 2) // получить количество элем из массива
  // _.union([2], [1, 2]) => [2, 1] // массив уникальных значений . Есть ещё такой _.xor
  // _.zip(['a', 'b'], [1, 2], [true, false]) => [['a', 1, true], ['b', 2, false]] // группирует
  // _.unzip([['a', 1, true], ['b', 2, false]]) => [['a', 'b'], [1, 2], [true, false]] // наоборот
  // _.without([2, 1, 2, 3], 1, 2) => [3] // исключить заданые значения
  // _.zipObject(['a', 'b'], [1, 2]) => { 'a': 1, 'b': 2 }
  // _.zipObjectDeep(['a.b[0].c', 'a.b[1].d'], [1, 2]) => { 'a': { 'b': [{ 'c': 1 }, { 'd': 2 }] } }
  // _.zipWith([1, 2], [10, 20], [100, 200], (a, b, c) => a + b + c) => [111, 222]
  // _.isArrayLike(value) // проверяет есть ли свойство value.length

  // === COLLECTIONS ===
  // _.groupBy([6.1, 4.2, 6.3], Math.floor) => { '4': [4.2], '6': [6.1, 6.3] }
  // _.includes() работает для строк, массивов, объектов
  // _.invokeMap([[5, 1, 7], [3, 2, 1]], 'sort') => [[1, 5, 7], [1, 2, 3]]
  // _.partition(users, function(o) { return o.active; }) => objects for [['fred'], ['barney', 'pebbles']]
  // _.size({ 'a': 1, 'b': 2 }) => 2
  // _.some([null, 0, 'yes', false], Boolean); => true // хотя бы для одного ...

  // === FUNCTION ===
  // var initialize = _.once(createApplication) => initialize(); initialize(); => `createApplication` is invoked once

  // === OBJECT ===
  // _.conformsTo({ 'a': 1, 'b': 2 }, { 'b': function(n) { return n > 1; } }) => true
  // _.isMatch({ 'a': 1, 'b': 2 }, { 'b': 2 })  => true
  // _.toArray({ 'a': 1, 'b': 2 }) => [1, 2] // _.values(new Foo)
  // _.defaults({ 'a': 1 }, { 'b': 2 }, { 'a': 3 }) => { 'a': 1, 'b': 2 }
  // _.defaultsDeep({ 'a': { 'b': 2 } }, { 'a': { 'b': 1, 'c': 3 } }) => { 'a': { 'b': 2, 'c': 3 } }
  // _.toPairs(object) => [['a', 1], ['b', 2]]
  // _.unset({ 'a': [{ 'b': { 'c': 7 } }] }, 'a[0].b.c') => { 'a': [{ 'b': {} }] }

  // === DATE ===
  // _.isDate(new Date) => true //  _.isDate('Mon April 23 2012') => false

  // _.template() create html elem
  // _.isElement(document.body); => true
  // _.isError(new Error); => true
  // _.isInteger(3) => true
  // _.toInteger('3.2') => 3
}