import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-safari-bottom',
  imports: [],
  templateUrl: './safari-bottom.component.html',
  styleUrl: './safari-bottom.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SafariBottomComponent {
  readonly isSafari
  constructor() {
    // this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.isSafari = true;
  }
}
