import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { FormsModule } from '@angular/forms';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { PopupService } from '@services/popup.service';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnComponent } from '@components/btn/btn.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { MatMenuModule } from '@angular/material/menu';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { ClassCompetitionUser } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { StatusComponent } from '@components/status/status.component';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { StrCasePipe } from '@pipes/string/str-case.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { urlCompetitions } from '@app/app.module';
import { DeviceService } from '@services/device.service';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { Router } from '@angular/router';
import { rout_profileUser } from '@app/profile/profile';
import { finalize, from, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { CheckExistUsersPipe } from '@app/dir_group_assignor/competitions/components/competitions-users/pipes/check-exist-users.pipe';
import { MainService } from '@services/main.service';
import { ClassUser } from '@models/user';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';

interface ITableHeader {
  text?: string;
  chx?: boolean;
  isActive?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'competitionsListUsers',
  standalone: true,
  imports: [CommonModule, SvgAndTextComponent, InputCtrlComponent, FormsModule, BtnComponent, SvgComponent, MatMenuModule, GetWidthMatMenuPipe, StatusComponent, PhotoComponent, StrCasePipe, SpinnerComponent, NothingListComponent, CheckExistUsersPipe, GetTableEmptyPipe],
  templateUrl: './competitions-list-users.component.html',
  styleUrls: ['./competitions-list-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionsListUsersComponent extends HelperClass {

  @Input() loadingUsers = true;

  searchValue = '';
  tableHeader: Array<ITableHeader> = [
    { text: 'User Name', chx: false, isActive: false },
    { text: 'Role', chx: false, isActive: false },
    { text: 'Status', chx: false, isActive: false },
    { text: 'Date Added', chx: false, isActive: false },
    { text: '', chx: false, isActive: false },
  ];

  readonly rout_profileUser = rout_profileUser;
  readonly urlCompetitions = urlCompetitions;

  @Output() newUser = new EventEmitter();
  @Output() searchEmit = new EventEmitter<string>();

  constructor(
    public deviceS: DeviceService,
    public meS: MeService,
    public competitionS: CompetitionService,
    public popupS: PopupService,
    public router: Router,
    private mainS: MainService,
    private otherS: OtherService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  // === OTHER ACTIONS ============================
  resendInviteToCompetition(competitionUser: ClassCompetitionUser): void {
    if (this.startRequest()) return;
    this.competitionS.resendInviteToCompetition(competitionUser)
      .pipe(
        finalize(() => this.endRequest()),
        untilDestroyed(this),
      )
      .subscribe((res) => {
      });
  }

  deleteItem(user: ClassCompetitionUser): void {
    if (this.startRequest()) return;

    const observable = from(this.popupS.openPopupConfirm({
      textTitle: `Remove user from competition`,
      text: 'Are you sure you want to delete this user from competition?',
      textBtnApply: 'Remove',
    }));

    observable.pipe(
      switchMap((res) => {
        if (res) return this.competitionS.deleteItem(user?.id!, 'users');
        this.endRequest();
        return of(null);
      }),
      finalize(() => this.endRequest()),
      catchError((err) => {
        this.endRequest();
        return of(err);
      }),
      untilDestroyed(this),
    )
      .subscribe((res) => {
        this.cd.detectChanges();
      });
  }

  // === OTHER ACTIONS ========================================
  goToProfileUser(user: ClassUser): void { // ClassCompetitionUser
    this.router.navigate([rout_profileUser + '/contactInfo', user?.userId], { queryParams: { userRole: user.role } });
  }

}
