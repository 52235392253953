<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
             } as obj' class='wrapperCompetitionsBlock wrapperCompetitionsListUsers'>
  <div class='headerSetupCompetition'>
    <svgAndText text='Users' typeText='text1-blueDark'></svgAndText>
  </div>

  <div class='wrapperCompetitionsListUsers__headerActions'>
    <!--    (enterAndFocusOutEmit)='searchEmit.emit($event)'-->
    <inputCtrl [(ngModel)]='searchValue' placeholder='Search' svgPrefix='search_grey&20' w='270'
               (changeVal)='searchEmit.emit($event)'></inputCtrl>
    <!--    <btn text='New User +' bcg='newGreen' (emit)='newUser.emit()'></btn>-->
    <btn *ngIf="meS.GROUP_ASSIGNOR" text='Invite User' bcg='blueDark' (emit)='newUser.emit()'></btn>
  </div>

  <div class='tableCompetitionsListUsers'>
    <div class='tableCompetitionsListUsers__header'>
      <div *ngFor='let itemHeader of tableHeader; let idx = index;' class='tableCompetitionsListUsers__header__item widthItem--{{idx+1}}'>
        <meSvg *ngIf='itemHeader.chx' [svgName]='"chx_false&20"' class='tableCompetitionsListUsers__header__item__chx'></meSvg>
        {{ itemHeader.text }}
        <meSvg *ngIf='itemHeader.isActive' [svgName]='"arrow_top&16"' class='tableCompetitionsListUsers__header__item__arrow'></meSvg>
      </div>
    </div>

    <div class='tableCompetitionsListUsers__list'>
      <ng-container *ngIf='""|checkExistUsers; else nothingList'>
        <div *ngFor='let user of (competitionS.competition$|async)?.users; let first = first; let idx = index; let last = last'
             class='tableCompetitionsListUsers__list__item'>
          <div class='widthItem--1 tableCompetitionsListUsers__list__item__photoName'>
            <photo [data]='user' class='mePhoto'></photo>
            <div><span>{{ user.name }}</span><span>{{ user.email }}</span></div>
          </div>
          <div class='widthItem--2'>{{ (user.role!|strCase:"capitalize": true) || '' }}</div>
          <div class='widthItem--3'>
            <status [status]='user.status'></status>
          </div>
          <div class='widthItem--4'>{{ user.dateAdded|date:'dd MMM YYYY' }}</div>
          <meSvg  *ngIf="meS.GROUP_ASSIGNOR"  svgName='circle_3points2&20' class='widthItem--5' [matMenuTriggerFor]='menuForUser'></meSvg>
          <mat-menu #menuForUser='matMenu'>
            <button *ngIf='user.status === "ACTIVE"' (click)='goToProfileUser(user)'>View profile</button>
            <button *ngIf='user.status === "PENDING"' (click)='resendInviteToCompetition(user)' [disabled]='reqPending'>
              Resend Invite
            </button>
            <!--            <button (click)='null'>Edit role</button>-->
            <button (click)='deleteItem(user)' [disabled]='reqPending'>Remove</button>
          </mat-menu>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #nothingList>
  <!--  <nothingList typePage='competitions/users' headerPage='No teammates yet'-->
  <!--               descriptionPage='You’ve not added any administrators to this competition yet.<br/> Once you do, they will be displayed on this page.'></nothingList>-->
  <nothingList [tableEmpty]='search|getTableEmpty:"competitionsListUsers"'></nothingList>
</ng-template>
