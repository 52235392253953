import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, EventEmitter, Input, Output, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass, TForPagination } from '@classes/Helper-Classes';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { DropDateRangeComponent } from '@components/__drop_inputs_matSelect/drop-date-range/drop-date-range.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { MatMenuModule } from '@angular/material/menu';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { StatusComponent } from '@components/status/status.component';
import { Router } from '@angular/router';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { BehaviorSubject, debounceTime, forkJoin } from 'rxjs';
import { OtherService } from '@services/other.service';
import { RoundBalancePipe } from '@app/dir_group_assignor/payments/pipes/round-balance.pipe';
import { BlockChartComponent } from '@app/dir_group_assignor/payments/additional-components/block-chart/block-chart.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { FiltersService } from '@components/filters/filters.service';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput, IForClassForFilters, TypeFilter_string } from '@components/filters/filters';
import { DeviceService } from '@services/device.service';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { IBalanceForHtml, IBalanceOperation, IPaymentMethod, TLinkPaymentsItem, TStatusPayment } from '@app/dir_group_assignor/payments/modelsForPayment';
import { ClassCeilTableHeader, IForTable, TypeEmitSettingRequest } from '@components/_table/meTable';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { ISortBy } from '@components/sortBy/sortBy';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { format } from 'date-fns';
import { TooltipDefaultComponent } from '@components/__tooltip/tooltip-default/tooltip-default.component';
import { ContainerTooltipComponent } from '@components/__tooltip/container-tooltip/container-tooltip.component';
import { arrPaymentStatusLink } from '@classes/dictionary';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { PaymentBalancesMobileComponent } from './payment-balances-mobile/payment-balances-mobile.component';
import { ComingSoonMobile } from '@app/dir_group_assignor/dashboard/components/coming-soon-mobile/coming-soon-mobile.component';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { PopupConfirmPaymentComponent } from '@components/__popup-windows/popup-confirm-payment/popup-confirm-payment.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MainService } from '@services/main.service';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { MeService } from '@services/me.service';

const tableHeaderPaymentsBalances: Array<ClassCeilTableHeader & { hoverText?: string }> = [
  { text: 'Type' },
  { text: 'Net Amount' },
  { text: 'Fees' , hoverText: 'Fees include Notch Pay transaction fees and, where it applies, card processing fees.' },
  { text: 'Reference' },
  { text: 'Status' },
  { text: 'Date Initiated' },
  // { text: '' },
];

@UntilDestroy()
@Component({
  selector: 'app-payments-balances',
  standalone: true,
  imports: [CommonModule, DropDateRangeComponent, DropFormCtrlComponent, FormsModule, SvgAndTextComponent, SvgComponent, BtnComponent, PaymentBalancesMobileComponent,
    MatMenuModule, NothingListComponent, SpinnerComponent, StatusComponent, HeadTitleComponent, GetStylesPipe, RoundBalancePipe, ReactiveFormsModule, BlockChartComponent, DropdownComponent, FiltersComponent, FiltersSelectedComponent, FiltersSelectedIconComponent, LinkPageComponent, WrapPageDirective, GetTableEmptyPipe, PaginationComponent, TooltipDefaultComponent, ContainerTooltipComponent, InputCtrlComponent, PaginationWithSettingsComponent, TableEmptyComponent, TableInfiniteLoadingComponent, TableHeaderComponent, ComingSoonMobile, LinkPageSearchFilterComponent],
  templateUrl: './payments-balances.component.html',
  styleUrls: ['./payments-balances.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
})
export class PaymentsBalancesComponent extends HelperClass implements IForClassForFilters, OnInit, AfterViewInit {
  @ViewChild('spinner', { static: false }) spinner!: ElementRef;
  @ViewChild('contentContainer', { static: false }) contentContainer!: ElementRef;
  @ViewChild('sentinel', { static: false }) sentinel!: ElementRef;
  @Input() selectedCompetition: string = 'Competition';
  @Output() emit = new EventEmitter<ClassSettingsRequest>();
  @Output() updatePage = new EventEmitter<number>();
  isPagination: boolean = false;

  currentBalance?: IBalanceForHtml;
  payoutsDueBalance?: IBalanceForHtml;

  autoTopupStatus: 'On' | 'Off' = 'Off';
  officialAvailabilityText = 'Coming Soon <img src="path/to/lock-icon.png" alt="lock" width="16" height="16">';
  selectedStatusLink: TStatusPayment | '' = '';
  arrPaymentStatusLink = arrPaymentStatusLink;
  private allowedUserIds = ['4262652e-cd47-4a7d-94c8-915495e09c5e', 'e18c1d49-f87e-48f2-819f-5db90d1569e9' , '4f2539dd-ddff-44ee-a1e2-8f347627bfcb' , '20fb6b63-2d27-4157-8fb4-5af5c3c4d950', 'af4fabcb-68a3-4028-bd79-838163a49f75'];

  isAllowedUser(): boolean {
    return this.meS.me?.id ? this.allowedUserIds.includes(this.meS.me.id) : false;
  }

  tiles = [
    { title: 'Notch Pay Balance' },
    { title: 'Pending Inflows' },
    { title: 'Pending Outflows' }
  ];
  selectedTile = 0;
  currentPage: number | undefined;
  totalPages: number | undefined;
  selectTile(index: number) {
    this.selectedTile = index;
  }

  // projectedOutgoingsPeriods: { label: string, from: string, to: string }[] = [
  //   { label: 'Today', from: format(new Date(), "yyyy-MM-dd'T'00:00:00"), to: format(new Date(), "yyyy-MM-dd'T'23:59:59") },
  //   { label: 'Next 7 days', from: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"), to: format(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), "yyyy-MM-dd'T'HH:mm:ss") },
  //   { label: 'Next 14 days', from: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"), to: format(new Date(Date.now() + 14 * 24 * 60 * 60 * 1000), "yyyy-MM-dd'T'HH:mm:ss") },
  //   { label: 'Next 30 days', from: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"), to: format(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), "yyyy-MM-dd'T'HH:mm:ss") },
  // ];


  get projectedOutgoingsPeriods() {
    const now = new Date();
    return [
      { label: 'Today', from: format(now, "yyyy-MM-dd'T'00:00:00"), to: format(now, "yyyy-MM-dd'T'23:59:59") },
      { label: 'Next 7 days', from: format(now, "yyyy-MM-dd'T'HH:mm:ss"), to: format(new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000), "yyyy-MM-dd'T'HH:mm:ss") },
      { label: 'Next 14 days', from: format(now, "yyyy-MM-dd'T'HH:mm:ss"), to: format(new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000), "yyyy-MM-dd'T'HH:mm:ss") },
      { label: 'Next 30 days', from: format(now, "yyyy-MM-dd'T'HH:mm:ss"), to: format(new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000), "yyyy-MM-dd'T'HH:mm:ss") },
    ];
  }
  currentProjectedOutgoingsPeriodIndex: number = 0;
  selectedStatus: TStatusPayment | '' = '';
  searchControl = new FormControl('');
  isLoading = new BehaviorSubject<boolean>(false);
  public searchFilter: ClassFilterInput;
  dataTable$ = new BehaviorSubject<IResponse<any>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<any>>([]);

  readonly currentLink: TLinkPaymentsItem = { titleCase: 'Balances', upperCase: 'balances' };
  readonly tableHeader = tableHeaderPaymentsBalances;

 currentMobileLink: ClassDrop = { titleCase: 'All Competitions', upperCase: 'all' };
 showAutoTopupSection: boolean = true;


  constructor(
    public payS: PaymentService,
    public router: Router,
    public otherS: OtherService,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public cd: ChangeDetectorRef,
    public meTableS: MeTableService<TStatusPayment>,
    public settingsRequestS: SettingsRequestService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    public mainS: MainService,
    public apiCompetitionService: ApiCompetitionService,
    public meS: MeService,
  ) {
    super(cd);
    this.searchFilter = this.filtersS.findFilter('search');
    this.setDataForTable();
    this.payS.projectedOutgoings$.next({ officialFees: 0, assignorFees: 0 });
    // console.log('this.tableHeader', this.tableHeader);
  }

  ngOnInit() {
    this.subscribeToArrCompetitions();
    this.setFilters(); // for-filters===
    this.updateBalances();
    this.subscribeToSearch();
    this.settingsRequestS.setAllData(this.isFirstLoadPageSub$);

    this.selectedStatusLink = arrPaymentStatusLink[0].upperCase;
    this.fetchAndUpdatePaymentStatusCounts();
    this.payS.arrCompetition$.pipe(untilDestroyed(this)).subscribe((res) => {
    });
  }


  ngAfterViewInit() {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        this.loadNextPage();
      }
    });
    observer.observe(this.sentinel.nativeElement);
  }



  loadNextPage(): void {
    if (this.isLoading.getValue() || this.deviceS.isDesktop) {
      return;
    }
    if (this.currentPage !== undefined && this.totalPages !== undefined && this.currentPage >= (this.totalPages - 1)) {
      return;
    }
    this.isLoading.next(true);
    this.settings.page = (this.settings.page || 0) + 1;
    this.getDataForHTML();
  }



  updateBalances(): void {
    this.payS.arrBalancesForHtml$.pipe(untilDestroyed(this)).subscribe((balances) => {
      // console.log('Balances:', balances);
      this.currentBalance = balances.find(balance => balance.title === 'current');
      this.payoutsDueBalance = balances.find(balance => balance.title === 'payoutsDue');
    //  this.cd.detectChanges(); --3
    });
  }

  getCurrentProjectedOutgoingsPeriod(): string {
    return this.projectedOutgoingsPeriods[this.currentProjectedOutgoingsPeriodIndex].label;
  }

  updateProjectedOutgoings(): void {
    this.isLoading.next(false);
    const currentPeriod = this.projectedOutgoingsPeriods[this.currentProjectedOutgoingsPeriodIndex];
    const dateRange = { from: currentPeriod.from, to: currentPeriod.to };

    // const settingsWithoutCompetition = { ...this.settings };
    const settingsWithoutCompetition = {
      ...this.settings,
      ...dateRange,
      competitions: this.settings.competitions
    };

    if( this.settings.competitions === 'all' ){
      delete settingsWithoutCompetition.competitions;
    }

    this.payS.getProjectedOutgoings({ ...settingsWithoutCompetition, ...dateRange }).pipe(untilDestroyed(this)).subscribe((projectedOutgoings) => {
      this.payS.projectedOutgoings$.next(projectedOutgoings);
      this.isLoading.next(false);
      this.cd.detectChanges();
    }, () => this.isLoading.next(false));
  }

  toggleProjectedOutgoingsPeriod(direction: 'left' | 'right'): void {
    if (direction === 'left') {
      this.currentProjectedOutgoingsPeriodIndex =
        (this.currentProjectedOutgoingsPeriodIndex - 1 + this.projectedOutgoingsPeriods.length) % this.projectedOutgoingsPeriods.length;
    } else {
      this.currentProjectedOutgoingsPeriodIndex =
        (this.currentProjectedOutgoingsPeriodIndex + 1) % this.projectedOutgoingsPeriods.length;
    }
    this.updateProjectedOutgoings();
    this.cd.detectChanges();
  }


  getDataForHTML(): void {
    this.isLoading.next(true);
    const currentPeriod = this.projectedOutgoingsPeriods[this.currentProjectedOutgoingsPeriodIndex];
    const dateRange = { from: currentPeriod.from, to: currentPeriod.to };
    const settingsWithoutCompetition = { ...this.settings };
    delete settingsWithoutCompetition.competitions;
    if (this.settings.competitions === 'all' || !this.settings?.competitions) {
      forkJoin([
        this.payS.getPayments(this.settings),
        this.payS.getBalanceWithOutCompetition(),
        // this.payS.getProjectedOutgoings({ ...this.settings, ...dateRange }),
        this.payS.getProjectedOutgoings({ ...settingsWithoutCompetition, ...dateRange }),
      ]).pipe(untilDestroyed(this)).subscribe(([payments, balance, projectedOutgoings]) => {
        this.payS.dataPayments$.next(payments);
        this.payS.balance$.next(balance);
        this.payS.projectedOutgoings$.next(projectedOutgoings);
        if (payments) this.setDataAfterResponseServer(payments);
        this.fetchAndUpdatePaymentStatusCounts();
        this.isLoading.next(false);
        this.cd.detectChanges();
      }, () => this.isLoading.next(false));
    } else {
      forkJoin([
        this.payS.getBalanceCompetition(this.settings.competitions!),
        this.payS.getPaymentMethodsCompetition(this.settings.competitions!),
        this.payS.getPaymentsCompetition(this.settings, this.settings.competitions!),
        this.payS.getProjectedOutgoings({ ...this.settings, ...dateRange }),
        this.apiCompetitionService.getCompetitionInfo(this.settings.competitions!) 
      ]).pipe(untilDestroyed(this)).subscribe(([balance, paymentMethods, payments, projectedOutgoings]) => {
        this.payS.balance$.next(balance);
        this.payS.paymentMethods$.next(paymentMethods);
        this.payS.dataPayments$.next(payments);
        this.payS.projectedOutgoings$.next(projectedOutgoings);
        this.fetchAndUpdatePaymentStatusCounts();
        this.autopopupenable(this.settings.competitions);
        this.showAutoTopupSection = this.apiCompetitionService.payoutFormat !== 'OFFLINE';
        this.isAllowedUser();
        if (payments) this.setDataAfterResponseServer(payments);
        this.isLoading.next(false);
        this.cd.detectChanges();
      }, () => this.isLoading.next(false));
    }
  }




  setDataAfterResponseServer(res?: IResponse<IForTable>, type?: TypeEmitSettingRequest | null): void {
    if (res) {
      this.totalPages = res.totalPages;
      this.currentPage = res?.pageable?.pageNumber;
    }
    this.dataTable$.next(res || {});
    const content = res?.content || [];
    if (this.deviceS.isDesktop) {
      this.arrContent$.next(content);
      this.resetSelectedItem();
    } else {
      const currentContent = this.arrContent$.getValue();
      this.arrContent$.next([...currentContent, ...content]);

      this.endLoading();
    }
   // console.log('arrContent after update:', this.arrContent$.getValue());
    this.isLoading.next(false);
  }





  getPaymentsOnly(): void {
    this.isLoading.next(true);
    this.settings.page = 1;
    const updatePagination = (payments: IResponse<IBalanceOperation>) => {
        this.totalPages = payments.totalPages;
        this.currentPage = payments?.pageable?.pageNumber;
    };
    if (!this.settings?.competitions || this.settings?.competitions === 'all') {
      // No competition selected, fetch all payments
      this.payS.getPayments(this.settings).pipe(untilDestroyed(this)).subscribe((payments) => {
        if (payments)  updatePagination(payments);
        this.payS.dataPayments$.next(payments);
        if (this.settings.page && this.settings.page > 1) {
          this.arrContent$.next([...(payments?.content || []), ...this.arrContent$.getValue()]);
        } else {
          this.arrContent$.next(payments?.content || []);
        }
        // this.arrContent$.next(payments?.content || []);
        this.fetchAndUpdatePaymentStatusCounts();
        if (payments && this.deviceS.isDesktop) this.setDataAfterResponseServer(payments);
        this.isLoading.next(false);
        this.cd.detectChanges();
      }, () => this.isLoading.next(false));
    } else {
      // Competition selected, fetch competition-specific payments
      this.payS.getPaymentsCompetition(this.settings, this.settings.competitions!).pipe(untilDestroyed(this)).subscribe((payments) => {
        if (payments)  updatePagination(payments);
        if (this.settings.page && this.settings.page > 1) {
          this.arrContent$.next([...(payments?.content || []), ...this.arrContent$.getValue()]);
        } else {
          this.arrContent$.next(payments?.content || []);
        }
        this.payS.dataPayments$.next(payments);
        // this.arrContent$.next(payments?.content || []);
        this.fetchAndUpdatePaymentStatusCounts();
        if (payments && this.deviceS.isDesktop) this.setDataAfterResponseServer(payments);
        this.isLoading.next(false);
        this.cd.detectChanges();
      }, () => this.isLoading.next(false));
    }
  }

  goToBalancesPay(): void {
    this.router.navigate(['payments/balances/pay'], { queryParams: { competitionId: this.settings.competitions } });
  }

  goToBalancesAutoTopup(): void {
    this.router.navigate(['payments/balances/autoTopup'], { queryParams: { competitionId: this.settings.competitions } });
  }

  // === FILTERS ==========================================
  settings: ClassSettingsRequest = new ClassSettingsRequest(); // for-filters===
  setFilters(): void {
    const competitionFilterDrop = this.payS.competitionFilterDrop$.getValue();
    const dateRange = new ClassFilterDateRange({ typePeriod: 'current', arrPeriods: this.payS.arrPeriods });
    // this.filtersS.setFilters([{ ...competitionFilterDrop, svgPrefix: 'text_viewBalanceAs' }, dateRange], false, false);
    this.filtersS.setFilters([{ ...competitionFilterDrop }], false, false);

  }

  emitSettingRequest(event: any): void {
    // If isPagination is true, return immediately and reset the flag
    if (this.isPagination) {
      this.isPagination = false;
      return;
    }

    this.settings = { ...this.settings, ...event };

    const competitionFilterDrop = this.payS.competitionFilterDrop$.getValue();
    const selectedCompetition = this.payS.arrCompetition$.getValue().find(comp => comp.id === this.settings.competitions);

    if (selectedCompetition) {
      competitionFilterDrop.valueDrop = {
        titleCase: selectedCompetition.competitionName,
        upperCase: selectedCompetition.id
      } as ClassDrop;
    } else {
      competitionFilterDrop.valueDrop = undefined;
    }

    this.payS.competitionFilterDrop$.next(competitionFilterDrop);
    this.getDataForHTML();
  }

  getCompetitionName(competitionId?: string): string {
    if (!competitionId) return 'Competition';
    const competitions = this.payS.arrCompetition$.getValue();
    const competition = competitions.find(comp => comp.id === competitionId);
    return competition?.competitionName || 'Competition';
  }

  subscribeToArrCompetitions(): void {
    this.payS.arrCompetition$.pipe(untilDestroyed(this)).subscribe((res) => {
      const competitionFilterDrop = this.payS.competitionFilterDrop$.getValue();
      const seasons = new ClassFilterDrop({ typeFilter: 'seasons', disabled: false });
      //  this.filtersS.updateAllFilters([{ ...competitionFilterDrop, svgPrefix: 'text_viewBalanceAs' }, seasons]);
      if (this.deviceS.isDesktop) {
        this.filtersS.updateAllFilters([{ ...competitionFilterDrop }, seasons]);
      }
      else {
        this.currentMobileLink = this.competitionsArray[0];
      }
    });
  }



  filterPayments(status: TStatusPayment | ''): void {
    if (status === ''  || !status) {
      delete this.settings.status;
    } else {
      this.settings.status = status;
    }
    this.getPaymentsOnly();
    this.cd.detectChanges();
  }

  subscribeToSearch() {
    this.searchControl.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((searchTerm) => {
      this.settings.search = searchTerm ?? undefined;
      this.getPaymentsOnly();
    });
  }

  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$;
    this.meTableS.arrContent$ = this.arrContent$;
    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Type' }),
      new ClassCeilTableHeader({ text: 'Net Amount' }),
      new ClassCeilTableHeader({ text: 'Reference' }),
      new ClassCeilTableHeader({ text: 'Status' }),
      new ClassCeilTableHeader({ text: 'Date Initiated' }),
      new ClassCeilTableHeader({ sortBy: true }),
    ];
   // const arrWidthCeilTable: Array<number> = [152, 220, 180, 380, 200, 291];
    const arrWidthCeilTable: Array<number> = [150, 150, 350, 350, 150, 150];
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, 300, true);
  }


  paginationMethod(event: any): void {
    const page = Number(event);
    if (this.settings.page !== page) {
      this.settings.page = page;
      this.isPagination = true;
      this.getDataForHTML();
    }
  }

  getSelectedTitleCase(): string {
    const selectedLink = arrPaymentStatusLink.find(link => link.upperCase === this.selectedStatusLink);
    return selectedLink ? selectedLink.titleCase : '';
  }



  updatePaymentStatusLink(data: any): Array<{ titleCase: string, upperCase: string, count?: any }> {
    const statusCounts = data.statusCounts || { SUCCEEDED: '', PENDING: '', INCOMPLETE: '', FAILED: '' };
    const succeededCount = statusCounts.SUCCEEDED ?? '';
    const pendingCount = statusCounts.PENDING ?? '';
    const incompleteCount = statusCounts.INCOMPLETE ?? '';
    const failedCount = statusCounts.FAILED ?? '';
    const totalElements = (succeededCount || 0) + (pendingCount || 0) + (incompleteCount || 0) + (failedCount || 0);
    const updatedLinks = arrPaymentStatusLink.map(link => {
        switch (link.upperCase) {
            case '':
                return { ...link, count: totalElements };
            case 'SUCCEEDED':
                return { ...link, count: succeededCount };
            case 'PENDING':
                return { ...link, count: pendingCount };
            case 'INCOMPLETE':
                return { ...link, count: incompleteCount };
            case 'FAILED':
                return { ...link, count: failedCount };
            default:
                return link;
        }
    });

    this.arrPaymentStatusLink = updatedLinks;
    this.cd.detectChanges();
    return updatedLinks;

  }

  fetchAndUpdatePaymentStatusCounts(): void {
    if (this.settings.competitions === 'all' || !this.settings?.competitions) {
      // Fetch and update payment status counts for all competitions
      this.payS.getPayments(this.settings).pipe(untilDestroyed(this)).subscribe((res) => {
        if (res) {
          const updatedLinks = this.updatePaymentStatusLink(res);
          this.cd.detectChanges();
        //  console.log('updatedLinks', updatedLinks);
        }
      });
    } else {
      // Fetch and update payment status counts for specific competition
      this.payS.getPaymentsCompetition(this.settings, this.settings.competitions).pipe(untilDestroyed(this)).subscribe((res) => {
        if (res) {
          const updatedLinks = this.updatePaymentStatusLink(res);
          this.cd.detectChanges();
        //  console.log('updatedLinks', updatedLinks);
        }
      });
    }
  }

    onCompetitionChange(selectedCompetition: ClassCompetition): void {
    this.settings.competitions = selectedCompetition.id;
    this.getPaymentsOnly();
    this.cd.detectChanges();
    this.payS.onCompetitionChange(selectedCompetition);
  }

  autopopupenable(competitionId: string | undefined): void {
    if(this.meS.SUB_ASSIGNOR){
      return;
    }
    if (competitionId) {
        
        this.mainS.getAutoTopupEnable(competitionId).pipe(untilDestroyed(this)).subscribe(
            (response: boolean) => {
                this.autoTopupStatus = response ? 'On' : 'Off';
                this.cd.detectChanges();
            },
            (error) => {
                console.error('Error fetching auto-topup status:', error);
                this.autoTopupStatus = 'Off';
            }
        );

        
        this.payS.onCompetitionChange({ id: competitionId });
    } else {
        console.warn('Competition ID is undefined. Please select a valid competition.');
    }
}


  currentPaymentStatus: { titleCase: string, upperCase: TStatusPayment | '' } = { titleCase: 'All', upperCase: '' };

  onPaymentStatusChange(selectedStatus: { titleCase: string, upperCase: TStatusPayment | '' }): void {
    console.log('Selected Status:', selectedStatus);
    this.selectedStatusLink = selectedStatus.upperCase;
    this.filterPayments(this.selectedStatusLink);
    this.currentPaymentStatus = selectedStatus;
    this.cd.detectChanges();
  }

  allMethodsSub: Array<IPaymentMethod> | null = null;
  isFundsEnabled: boolean = false;

  get competitionsArray(): ClassCompetition[] {
    return this.payS.arrCompetition$.getValue() || [];
  }

    openExistingConfirmationPopup(paymentId: string): void {
      const isMobile = this.deviceS.isMobile;
      const dialogRef = this.dialog.open(PopupConfirmPaymentComponent, {
        data: {
          textTitle: 'Confirm Payment',
          text: 'By clicking "Confirm" you authorize Notch to debit the bank account specified above for any amount owed for charges arising from your use of Notch Pay services to pay in and fund a competition balance, pursuant to the Notch terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to Notch with 30 (thirty) days notice.',
          showCloseButton: true,
          closeOnApprove: true,
        },
        width: isMobile ? '90vw' : 'auto',
        maxWidth: isMobile ? '90vw' : 'auto'
      });

      dialogRef.componentInstance.approve.subscribe(() => {
        this.confirmPaymentForCompetition(paymentId, dialogRef);
      });

      dialogRef.componentInstance.close.subscribe(() => {
        dialogRef.close();
      });
    }
    confirmPaymentForCompetition(paymentId: string, dialogRef: MatDialogRef<PopupConfirmPaymentComponent>): void {
      this.isLoading.next(true);
      this.mainS.confirmPaymentForCompetition(paymentId).toPromise()
        .then(() => {
          dialogRef.close();
          this.getPaymentsOnly();
        }) .finally(() => {
          this.isLoading.next(false);
          this.cd.detectChanges();
        });
    }
    handlePaymentStatusClick(itemPayment: any) {
      const paymentId = itemPayment.reference;
      this.openExistingConfirmationPopup(paymentId);
    }

}
