<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
                mobile: (deviceS.isMobile$|async),
                arrCurrentLink: gameS.arrCurrentLinkDrop,
                objCurrentLink: (gameS.currentLink$|async)!,
                currentLink: (gameS.currentLink$|async)?.currentLink!,
                dataTable: (dataTable$|async)!,
                arrContent: (arrContent$|async),
                loadingTable: (meTableS.loadingTable$|async),
                CURRENT: gameS.is_currentLink,
                PAST: gameS.is_pastLink,
               } as obj' class='o-wrapPage' wrapPage [widthPage]='widthPage' cdkScrollable dataTable>

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header'>
    <linkPage-searchFilter></linkPage-searchFilter>
    <div class='o-header__content'>
      <div class='o-header__title'>My Games</div>
      <div class='o-header__filters'>
        <app-filters></app-filters>
        <div class='o-header__actions'>
          <meSvg svgName='download_3&44' (click)='null' [disabled]='true'></meSvg>
        </div>
      </div>
      <app-filtersSelected></app-filtersSelected>
    </div>
  </div>

  <!--  === TABLE ==============================-->
  <div class='o-content t-wrapperTable'>
    <tableHeader></tableHeader>
    <div class='t-table'>
      <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
        <div *ngIf='{ gameCANCELLED: item?.gameStatus == "CANCELLED",
                      isPendingGoForOfficial: (item|getMeGoFromGame|isPendingGoForOfficial),
                      meGo: item|getMeGoFromGame,
                    } as objGame'
             class='t-table__item' itemTable [item]='item' [urlNavigate]='urlGameInfo'
             [class.o-table__list__item--colorRedOpacity]='objGame.gameCANCELLED'
             [class.o-table__list__item--colorBlueOpacity]='obj.desktop ? objGame.isPendingGoForOfficial : false'>

          <app-tableCeilChx *ngIf='obj.desktop' [item]='item' [text]='item.gameNumber' [path]='urlMyGames'></app-tableCeilChx>
          <!-- !!! this component app-tableBtnNumber ONLY FOR MOBILE for table -->
          <app-tableBtnNumber [game]='item' [bcg]='objGame.isPendingGoForOfficial ? "grey_3_5":null'></app-tableBtnNumber>
          <app-tableCeil [idxCeil]='1' [text1]='item.date_EE_MMMd_yyy' [text2]='item.date_shortTime_timezone'></app-tableCeil>
          <app-tableCeil [idxCeil]='2' [text1]='item.ageGenderLevel' [text2]='item.competition?.competitionName || "TBD"'></app-tableCeil>
          <app-tableCeil [idxCeil]='3' [text1]='item.locNameCourtName' [text2]='item.cityState'
                         [matTooltipText]='item.matTooltipForLocation'></app-tableCeil>
          <app-tableCeil [idxCeil]='4' [text1]='(item.homeTeam?.teamName || "TBD") + " vs."'
                         [text2]='item.awayTeam?.teamName || "TBD"' [commaForMobile]='false'></app-tableCeil>
          <app-table-cell-game-notes ceilTable [idxCeil]='5'
                                     stopPropagation
                                     [game]="item">
          </app-table-cell-game-notes>

          <!--=== CEIL OFFICIALS idxCeil==5 ======================-->
          <photos *ngIf='obj.desktop && !objGame.isPendingGoForOfficial' type='game' [game]='item' [showSmallCircle]='true' ceilTable
                  [idxCeil]='6'></photos>
          <app-tableCeil *ngIf='obj.mobile' [idxCeil]='6' [text1]='item.text_assignGO_outOf_goLength'></app-tableCeil>
          <div *ngIf='objGame.isPendingGoForOfficial' class='t-table__item__wrapCeil' ceilTable [idxCeil]='6' [bordNone_forMobile]='true'>
            <div class='blockPendingAssignorApproval'>Pending Assignor Approval</div>
          </div>

          <!--  === ACTIONS FOR ITEM TABLE ==========================================-->
          <app-tableCeilActions *ngIf='obj.desktop' [matMenuTriggerFor]='menuItemTable' stopPropagation></app-tableCeilActions>
          <mat-menu #menuItemTable='matMenu'>
            <ng-container [ngTemplateOutlet]='actionsForItemTable'></ng-container>
          </mat-menu>
          <div *ngIf='obj.mobile' class='t-actionsItemTable t-actionsItemTable--{{obj.desktop}}' stopPropagation>
            <ng-container [ngTemplateOutlet]='actionsForItemTable'></ng-container>
          </div>

          <ng-template #actionsForItemTable>

            <button *ngIf='!objGame.gameCANCELLED && objGame.meGo?.status == "UNACCEPTED" && !objGame.meGo?.selfAssigned && obj.CURRENT'
                    (click)="$event.stopPropagation();setStatus(item,'ACCEPTED')" [class.o-btn-square-blueDark]='obj.mobile'
                    class='gameCANCELLED==={{objGame.gameCANCELLED}}  meGo?.status=={{objGame.meGo?.status}}  selfAssigned=={{objGame.meGo?.selfAssigned}}  CURRENT=={{obj.CURRENT}}'>
              Accept
            </button>

            <button *ngIf='!objGame.gameCANCELLED && objGame.meGo?.status == "UNACCEPTED" && !objGame.meGo?.selfAssigned && obj.CURRENT'
                    (click)="$event.stopPropagation();setStatus(item,'DECLINED')" [class.o-btn-square-red]='obj.mobile'>
              Decline
            </button>

            <button *ngIf='!objGame.gameCANCELLED && objGame.meGo?.status == "DECLINED" && obj.CURRENT'
                    (click)="$event.stopPropagation();setStatus(item,'ACCEPTED')" [class.o-btn-square-blueDark]='obj.mobile'>
              Re-Accept
            </button>

            <button (click)='router.navigate([urlGameInfo,item.id])' [class.o-btn-square-blueDark]='obj.mobile'>
              {{ obj.desktop ? "View Full Details" : "View Details" }}
            </button>
          </ng-template>
        </div>
      </ng-container>
      <app-tableEmpty [tableEmpty]='filtersS.searchValue|getTableEmpty'>
        <btn bcg='blueDark' text='Go to Open Games' [btnHeight]='deviceS.btnHeightForNothingList'
             (emit)='router.navigate([urlOpenGames])' class='class_ngContent_btn_for_nothingList'></btn>
      </app-tableEmpty>
    </div>
  </div>

  <app-tableInfiniteLoading></app-tableInfiniteLoading>
  <paginationWithSettings *ngIf='meTableS.showPagination'></paginationWithSettings>
</div>