<!--groupCreated: (meS.onboarding$|async)?.groupCreated,-->
<!--onboardingSkipped: (dashboardS.onboardingSkipped$|async),-->
<ng-container *ngIf='{OFFICIAL: (meS.me$|async)?.roleCurrent === "OFFICIAL",
                      DESKTOP: (deviceS.isDesktop$|async),
                      MOBILE: (deviceS.isMobile$|async),
                      menu_disabled: (meS.needGetOnboarding$|async) && !(dashboardS.onboardingSkipped$|async) && (meS.me$|async)?.roleCurrent !== userRole_OFFICIAL,
                     } as obj'>

  <!--  <div class='wrapperMenuNavigation {{!obj.groupCreated && !obj.OFFICIAL ? "wrapperMenuNavigation&#45;&#45;disabled":""}}'-->
  <div class='wrapperMenuNavigation {{obj.menu_disabled ? "wrapperMenuNavigation--disabled":""}}'
       [class.wrapperMenuNavigation--OFFICIAL]='obj.OFFICIAL'>
    <!--    <div *ngIf='!obj.groupCreated && !obj.OFFICIAL' class='o-fakeBlock'></div>-->
    <div *ngIf='obj.menu_disabled' class='o-fakeBlock'></div>
    <div class='wrapperMenuNavigation__content'>
      <img *ngIf='obj.MOBILE' src='assets/line/{{obj.OFFICIAL ? "line_threeLineBlueDark&24":"line_threeLineWhite&20"}}.svg'
           alt='Menu' (click)='showMenu=!showMenu'>
      <ng-container *ngIf='obj.DESKTOP' [ngTemplateOutlet]='logoNotchTemplate'></ng-container>
      <ng-container *ngIf='obj.DESKTOP' [ngTemplateOutlet]='linksTemplate'></ng-container>

      <div class='actions'>
        <div class='actions__bell'>
          <ng-container *ngIf='meS.GROUP_ASSIGNOR && !mainS.forProd'>
            <img src='assets/bell/bell_white.svg' alt=''>
            <img src='assets/circle/circle_red2&8.svg' alt='' class='redPoint'>
          </ng-container>
          <ng-container *ngIf='meS.OFFICIAL && !mainS.forProd'>
            <img src='assets/bell/bell_blueDark.svg' alt=''>
            <img src='assets/circle/circle_red2&8.svg' alt='' class='redPoint'>
          </ng-container>
        </div>
        <img *ngIf='meS.GROUP_ASSIGNOR && !mainS.forProd' src='assets/question/question_white&16.svg' alt=''>
        <img *ngIf='meS.OFFICIAL && !mainS.forProd' src='assets/question/question_blueDark&16.svg' alt=''>
        <ng-container *ngIf='meS.OFFICIAL'>
          <meSvg svgName='calendar_blueDark&20' routerLink='/planner'></meSvg>
        </ng-container>
        <meSvg *ngIf='meS.GROUP_ASSIGNOR || meS.SUB_ASSIGNOR' svgName='bell_hornWhite&20' routerLink='/announcements'></meSvg>
        <photo [data]='meS.me' class='mePhoto o-zIndex10' [matMenuTriggerFor]='obj.DESKTOP ? menuMeUser : null'></photo>
        <mat-menu #menuMeUser='matMenu'>
          <menuMeUser></menuMeUser>
        </mat-menu>
      </div>
    </div>
  </div>

  <div *ngIf='showMenu' class='menuMobile' [class.menuMobile--OFFICIAL]='obj.OFFICIAL'>
    <div class='menuMobile__content'>
      <ng-container [ngTemplateOutlet]='logoNotchTemplate'></ng-container>
      <ng-container [ngTemplateOutlet]='linksTemplate'></ng-container>
      <ng-container [ngTemplateOutlet]='actionsMobileTemplate'></ng-container>
    </div>
    <div class='menuMobile__bcg'>
      <meSvg svgName='cross_white&24' (click)='handleMenu(false)'></meSvg>
    </div>
  </div>


  <!--===================================-->
  <ng-template #linksTemplate>
    <div class='wrapLinks' [class.wrapLinks--OFFICIAL]='obj.OFFICIAL'>
      <!--  routerLinkActive='active' [routerLink]='link.url' [class.active]='link.active'  -->
      <!--      [class.active]='currentHeaderNavigation?.path === link.path'-->
      <div *ngFor='let link of arrHeaderNavigation' class='wrapLinks__link'
           [class.active]='currentHeaderNavigation?.path === link.path' (click)='changeLink(link)'>
        {{ link.text }}
      </div>
    </div>
  </ng-template>

  <ng-template #actionsMobileTemplate>
    <div class='wrapLinks actionsMobile' [class.wrapLinks--OFFICIAL]='obj.OFFICIAL'>
      <!--      [class.active]='link.active'-->
      <div *ngFor='let link of arrHeaderAction' class='wrapLinks__link'
           [class.active]='currentHeaderNavigation?.path === link.path' routerLinkActive='active' (click)='changeLink(link)'>
        {{ link.text }}
        <meSvg *ngIf='link.svgRight' [svgName]='link.svgRight'></meSvg>
      </div>
    </div>
    <div class='photoNameMobile' [class.photoNameMobile--OFFICIAL]='obj.OFFICIAL'>
      <photo [data]='meS.me' class='mePhoto'></photo>
      <div class='photoNameMobile__text'>
        <div class='photoNameMobile__text__name o-noWrap'>{{ meS.me|getUserName }}</div>
        <div class='photoNameMobile__text__email o-noWrap'>{{ meS.me?.email }}</div>
      </div>
      <img src='assets/arrow/arrow_logout{{meS.OFFICIAL?"":"White"}}&16.svg' alt='' class='photoNameMobile__logout'
           (click)='mainS.logout()'>
    </div>
  </ng-template>

  <!--=== logoNotch =================-->
  <ng-template #logoNotchTemplate>
    <img *ngIf='obj.DESKTOP' src='assets/bcgLogo/bcgLogo_logoNotch{{meS.OFFICIAL?"":"Green"}}.svg' alt='' class='logoNotch'>
    <img *ngIf='obj.MOBILE' src='assets/bcgLogo/bcgLogo_textNotch{{meS.OFFICIAL?"":"Green"}}.svg' alt='' class='logoNotch'>
  </ng-template>
</ng-container>