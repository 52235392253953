import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of, switchMap } from 'rxjs';
import {
  arrCompetitionTypeDrop,
  arrGamePeriodDrop,
  arrGameTypeDrop,
  arrNavigationCompetitions,
  arrPayoutFormatDrop,
  arrSportDrop,
  ClassCompetition,
  ClassCompetitionAgeGroup,
  ClassCompetitionLevel,
  ClassCompetitionLocation,
  ClassCompetitionOfficialLabels,
  ClassCompetitionOfficialLabelsItem,
  ClassCompetitionPayScale,
  ClassCompetitionSeason,
  ClassCompetitionTeam,
  ClassCompetitionUser,
  getClassCompetitionsByUrl,
  getMethodCompetitionsByPage,
  getUrlNavigationCompetitionFromUrl,
  IResponseCompetitionUsers,
  TKeyofArrInCompetition,
  TUrlNavigationCompetition,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { str_setup_competition, urlDashboard } from '@app/app.module';
import { TColor } from '@models/ICssStyles';
import { map, tap } from 'rxjs/operators';
import {
  addTextForArray,
  arrYesNoDrop,
  defaultNameMatOption,
  findDefaultDrop,
  getArrayDropFromNumber,
} from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { RoutesService } from '@services/routes.service';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { UtilsService } from '@services/utils.service';

@Injectable({ providedIn: 'root' })
export class CompetitionService {
  readonly arrPayoutFormatDrop = arrPayoutFormatDrop;
  readonly arrGamePeriodDrop = arrGamePeriodDrop;
  readonly arrGameTypeDrop = arrGameTypeDrop;
  readonly arrCompetitionTypeDrop = arrCompetitionTypeDrop;
  readonly arrSportDrop = arrSportDrop;
  readonly arrMaxCrewSizeDrop = getArrayDropFromNumber(10);
  readonly arrYesNoDrop = arrYesNoDrop;
  readonly enableSelfRequest = arrYesNoDrop;
  selfApplyLimit = addTextForArray(getArrayDropFromNumber(6), 'Unlimited', false);

  // === CREW SIZE =====================
  private arrCrewSizeSub$ = new BehaviorSubject<Array<string>>(['1']);

  get crewSize(): Array<string> {
    return this.arrCrewSizeSub$.getValue();
  }

  bcg: TColor = 'newGreen';

  constructor(
    private apiCompetitionS: ApiCompetitionService,
    private router: Router,
    private routesS: RoutesService,
  ) {
    this.competitionSub$.subscribe((competition?: ClassCompetition) => {
      if (competition) {
        const keys = Array.from(Array(competition.maxCrewSize).keys());
        this.arrCrewSizeSub$.next(keys.map(el => (+el + 1).toString()));
      }
    });
  }

  getData(competitionId: string): Observable<ClassCompetition | null> {
    const url = this.routesS.urlWithoutQueryParams!;
    const urlNavigationCompetitionFromUrl = getUrlNavigationCompetitionFromUrl(url);
    const currentObjPage = arrNavigationCompetitions.find(el => el.url === urlNavigationCompetitionFromUrl);

    if (currentObjPage?.methodCompetitions) { // !!! for 'officialLabels' | 'ageGroups' | 'levels' | 'payScales' | 'seasons' | 'locations' | 'teams' | 'users'
      // const result: Observable<ClassCompetition> = this.apiCompetitionS[currentObjPage.methodCompetitions](this.competition, competitionId, 'get');
      const result: Observable<ClassCompetition> = this.apiCompetitionS[currentObjPage.methodCompetitions](this.competition, 'get');
      return result.pipe(
        switchMap((res) => {
          if (currentObjPage?.url === 'payScales') { // !!! для payScales надо также получить 'ageGroups' & 'levels' для дропдаунов
            return forkJoin([
              // this.apiCompetitionS.methodCompetitionAgeGroups(this.competition, competitionId, 'get'),
              // this.apiCompetitionS.methodCompetitionLevels(this.competition, competitionId, 'get'),
              this.apiCompetitionS.methodCompetitionAgeGroups(this.competition, 'get'),
              this.apiCompetitionS.methodCompetitionLevels(this.competition, 'get'),
            ]).pipe(
              map(([res_ageGroups, res_levels]) => {
                const res_payScales = res?.payScales?.length ? res?.payScales : [new ClassCompetitionPayScale({}, this.competition?.id!)];
                const result: ClassCompetition = {
                  ...this.competition,
                  ageGroups: res_ageGroups?.ageGroups || [],
                  // levels: res_levels?.levels?.length ? res_levels?.levels : [this.getDefaultCompetitionLevel()],
                  levels: res_levels?.levels || [],
                };
                result.payScales = res_payScales.map(el => {
                  const isDefaultLevel = el.level?.id === defaultNameMatOption;
                  if (el.level?.id && !isDefaultLevel) {
                    return el;
                  } else { // !!! если в payScale нет level, то надо установить defaultLevel('ALL')
                    return {
                      ...el,
                      // level: el?.level?.id && !isDefaultLevel ? el?.level : (findDefaultDrop(result.levels!) || this.getDefaultCompetitionLevel()),
                      level: el?.level?.id && !isDefaultLevel ? el?.level : (findDefaultDrop(result.levels!)),
                    };
                  }
                });
                return result;
              }),
            );
          } else {
            return of(res);
          }
        }),
        tap((res) => {
          // !!! если компетишн новый, то в нем нет ещё 'officialLabels' | 'ageGroups' | 'levels' | 'payScales' | 'seasons' | 'locations' | 'teams' | 'users'. Поэтому надо создать один новый
          const keyForArray = currentObjPage?.url as TKeyofArrInCompetition;
          if (currentObjPage?.url === 'list' || currentObjPage?.url === 'about') console.error('CompetitionService.getData() currentObjPage?.url:', currentObjPage?.url);
          const classCompetitionsByUrl = getClassCompetitionsByUrl(keyForArray);
          if (!classCompetitionsByUrl) {
            console.error('CompetitionService.getData() no have classCompetitionsByUrl:', '  currentObjPage :', currentObjPage, '  classCompetitionsByUrl :', classCompetitionsByUrl);
            return;
          }
          const new_arr = res && res[keyForArray]?.length ? res[keyForArray] : [new classCompetitionsByUrl({}, competitionId)];
          const updatedCompetition = { ...this.competition, ...res, [keyForArray]: new_arr };

          this.updateCompetition(updatedCompetition);

          // !!! уже времени не осталось чтобы порефакторить это. Пусть пока что будет так. Потом позже найду время и переделаю
          if (currentObjPage?.url === 'officialLabels') {
            this.newOfficialLabels(new_arr as Array<ClassCompetitionOfficialLabels>);
          }
        }),
      );
    } else { // !!! for 'list' | 'about' | setup-competition
      // !!! для страниц 'list' не подключен CompetitionResolver
      // !!! для страниц 'about/setup-competition' НЕ вызывается этот метод getData() в CompetitionResolver
      // !!! для страниц 'about' НЕ надо дополнительных запросов на сервер ортправлять, поэтому возвращается of(null)
      return of(null);
    }
  }

  // !!! уже времени не осталось чтобы порефакторить это. Пусть пока что будет так. Потом позже найду время и переделаю
  private newOfficialLabels(new_arr: Array<ClassCompetitionOfficialLabels>): Array<ClassCompetitionOfficialLabels> {
    const newOfficialLabels: Array<ClassCompetitionOfficialLabels> = [];
    this.setAmountOfficialLabels();
    UtilsService.arrayFromNumber(this.amountOfficialLabels).forEach((maxCrewSizeItem) => { // [1,2,3,4,5]
      let idItem: string = '';
      if (this.officialLabels?.length) idItem = this.officialLabels[maxCrewSizeItem - 1]?.id || '';
      // if (new_arr?.length) idItem = new_arr[maxCrewSizeItem - 1]?.id || '';

      const newItem: ClassCompetitionOfficialLabels = {
        id: idItem,
        competitionId: this.competition?.id || '',
        officialPosition: maxCrewSizeItem, // officialId: maxCrewSizeItem, // crewSize
        labels: [], // Array<ClassCompetitionOfficialLabelsItem>;
      };

      UtilsService.arrayFromNumber(this.amountOfficialLabels).forEach((maxCrewSizeSubItem) => {
        let labelValue: string = '';
        let labelId: string = '';
        if (this.officialLabels?.length && this.officialLabels[maxCrewSizeItem - 1]?.labels?.length) {
          labelValue = this.officialLabels[maxCrewSizeItem - 1]?.labels![maxCrewSizeSubItem - 1]?.label || '';
          labelId = this.officialLabels[maxCrewSizeItem - 1]?.labels![maxCrewSizeSubItem - 1]?.id || '';
        }
        // if (new_arr?.length && new_arr[maxCrewSizeItem - 1]?.labels?.length) {
        //   labelValue = new_arr[maxCrewSizeItem - 1]?.labels![maxCrewSizeSubItem - 1]?.label || '';
        //   labelId = new_arr[maxCrewSizeItem - 1]?.labels![maxCrewSizeSubItem - 1]?.id || '';
        // }
        const newSubItem: ClassCompetitionOfficialLabelsItem = {
          indexNumber: maxCrewSizeSubItem,
          id: labelId,
          label: labelValue,
          disabled: !!(this.competition?.maxCrewSize && (this.competition?.maxCrewSize < 4)) && (this.competition?.maxCrewSize < maxCrewSizeSubItem),
        };
        newItem.labels?.push(newSubItem);
      });
      newOfficialLabels.push(newItem);
    });
    this.competition.officialLabels = newOfficialLabels;
    return this.officialLabels;
  }

  // !!! is show controls 'official1' | 'official2' | 'official3' | 'official4' | 'official5' | 'official6' | 'official7' | 'official8' | 'official9' | 'official10'
  isShowCrewOfficial(columnNumber: number, forTest: string): boolean {
    const result = this.competition.maxCrewSize! >= columnNumber;
    return result;
  }

  reset(): void {
    this.competitionSub$.next(new ClassCompetition());
  }

  addCompetitionIdBeforeSendToServer<T>(arr?: Array<T & { competitionId?: string }>): void {
    if (!this.competition?.id) return;
    arr?.forEach((el) => {
      el.competitionId = this.competition?.id;
    });
  }

  // !!! id удаляемого
  deleteItem(id: string, page: TUrlNavigationCompetition): Observable<any> {
    const method = getMethodCompetitionsByPage(page);

    const arr = page.split(' ');
    let keyofClassCompetition = arr.map((item, i) => {
      if (i === 0) return item.toLowerCase();
      else return item;
    }).join('') as TKeyofArrInCompetition;

    // const observable: Observable<any> = this.apiCompetitionS[method!]({}, this.competition?.id!, 'delete');
    const observable: Observable<any> = this.apiCompetitionS[method!]({}, 'delete', id);
    return observable.pipe(
      tap((res: any) => {
        if (this.competition[keyofClassCompetition]) {
          this.competition[keyofClassCompetition] = (this.competition[keyofClassCompetition] as Array<any>)?.filter((el: any) => el.id !== id);
        }
      }),
    );
  }

  // === NAVIGATION ===================================
  nextStep(currentUrl: TUrlNavigationCompetition): void {
    const idxCurrentUrl = arrNavigationCompetitions.findIndex((el) => el.url === currentUrl);
    const urlForRedirect: TUrlNavigationCompetition = arrNavigationCompetitions[idxCurrentUrl + 1].url;
    const endStrForUrl = this.competition?.id ? this.competition?.id : str_setup_competition;
    this.router.navigate([`competitions/${urlForRedirect}/${endStrForUrl}`]);
  }

  goToDashboard(): void {
    this.router.navigate([urlDashboard]);
  }

  goToBack(): void {
    window.history.back();
  }

  //  === Competition ===========================
  competitionSub$ = new BehaviorSubject<ClassCompetition>(new ClassCompetition());
  competition$ = this.competitionSub$.asObservable();

  // !!! вызывается только 1 раз при загрузке страницы в CompetitionResolver
  getCompetitionById(competitionId: string): Observable<ClassCompetition | undefined> {
    return this.apiCompetitionS.methodCompetition({ id: competitionId }, 'get').pipe(
      tap((competition?: ClassCompetition) => {
        if (!competition) return;
        this.updateCompetition(competition, 'GET competition');
      }),
    );
  }

  get competition(): ClassCompetition {
    return this.competitionSub$.getValue()!;
  }

  updateCompetition(competition: ClassCompetition, forTest?: string): void {
    this.competitionSub$.next(new ClassCompetition({ ...this.competitionSub$.getValue(), ...competition }));
    this.setArrTableHeaderPayScales();
    this.setArrTableHeaderOfficialLabels();
  }

  // === official Labels  =======================
  get officialLabels(): Array<ClassCompetitionOfficialLabels> {
    return this.competitionSub$.getValue()?.officialLabels || [];
  }

  private arrTableHeaderOfficialLabelsSub$ = new BehaviorSubject<Array<string>>(['Crew Size']);
  arrTableHeaderOfficialLabels$ = this.arrTableHeaderOfficialLabelsSub$.asObservable();

  get arrTableHeaderOfficialLabels(): Array<string> {
    return this.arrTableHeaderOfficialLabelsSub$.getValue();
  }

  setArrTableHeaderOfficialLabels() {
    const arrTableHeader: Array<string> = ['Crew Size'];
    this.setAmountOfficialLabels();
    Array.from(Array(this.amountOfficialLabels).keys()).forEach((el) => {
      arrTableHeader.push(`${el + 1}`);
    });
    this.arrTableHeaderOfficialLabelsSub$.next(arrTableHeader);
  }

  amountOfficialLabels: number = 4;

  setAmountOfficialLabels() {
    this.amountOfficialLabels = this.competition?.maxCrewSize && this.competition?.maxCrewSize < 4 ? 4 : (this.competition?.maxCrewSize || 4);
  }

  // === AgeGroup =======================
  get ageGroups(): Array<ClassCompetitionAgeGroup> {
    return this.competitionSub$.getValue()?.ageGroups || [];
  }

  // 'Default Crew Size',
  private arrTableHeaderAgeGroupsSub$ = new BehaviorSubject<Array<string>>(['Game Age Description', 'Game Period', 'Period Length',
    'Total Duration (Min)', 'Self-Request Limit', '']);

  get arrTableHeaderAgeGroups(): Array<string> {
    return this.arrTableHeaderAgeGroupsSub$.getValue();
  }

  // === LEVELS  =======================
  get levels(): Array<ClassCompetitionLevel> {
    return this.competitionSub$.getValue()?.levels || [];
  }

  // !!! при создании нового payScale (button Add new +) нужно по деволту сразу установить level=='ALL' inside payScale
  // !!! если levels не создан, то вернется undefined
  get defaultLevel(): ClassCompetitionLevel | undefined {
    return this.levels?.find(el => el.level === defaultNameMatOption);
  }

  // === PAY SCALES =================
  get payScales(): Array<ClassCompetitionPayScale> {
    return this.competitionSub$.getValue()?.payScales || [];
  }

  // 'Crew Rates',
  private arrTableHeaderPayScalesSub$ = new BehaviorSubject<Array<string>>(['Game Type', 'Age', 'Level', 'Crew Size',
    'Official #1', 'Group Assignor Rate', 'Sub-Assignor Rate', 'Other Rate', 'Total Rate', '']);
  arrTableHeaderPayScales$ = this.arrTableHeaderPayScalesSub$.asObservable();

  get arrTableHeaderPayScales(): Array<string> {
    return this.arrTableHeaderPayScalesSub$.getValue();
  }

  setArrTableHeaderPayScales() {
    const arrTableHeader: Array<string> = ['Game Type', 'Age', 'Level', 'Crew Size'];
    Array.from(Array(this.competition?.maxCrewSize).keys()).forEach((el) => {
      arrTableHeader.push(`Official #${el + 1}`);
    });
    arrTableHeader.push('Group Assignor Rate', 'Sub-Assignor Rate', 'Other Rate', 'Total Rate', '');
    this.arrTableHeaderPayScalesSub$.next(arrTableHeader);
  }

  // === SEASONS =================
  get seasons(): Array<ClassCompetitionSeason> {
    return this.competitionSub$.getValue()?.seasons || [];
  }

  // === LOCATIONS =================
  get locations(): Array<ClassCompetitionLocation> {
    return this.competitionSub$.getValue()?.locations || [];
  }

  // === TEAMS =================
  get teams(): Array<ClassCompetitionTeam> {
    return this.competitionSub$.getValue()?.teams || [];
  }

  // === USERS =================
  getCompetitionUsers(competitionId: string): Observable<Pick<ClassCompetition, 'users'>> {
    return this.apiCompetitionS.methodCompetitionUsers({ id: competitionId }, 'get').pipe(
      // !!! if searchText  return Array<ClassCompetitionUser>>
      // !!! if !searchText return {content: Array<ClassCompetitionUser>}
      tap((res: Pick<ClassCompetition, 'users'>) => {
        // !!! если компетишн новый, то в нем нет ещё users. Поэтому надо создать один новый
        this.updateCompetition({ ...this.competition, users: res.users || [] }, 'GET USERS');
        this.newUsers();
      }),
    );
  }

  inviteUsersInCompetition(sendObj: IResponseCompetitionUsers): Observable<Pick<ClassCompetition, 'users'>> {
    // return this.apiCompetitionS.inviteUsersInCompetition(sendObj, this.competition.id!).pipe(
    return this.apiCompetitionS.inviteUsersInCompetition({ ...sendObj, id: this.competition.id }).pipe(
      switchMap((res) => this.getCompetitionUsers(this.competition.id!)),
    );
  }

  inviteUsersInMultipleCompetition(sendObj: IResponseCompetitionUsers): Observable<Pick<ClassCompetition, 'users'>> {
    return this.apiCompetitionS.inviteUsersInCompetition({ ...sendObj, id: this.competition.id });
  }
  
  resendInviteToCompetition(competitionUser: ClassCompetitionUser): Observable<Pick<ClassCompetition, 'users'>> {
    const sendObj: IResponseCompetitionUsers = { id: this.competition.id, users: [competitionUser] };
    // return this.apiCompetitionS.inviteUsersInCompetition(sendObj, this.competition.id!);
    return this.apiCompetitionS.inviteUsersInCompetition(sendObj);
  }

  // !!! andrei перенсети в ClassCompetitionUser
  newUsers(): Array<ClassCompetitionUser> {
    const newItem = new ClassCompetitionUser({
      // competitionId: this.competition?.id || '',
      // id: '',
      // name: '',
      // role: undefined,
      // roleDrop: undefined,
    }, this.competition?.id!);
    if (this.users?.length) {
      const newArrItems: Array<ClassCompetitionUser> = [];
      this.users.forEach((el, idx) => {
        newArrItems.push({ ...newItem, ...el });
      });
      this.competition.users = newArrItems;
      return this.users;
    } else {
      this.competition.users = [newItem];
      return this.users;
    }
  }

  get users(): Array<ClassCompetitionUser> {
    return this.competitionSub$.getValue()?.users || [];
  }

}
