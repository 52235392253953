import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassTransfer } from '@models/transfer.model';
import { ISwitcherBtn, SwitcherBtnComponent } from '@components/switcher-btn/switcher-btn.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnComponent } from '@components/btn/btn.component';
import { FormsModule } from '@angular/forms';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { TransferApiService } from '@services/transfer.api.service';
import { BehaviorSubject, finalize } from 'rxjs';
import { HelperClass } from '@classes/Helper-Classes';
import { StatusComponent } from '@components/status/status.component';
import { MatMenuModule } from '@angular/material/menu';
import { DeviceService } from '@services/device.service';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { ClassCeilTableHeader, IForClassForTable, TypeEmitSettingRequest } from '@components/_table/meTable';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { MeTableService } from '@components/_table/me-table.service';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { TableCeilActionsComponent } from '@components/_table/table-ceil-actions/table-ceil-actions.component';
import { TableCeilChxComponent } from '@components/_table/table-ceil-chx/table-ceil-chx.component';
import { TableCeilComponent } from '@components/_table/table-ceil/table-ceil.component';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { MyWalletService } from '@app/dir_officials/page-my-wallet/my-wallet.service';
import {
  MyWalletItemTableForMobileComponent,
} from '@app/dir_officials/page-my-wallet/components/my-wallet-item-table-for-mobile/my-wallet-item-table-for-mobile.component';
import {
  GetArrayForMyWalletTableForMobilePipe,
} from '@app/dir_officials/page-my-wallet/pipes/get-array-for-my-wallet-table-for-mobile.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { FiltersService } from '@components/filters/filters.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';

@UntilDestroy()
@Component({
  selector: 'myWalletTable',
  standalone: true,
  imports: [CommonModule, WrapPageDirective, CdkScrollable, DataTableDirective, SvgComponent, SwitcherBtnComponent, InputCtrlComponent, BtnComponent, TableCeilChxComponent, TableCeilComponent, ItemTableDirective, StatusComponent, CeilTableDirective, StopPropagationDirective, TableCeilActionsComponent, MatMenuModule, GetArrayForMyWalletTableForMobilePipe, MyWalletItemTableForMobileComponent, TableEmptyComponent, TableInfiniteLoadingComponent, PaginationComponent, FormsModule, TableHeaderComponent, AdminPermissionDirective, GetTableEmptyPipe],
  templateUrl: './my-wallet-table.component.html',
  styleUrls: ['./my-wallet-table.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// !!! IForClassForTable for-table===
export class MyWalletTableComponent extends HelperClass implements IForClassForTable<ClassTransfer> {
  dataTable$ = new BehaviorSubject<IResponse<ClassTransfer>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<ClassTransfer>>([]); // for-table===
  settings: ClassSettingsRequest = new ClassSettingsRequest({ transferType: 'STRIPE' });

  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;

  constructor(
    private transferApiS: TransferApiService,
    public myWalletS: MyWalletService,
    public deviceS: DeviceService,
    public meTableS: MeTableService<ClassTransfer>, // for-table===
    // public filtersS: FiltersService, // for-filters===
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.setAllData();
  }

  setAllData(): void {
    this.setDataForTable(); // for-table===
    this.subscribeToSearch();
  }
  totalPages: number = 1;

  getOfficialTransfers(type?: TypeEmitSettingRequest): void {
    this.meTableS.startLoadingTable(); // for-table===
    this.transferApiS.getOfficialTransfers(this.settings)
      .pipe(
        finalize(() => { // for-table===
          this.meTableS.endLoadingTable();
          this.cd.detectChanges();
        }),
        untilDestroyed(this),
      )
      .subscribe((res?: IResponse<ClassTransfer>) => {
        if (this.deviceS.isMobile) {
          if (res) {
            this.arrContent$.next([
              ...this.arrContent$.getValue(),
              ...(res.content ?? []),
            ]);
            this.totalPages = res.totalPages ?? 1;
          }
          this.loadNextPage();
        }
        this.meTableS.setDataAfterResponseServer(res, type); // for-table===
        this.meTableS.endLoadingTable();
        this.cd.detectChanges();
      });
  }

  subscribeToSearch(): void {
    this.subscribeToSearchFromHelperClass().pipe(untilDestroyed(this)).subscribe((searchValue: string) => {
      this.settings.search = searchValue;
      if (!searchValue?.trim()) delete this.settings.search;
      this.getOfficialTransfers();
    });
  }

  switcherBtnEmit(event: ISwitcherBtn): void {
    if (this.settings.transferType == 'STRIPE' && event.btn1) return; // если на текущую активную кнопку нажал
    this.settings.transferType = event.btn1 ? 'STRIPE' : 'OFFLINE';
    this.settings.page = 0;
    this.arrContent$.next([]);
    this.infiniteLoadingSpinner = true; 
    this.getOfficialTransfers();
  }

  // === TABLE ======================================================
  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.arrContent$ = this.arrContent$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Game' }), //  isChx: true
      new ClassCeilTableHeader({ text: 'Game Date' }),
      new ClassCeilTableHeader({ text: 'Role' }),
      new ClassCeilTableHeader({ text: 'Age & Level' }),
      new ClassCeilTableHeader({ text: 'Transaction Date' }),
      new ClassCeilTableHeader({ text: 'Amount', isCentered: true }),
      new ClassCeilTableHeader({ text: 'Status', isCentered: true }),
      new ClassCeilTableHeader({ text: '' }),
    ];
    const arrWidthCeilTable: Array<number> = [92, 190, 120, 160, 180, 110, 84, 70]; // !!! ширина для каждой ячейки для Desktop => сумма должна совпадать с this.widthPage
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.myWalletS.widthPage, false);
  }

  
infiniteLoadingSpinner: boolean = true;
  
  loadNextPage(): void {
    this.settings.page = (this.settings.page ?? -1) + 1;
    // Check if the current page is less than total pages
    if (this.settings.page >= this.totalPages) {
      this.infiniteLoadingSpinner = false; // Stop loading if no more pages
      return;
    }

    // Load the next page
    this.settings.page++;

    // Call the API to get the transfers
    this.transferApiS.getOfficialTransfers(this.settings).subscribe({
      next: (res?: IResponse<ClassTransfer>) => {
        if (res) {
          this.arrContent$.next([
            ...this.arrContent$.getValue(),
            ...(res.content ?? []),
          ]);
        }

        // Check if there are more pages to load
        if (this.settings.page && (this.settings.page < this.totalPages)) {
          // Load the next page after a 2-second delay
          setTimeout(() => this.loadNextPage(), 2000);
        } else {
          this.infiniteLoadingSpinner = false; // No more pages to load
        }
      },
      error: (error) => {
        console.error("Error loading transfers:", error);
      }
    });
  }
  emitSettingRequest(settingsRequest: ClassSettingsRequest, type: TypeEmitSettingRequest): void {
    this.settings = { ...this.settings, ...settingsRequest };
    this.getOfficialTransfers(type);
  }


  @HostListener('window:scroll', [])
  onScroll(): void {
    this.loadNextPage();
  }
  
}

