import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { TUserRoleUpperCase } from '@models/user';

// export type TLink_groupProfile = 'Group Profile' | 'Adjustment Statuses' | 'User Management' | 'CSV Import History';
export type TLink_groupProfile = 'Group Profile' | 'Adjustment Statuses' | 'User Management';
// export type TLink_groupProfile_camelCase = 'info' | 'adjustmentStatuses' | 'userManagement' | 'csvImportHistory';
export type TLink_groupProfile_camelCase = 'info' | 'adjustmentStatuses' | 'userManagement';

export type TCurrentLinkDrop_groupProfile = ClassDrop & {
  titleCase: TLink_groupProfile,
  upperCase: TLink_groupProfile_camelCase,
};

export interface ICurrentLink_groupProfile {
  currentLink?: TCurrentLinkDrop_groupProfile;
}

// !!! === TCurrentLinkDrop_groupProfile || IDropItemProfile
export const arrLinkObj_groupProfile: Array<TCurrentLinkDrop_groupProfile> = [
  { titleCase: 'Group Profile', upperCase: 'info' },
  // { titleCase: 'Adjustment Statuses', upperCase: 'adjustmentStatuses' },
  // { titleCase: 'User Management', upperCase: 'userManagement' },
  // { titleCase: 'CSV Import History', upperCase: 'csvImportHistory' },
];

export function getLinkObj_groupProfile(link_groupProfile_camelCase: TLink_groupProfile_camelCase): TCurrentLinkDrop_groupProfile {
  return arrLinkObj_groupProfile.find((el) => el.upperCase === link_groupProfile_camelCase)!;
}

export function getLink_groupProfile_camelCase_fromUrl(str: string): TLink_groupProfile_camelCase {
  const result = str?.split('/')?.filter(Boolean)[0]?.split('?').filter(Boolean)[0]?.split('_')[1] as TLink_groupProfile_camelCase;
  return result;
}

// ========================
export interface IRequest_for_inviteUserToGroup_emailsWithRoles {
  'email': string;
  'role': TUserRoleUpperCase;
}

export interface IRequest_for_inviteUserToGroup {
  'emailsWithRoles': Array<IRequest_for_inviteUserToGroup_emailsWithRoles>;
  'invitationText': string;
}
