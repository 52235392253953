<!--*ngIf='competitionS.isLoad.users; else spinner'-->
<!--<ng-container *ngIf='competitionS.users?.length; else spinner'>-->
  <div class='wrapperCompetitions'>
    <competitionsNavigation page='users'></competitionsNavigation>
    <ng-container *ngIf='pageUsers=="newUsers"'>
      <div class='wrapperCompetitionsBlock wrapperCompetitionsUsers' [formGroup]='form'>
        <div class='headerSetupCompetition'>
          <svgAndText text='New Users' typeText='text1-blueDark'></svgAndText>
          <btn *ngIf='""|checkExistUsers' text='List users' bcg='newGreen' (emit)='pageUsersSub$.next("listUsers")'></btn>
        </div>

        <div formArrayName='arrControls' class='contentSetupCompetition'>
          <ng-container *ngFor='let item of arrControls?.controls; let idx=index; let first=first;  let last=last'>
            <div [formGroupName]='idx' class='contentSetupCompetition__itemUser contentSetupCompetition__itemUser--{{last?"last":""}}'>
              <div class='contentSetupCompetition__itemUser__fields'>
                <field text='Name or Email' svgName='question_grey&16'
                       matTooltipSVG='Search an existing group user by name or enter the email of the recipient.'>
                  <!--                  <div class='o-mg-t-8 o-mg-b-6'>value=={{item?.controls?.name?.value}}</div>-->

                  <!--                (enterAndFocusOutEmit)='enterAndFocusOutEmit($event)'-->
                  <!--                  <inputCtrl #elRef formControlName='name' placeholder='Name' [isFocus]='true' (enterEmit)='enterAndFocusOutEmit($event,t)'-->
                  <!--                             [matMenuTriggerFor]='usersForDropDown?.length ? menuUsersForDropDown:null' #t='matMenuTrigger'>-->
                  <inputCtrl #elRef formControlName='name' placeholder='Name' [isFocus]='true'
                             [matMenuTriggerFor]='usersForDropDown?.length ? menuUsersForDropDown:null' #t='matMenuTrigger'
                             (changeVal)='searchUsersInCompetition($event,t)'>
                    <!--  1. нет в системе-->
                    <!--  2. есть , но нет в компетигщшне-->
                    <!--  3. есть и есть в компетишне-->
                    <!--                  <error *ngIf='!usersForDropDown?.length && searchText && !item?.controls?.userId?.value'-->
                    <error *ngIf='checkExistError(item)' text='The user is not within the group'></error>
                    <!--                  <error text='The user is not within the group'></error>-->
                  </inputCtrl>
                  <!--                <mat-menu #menuUsersForDropDown='matMenu' yPosition="after" xPosition="before" [overlapTrigger]="false">-->
                  <mat-menu #menuUsersForDropDown='matMenu' class="search-results-menu">
                    <div class="scrollable-menu">
<!--                    <button [ngStyle]='(elRef|getWidthMatMenu)' class='hiddenMatOption'></button>-->
                    <!--                  t.openMenu()-->
                    <button *ngFor='let itemDrop of usersForDropDown' (click)='addNewUserFromDropDown(item, itemDrop)'
                            [style]='{width: "346px", maxWidth: "346px"}'>
                      {{itemDrop?.firstName || itemDrop?.name}}, {{itemDrop?.email}}
                    </button>
                   </div>
                    <!--                  <button [ngStyle]='(elRef?.nativeElement|getWidthMatMenu)' class='hiddenMatOption'></button>-->
                    <!--                  <button [ngStyle]='(elRef|getWidthMatMenu)' class='hiddenMatOption'></button>-->
                    <!--                  <button [style]='{width: "346px", maxWidth: "346px"}' class='hiddenMatOption'></button>-->
                  </mat-menu>
                </field>
                <field text='Role'>
                  <app-dropdown formControlName='roleDrop' [array]='arrUserRoleDrop_forInvite' placeholder='Role' displayField="titleCase" >
                    <copyLink *ngIf='item.controls.roleDrop.value' path='competitions/users'
                              [userRole]='item.controls.roleDrop.value?.upperCase!'
                              [competition]='competitionS.competition' class='o-mg-t-8'></copyLink>
                  </app-dropdown>
                </field>

                <field text=' ' class='svgDelete'>
                  <meSvg [svgName]='first ? "delete_square2&44":"delete_square1&44"'
                         (click)='first ? null : form.controls.arrControls?.removeAt(idx)'></meSvg>
                  <!--              <deleteItemForCompetitions page='Users' [id]='item?.value?.id!' [first]='first'-->
                  <!--                                         (responseEmit)='form.controls.arrControls?.removeAt(idx)'></deleteItemForCompetitions>-->
                </field>
              </div>

              <!--              <div class='contentSetupCompetition__itemUser__actions'>-->
              <!--                <btnAddAnother *ngIf='last' page='competition' (emit)='addNew()'></btnAddAnother>-->
              <!--                <copyLink *ngIf='item.controls.roleDrop.value' path='competitions/users'-->
              <!--                          [userRole]='item.controls.roleDrop.value?.upperCase!'-->
              <!--                          [competition]='competitionS.competition'></copyLink>-->
              <!--              </div>-->
            </div>

            <div *ngIf='last' class='contentSetupCompetition__itemUser__btns'>
              <btnAddAnother page='competition' (emit)='addNew()'></btnAddAnother>
              <btn text='Customize your invitation' bcg='white' svgLeft='magicWand&20' (click)='openPopupCustomizeInvitation()'
                   [disabled]='reqPending'></btn>
              <btn text='Save & Send invitation' bcg='blueDark' (click)='inviteUsersInCompetition()'
                   [disabled]='!form.valid || reqPending || checkExistError(item)'></btn>
            </div>
          </ng-container>

        </div>
      </div>
    </ng-container>
  </div>

  <!--  1. нет в системе-->
  <!--  2. есть , но нет в компетигщшне-->
  <!--  3. есть и есть в компетишне-->
  <!--  (searchEmit)='methodCompetitionUsers("get",$event)'-->
  <competitionsListUsers *ngIf='pageUsers=="listUsers"' (newUser)='openPageNewUser()'
                         (searchEmit)='searchUsersInCompetition($event)' [loadingUsers]='reqPending'></competitionsListUsers>
<!--</ng-container>-->

<!--<ng-template #spinner>-->
<!--  <mat-spinner diameter='40'></mat-spinner>-->
<!--</ng-template>-->
