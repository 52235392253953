<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: gameS.arrCurrentLinkDrop,
              objCurrentLink: (gameS.currentLink$|async)!,
              currentLink: (gameS.currentLink$|async)?.currentLink!,
              dataTable: (dataTable$|async)!,
              arrContent: (arrContent$|async),
              loadingTable: (meTableS.loadingTable$|async)             
             } as obj' class='o-wrapPage st-pading-from-menu' wrapPage [widthPage]='widthPage' cdkScrollable dataTable>

  <div class='o-header '>
    <div class='o-header__content'>
      <div class='o-header__title'>Assignor Fees</div>
      <div class='o-header__filters'>
        <app-filters></app-filters>
      </div>
      <app-filtersSelected></app-filtersSelected>
      <linkPage-searchFilter [haveLinkPage]="false" [filterText]="'Filters'"></linkPage-searchFilter>
      <div class="carousel-panel">
        <app-info-list [list]="[template1, template2]" class="info-list">
          <ng-template #template1>
            <app-info-list-item [title]="'Total Assigning Fees'" [helpTip]="'Total assigning fees approved and paid by your competitions for all payment formats.'"
              [amount]="this.totalAssigningFees! | currencyCents"></app-info-list-item>
          </ng-template>
          <ng-template #template2>
            <app-info-list-item [title]="'Available Payout'" [helpTip]="'Total assigning fees available to payout using Notch Pay.'"
              [amount]="this.availablePayout! | currencyCents"></app-info-list-item>
          </ng-template>
        </app-info-list>
        <div class="hide-on-mobile header-button">
          <ng-container [ngTemplateOutlet]="headerButton"></ng-container>
        </div>
      </div>

      <div class="hide-on-desktop header-button">
        <ng-container [ngTemplateOutlet]="headerButton"></ng-container>
      </div>

      <ng-template #headerButton>
        <div style="position: relative;">
          <btn class="auto-payout" text='Auto Payout' bcg='white' svgLeft='refresh' [btnHeight]="'100%'"></btn>
          <div class="tool-top">
            Coming Soon
            <img src="/assets/vector-lock.svg" alt="Lock Icon">
          </div>
        </div>

        <btn text='Payout' bcg='newGreen' [btnHeight]="'100%'" [disabled]="availablePayout === 0"  (emit)="payoutClicked()"></btn>
      </ng-template>

      <div class="switcher-btn-filter">
        <div class="header">
          Recent Transactions
        </div>
        <div class="buttons">
          <switcherBtn [height]="44" text1='Notch Pay' text2='Offline' [width]='225'
            (emit)='transactionTypeOnChange($event)'></switcherBtn>
          <div class="hide-on-mobile">
            <ng-container [ngTemplateOutlet]="viewAllButton"></ng-container>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class='o-content t-wrapperTable'>

    <div class="st-table-header  hide-on-mobile">
      <div class="st-table-header__ceil" style="width:100px;">
        Game
      </div>
      <div class="st-table-header__ceil" style="width:200px;">
        Game Date
      </div>
      <div class="st-table-header__ceil" style="width: 200px;">
        Competition
      </div>
      <div class="st-table-header__ceil" style="width: 180px;">
        Transaction Date
      </div>
      <div class="st-table-header__ceil st-center" style="width: 115px;">
        Amount
      </div>
      <div class="st-table-header__ceil st-center" style="width: 115px;">
        Status
      </div>
    </div>
    <div class='t-table-v2'>
      <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
        <div class='t-table-v2__item ' (click)="viewDetailsClicked(item.gameId,false)">

          <div class='t-table-v2__item__wrapCeil item-game-number' style="width: 100px;">
            <div class="hide-on-desktop">#</div>
            <div>{{item.gameNumber}}</div>
            <meSvg *ngIf="item.transferStatus=='Failed'" [svgName]="'alert-triangle'" class="hide-on-mobile"></meSvg>
          </div>

          <div class='t-table-v2__item__wrapCeil' style="width: 200px;">
            <div>{{item.gameDateTime | date: globalConst.dateFormat}}</div>
          </div>

          <div class='t-table-v2__item__wrapCeil competitionName-transfer-cents' style="width: 200px;">
            <div class="competition-name" style="font-weight: 600;">
              <div> {{item.competitionName}}</div>
              <div class="hide-on-desktop transfer-cents"> {{item.transferTotalInCents | currencyCents}}</div>
            </div>
            <div>
              {{item.gameAgeDescription}} - {{item.competitionLevel}} - {{item.gameGender}}
            </div>
          </div>

          <div class='t-table-v2__item__wrapCeil' style="width: 180px;">
            {{item.transferCreatedAt | date: globalConst.dateTimeFormat}}
          </div>
          <div class=' hide-on-mobile t-table-v2__item__wrapCeil st-center st-600' style="width: 120px;">
            {{item.transferTotalInCents | currencyCents}}
          </div>

          <div class='t-table-v2__item__wrapCeil st-center' style="width: 135px;">
            <!-- <status [status]="item.transferStatus"></status> -->
            <status [status]="transactionType === 'OFFLINE' ? 'Offline' : item.transferStatus"></status>
          </div>
          <div class='hide-on-mobile t-table-v2__item__wrapCeil st-center' style="width: 135px;">
            <btn class="btn-view" text='View' bcg='white' btnWidth='87' [btnHeight]="36" [fontSize]="14"
              (emit)="viewDetailsClicked(item.gameId,true)">
            </btn>
          </div>
        </div>
      </ng-container>
      <div class="view-all-table hide-on-desktop" *ngIf="obj?.arrContent?.length??0>0">
        <ng-container [ngTemplateOutlet]="viewAllButton"></ng-container>
      </div>
      <div class="t-table-v2-tableEmpty" *ngIf="(obj?.arrContent?.length??0)==0">
        Transactions will appear here
      </div>
    </div>

  </div>
</div>

<ng-template #viewAllButton>
  <btn class="st-btn" [btnHeight]="'100%'" text='View All' bcg='white' (click)="viewAllClicked()">
  </btn>
</ng-template>