<div class='wrapperPopupCreateGroup' [formGroup]='form'>
  <headTitle text='Create Group' svgRight='cross_grey&24' (svgRightEmit)='close()' typeText='text4-blueDark'></headTitle>

  <div class='wrapFields'>
    <field text='Group Name' [ctrl]='ctrl.name'>
      <inputCtrl formControlName='name' placeholder='Group Name' [isFocus]='true'></inputCtrl>
    </field>
    <field text='Company Legal Name' [ctrl]='ctrl.companyLegalName'>
      <inputCtrl formControlName='companyLegalName' placeholder='Company Legal Name'></inputCtrl>
    </field>
    <field text='Email' [ctrl]='ctrl.email'>
      <inputCtrl formControlName='email' placeholder='Email'></inputCtrl>
    </field>
    <phone #phoneComponent [phoneValue]='ctrl.phone?.value'></phone>
    <location [fields]='fields' [requiredFields]='requiredFields' page='createGame'
              [replaceNameField]='{street:"Mailing Address*"}'></location>
  </div>

  <btn text='Complete' bcg='blueDark' (click)='sendForm()' class='btnSendForm' [disabled]='!form.valid || locationS.locInvalid'></btn>
</div>
