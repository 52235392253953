import { Injectable } from '@angular/core';
import { NotificationService } from '@services/notification.service';
import { shortStates } from '@models/ShortStates';
import { IResponseTimezone, ITimezone, TTypeTimezone } from '@classes/geo';
import { CustomDatesService } from '@classes/CustomDates';
import { HttpErrorResponse } from '@angular/common/http';
import { errText500 } from '@classes/dictionary';
import { INewRequest } from '@services/base-api';
import { Location } from '@angular/common';
import { PopupService } from '@services/popup.service';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { LodashService, TypeStringCase } from '@services/lodash.service';
import { IObject } from '@classes/other';
import { ClassUser } from '@models/user';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { ClassTransfer, TransferModel } from '@models/transfer.model';
import { ILocation } from '@models/location';
import { ClassDrop, defaultNameMatOption, getDropFromString } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { ForTestService } from '@classes/forTest';
import { IForTable } from '@components/_table/meTable';
import { ActivatedRoute, Router } from '@angular/router';
import { TypePathForTable } from '@app/app.module';
import { UtilsService } from '@services/utils.service';
import { const_NA, TypeNA } from '@models/other';
import * as _ from 'lodash';

export const patternEmail = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';

export const arrNameCtrlForPassword = ['password', 'confirmPassword', 'confirmpassword', 'currentPassword', 'currentpassword'];

export const selfRequestErrText = `You've reached the maximum daily self-requests for this competition. Please contact your assignor if you have any questions`;

@Injectable({ providedIn: 'root' })
export class OtherService {
  // wrapperShellRef!: ElementRef;

  oneHour = 3600000; // 1час === 3600000
  readonly todayDate: Date = new Date();

  utcTimezone: ITimezone = { abbrev: 'UTC', name: 'Coordinated Universal Time', id: 'UTC', value: 0 };

  constructor(
    private notificationS: NotificationService,
    private datesS: CustomDatesService,
    // private router: Router,
    private _location: Location,
    public popupS: PopupService,
    private lodashS: LodashService,
    private forTestS: ForTestService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    // this.fullListTimezone = fullListTimezone;
  }

  // === ROUTE ======================
  // andrei создать type для всех роутов && need change for routes /games/info
  getCurrentPath(): TypePathForTable {
    return this.router?.url?.split('?')[0]?.replace('/', '') as TypePathForTable;
  }

  // === PAYMENTS ==================================
  getAmountPlusFee(amount: number, plusProcessingFee: boolean, plusTransactionFee: boolean = true): number {
    let result = amount;
    if (plusProcessingFee) result = result + this.getProcessingFee(amount);
    if (plusTransactionFee) result = result + this.getTransactionFee(amount);
    return result;
    // return amount + this.getProcessingFee(amount) + this.getTransactionFee(amount);
  }

  getProcessingFee(amount: number): number { // (2.9% + $0.30)
    return ((amount + this.getTransactionFee(amount) + 0.3) / 0.971 * 0.029) + 0.3;
  }

  getTransactionFee(amount: number): number { // (1.25%)
    return amount * 0.0125;
  }

  getCommission(amount: string | number, plusProcessingFee: boolean, plusTransactionFee: boolean = true, toFixed = 2): number | '' {
    if (!amount) return '';
    const amountPlusCommission = this.getAmountPlusFee(+amount, plusProcessingFee, plusTransactionFee) as number;
    return +(amountPlusCommission - +amount)?.toFixed(toFixed);
  }

  // !!! convert IResCreatePaymentMethod to IPaymentMethod // чтобы функционал не ломать. Потом переделать надо
  // convertResCreatePaymentMethodToPaymentMethod(resCreatePaymentMethod: IResCreatePaymentMethod): IPaymentMethod {
  //   const cardDto: ICard | null = resCreatePaymentMethod.cardDto ? resCreatePaymentMethod.cardDto : null;
  //   const bankAccountDto: IBankAccount | null = resCreatePaymentMethod.bankAccountDto ? resCreatePaymentMethod.bankAccountDto : null;
  //   const paymentMethod: IPaymentMethod = {
  //     expiryDate: cardDto ? cardDto?.expiryDate : '', // "0330"
  //     last4: cardDto ? cardDto?.last4 : '', // "4242"
  //     method: resCreatePaymentMethod.id, // "pm_1L14LsBIMZTJKM1cmR0q3ViF"
  //     name: cardDto ? cardDto?.brand : '', // "visa" || "STRIPE TEST BANK"
  //     type: cardDto ? 'CARD' : 'ACH', // from 2 version 'ACH' | 'CARD';
  //     fromPlaid: !cardDto, // если true, то этот метод получен из попап-Plaid
  //   };
  //   return paymentMethod;
  // }

  // === TIMEZONE ========================================================
  // =================================================================================
  // return 'CDT' || 'Central Daylight Time' || 'America/Chicago' || '+8' // в зависимости от key, который передаём, такое значение и возвращаем
  // timezoneTo - в какую таймзону перевести. если null, то не надо переводить
  getTimezoneString(timezone: ITimezone, timezoneTo: TTypeTimezone | null, key: keyof ITimezone, key2?: keyof ITimezone): string {
    if (!timezone) {
      console.log('getTimezoneString() :', timezone);
      return '';
    }

    let findTimezone: ITimezone | undefined;

    // было так до 8.03.23
    // if (timezoneTo === 'Utc') {
    //   findTimezone = this.deepClone(this.utcTimezone);
    // } else if (timezoneTo === 'Game') {
    //   findTimezone = fullListTimezone.find((el: ITimezone) => el.id === timezone.id);
    //   if (!findTimezone) findTimezone = fullListTimezone.find((el: ITimezone) => el.value === timezone.value); // первую попавшуюся из массива
    // } else if (timezoneTo === 'User') {
    //   const idTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || ''; // 'America/Chicago'
    //   findTimezone = fullListTimezone.find((el: ITimezone) => el.id === idTimezone);
    //   if (!findTimezone) findTimezone = fullListTimezone.find((el: ITimezone) => el.value === +this.userTimezoneValue); // первую попавшуюся из массива
    // } else { // null
    //   findTimezone = fullListTimezone.find((el: ITimezone) => el.id === timezone.id);
    // }

    if (timezoneTo === 'Utc') {
      findTimezone = this.deepClone(this.utcTimezone);
    } else if (timezoneTo === 'Game') { // здесь таймзона уже приходит нужная из пайпа. Поэтому переводить не надо
      findTimezone = this.findTimezoneFromList(timezone);
    } else if (timezoneTo === 'User') {
      // findTimezone = this.findTimezoneFromList({id: this.idUserTimezone, value: +this.userTimezoneValue, abbrev: '', name: ''})
      findTimezone = this.userTimezone;
    } else { // null // не надо переводить таймзону
      findTimezone = this.findTimezoneFromList(timezone);
    }

    if (!findTimezone) {
      console.log('TIMEZONE not found in fullListTimezone :', findTimezone);
      return '';
    }
    const editedTimezone = this.editTimezone(findTimezone)!;
    return editedTimezone[key].toString() + (key2 ? (' ' + editedTimezone[key2].toString()) : '');
  }

  findTimezoneFromList(timezone: Partial<ITimezone>): ITimezone {
    // let findTimezone = shortListTimezone.find((el: ITimezone) => el.id === timezone.id || el.value === timezone.value);
    // if (!findTimezone) findTimezone = fullListTimezone.find((el: ITimezone) => el.id === timezone.id || el.value === timezone.value); // первую попавшуюся из массива
    // return findTimezone!;
    return UtilsService.findTimezoneFromList(timezone);
  }

  // !!! с сервера приходит в таком формате "30 Nov 2023 12:00 AM PST" . Этот метод возвращает таймзону (например PST)
  getTimezoneFromDateFromServer(dateFromServer: string): string {
    if (!dateFromServer) return dateFromServer;
    const arr = dateFromServer.split(' ');
    const timezone = arr[arr.length - 1];
    return timezone;
  }

  // возвращается первая попавшаяся таймзона из списка fullListTimezone. Потому что кроме '+8' у меня других значений нет.
  get userTimezone(): ITimezone {
    // const userTimezoneValue = this.getUserTimezoneValue(); // '+8'
    // было так до 8.03.23
    // return this.editTimezone({
    //   abbrev: fullListTimezone.find((el: ITimezone) => el.value === +this.userTimezoneValue)!.abbrev, // 'CDT'
    //   id: fullListTimezone.find((el: ITimezone) => el.value === +this.userTimezoneValue)!.id, // 'America/Chicago'
    //   name: fullListTimezone.find((el: ITimezone) => el.value === +this.userTimezoneValue)!.name, // 'Central Daylight Time'
    //   value: fullListTimezone.find((el: ITimezone) => el.value === +this.userTimezoneValue)!.value, // '+8'
    // });
    // let findTimezone = this.findTimezoneFromList({id: this.idUserTimezone, value: +this.userTimezoneValue, abbrev: '', name: ''})
    return this.findTimezoneFromList({ id: this.idUserTimezone, value: +this.userTimezoneValue });
  }

  // return например '+8'
  get userTimezoneValue(): string {
    return (new Date().getTimezoneOffset() / -60).toString();
  }

  get idUserTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  // return например '+8'
  getGameTimezoneValue(timezoneGame: ITimezone): string {
    return timezoneGame.value.toString();
  }

  convertGoogleTimezoneToITimezone(responseTimezone: IResponseTimezone): ITimezone {
    // было так до 8.03.23
    // return this.editTimezone({
    //   abbrev: fullListTimezone.find((el: ITimezone) => el.id === responseTimezone.timeZoneId)!.abbrev, // 'CDT'
    //   id: responseTimezone.timeZoneId, // 'America/Chicago'
    //   name: responseTimezone.timeZoneName, // 'Central Daylight Time'
    //   value: fullListTimezone.find((el: ITimezone) => el.id === responseTimezone.timeZoneId)!.value, // '+8'
    // });
    const findTimezone = this.findTimezoneFromList({ id: responseTimezone.timeZoneId });
    // if (responseTimezone.timeZoneId) findTimezone.id = responseTimezone.timeZoneId
    // if (responseTimezone.timeZoneName) findTimezone.name = responseTimezone.timeZoneName
    return findTimezone;
  }


  // === DATE =====================================================================
  // =================================================================================
  // convert Date (например => UTC to User timezone)
  // если TTypeTimezone === "Game" передаем, то обязательно надо передать timezone?: ITimezone
  // date === '2022-05-17T04:48:07.062344'
  convertDate(dateOld: string, timezoneFrom: TTypeTimezone, timezoneTo: TTypeTimezone, timezone: ITimezone | 'UTC' = 'UTC'): Date {
    if (!timezoneFrom || !timezoneTo) return new Date(dateOld);
    const date = typeof dateOld == 'object' ? dateOld : this.convertDateForSafari(dateOld);

    const timezone111 = timezone === 'UTC' ? this.utcTimezone : timezone;
    const type = timezoneFrom + 'To' + timezoneTo;
    if (type === 'UtcToUser') return this.convertDateUtcToUser(date);
    if (type === 'UserToUtc') return this.convertDateUserToUtc(date);
    if (type === 'UtcToGame') return this.convertDateUtcToGame(date, timezone111!);
    if (type === 'GameToUtc') return this.convertDateGameToUtc(date, timezone111!);
    else return new Date(date); // если timezoneFrom === timezoneTo, то не надо конвертировать дату
  }

  // дата с сервера приходит так '2021-12-13 11:56:28.045569' || '2021-02-16T17:00'
  // safari выдает ошибку new Date('2021-12-13 11:56:28.045569') без буквы 'T' и без 'Z'
  // буква 'Z' прибавляет зону пользователя (типа переводит из UTC в зону юзера)
  // это ломало всю логику. Поэтому теперь добавляю эти буквы и вычитаю зону пользователя
  convertDateForSafari(str: string): Date {
    let res: any;
    if (str.length < 11) { // '2022-09-01'
      res = this.convertDateUserToUtc(str + 'T00:00:00Z');
    } else if (str.length < 30 && str.length > 11) { // '2022-09-01 00:00:00.000000'.length == 27
      res = str.replace(' ', 'T'); // '2022-09-01T00:00:00.000000'
      if (!str.includes('Z')) res = res + 'Z';
      res = this.convertDateUserToUtc(res);
    } else { // 'Thu Sep 08 2022 18:34:06 GMT+0800 (Иркутск, стандартное время)'.length > 50
      res = str;
    }
    return new Date(Date.parse(res));
  }

  // обрезать Z на конце даты
  cutSimbolZFromDateForSendToServer(str: string): string {
    if (str.endsWith('Z')) {
      return str.slice(0, -1);
    } else {
      return str;
    }
  }

  // convertDateUtcToUser(date: Date | string): Date {
  convertDateUtcToUser(date: Date): Date {
    return new Date(new Date(date).getTime() + (Number(this.userTimezoneValue) * this.oneHour));
  }

  convertDateUserToUtc(date: Date | string): Date {
    return new Date(new Date(date).getTime() - (Number(this.userTimezoneValue) * this.oneHour));
  }

  convertDateUtcToGame(date: Date | string, timezone: ITimezone): Date {
    return new Date(new Date(date).getTime() + (Number(this.getGameTimezoneValue(timezone)) * this.oneHour));
  }

  convertDateGameToUtc(date: Date | string, timezone: ITimezone): Date {
    return new Date(new Date(date).getTime() - (Number(this.getGameTimezoneValue(timezone)) * this.oneHour));
  }

  getDatePlusHours(date: Date, hours: number): Date {
    return new Date(new Date(date).getTime() + (hours * this.oneHour));
  }

  // переводим в UTC => для drop-date-range // return "2022-07-12" || "2022-07-11T12:41:54"
  // getDateFrom(format: string, date: Date = this.todayDate): string {
  //   const resultDateString = this.convertDate(this.datesS.formatDate(format, date), 'User', 'Utc');
  //   return this.datesS.formatDate(format, resultDateString);
  // }

  getDateTo(format: string, date: Date = this.todayDate): string {
    const date111 = this.getDatePlusHours(date, 23);
    const resultDateString = this.convertDate(this.datesS.formatDate(format, date111), 'User', 'Utc');
    return this.datesS.formatDate(format, resultDateString);
  }

  getUtcDate(): Date {
    let date = new Date();
    let now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    return new Date(now_utc);
  }

  checkIsToday(date: string | Date): boolean {
    let d: Date | string;
    if (typeof date === 'string') {
      d = new Date(date);
    } else if (date) {
      // d = date;
      d = new Date(date);
    } else {
      d = new Date();
    }
    const today = new Date();
    return d.getDate() === today.getDate() && d.getMonth() === today.getMonth() && d.getFullYear() === today.getFullYear();
  }

  dateToLocale(date?: Date): string { // 8:00 PM
    const time = new Date(date + 'Z').toLocaleString()?.split(', ')[1];
    const minutes = time?.split(':')[1];
    let hours = Number(time?.split(':')[0]);
    hours = ((hours + 11) % 12 + 1);
    const typeTime = hours <= 12 ? 'PM' : 'AM';
    return `${hours}:${minutes} ${typeTime}`;
  }

  getDateLocale(dateStr: string): Date {
    return new Date(new Date(dateStr).toISOString().replace('Z', ''));
  }

  // Example: return '20 December 2021'
  formatDateToString(dat: Date | string): string { // 2021-12-21 || Tue Nov 23 2021 00:00:00 GMT-0900 (Аляска, стандартное время)
    // const date = (typeof dat === 'string') ? new Date(dat) : dat;
    const date = (typeof dat === 'string') ? new Date(new Date(dat).toISOString().replace('Z', '')) : dat;
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  // !!! dateFromServer == 2023-08-17
  getAgeByDateOfBirth(dateFromServer: string): number | string {
    return UtilsService.getAgeByDateOfBirth(dateFromServer);
  }

  getShortState(state?: string): string {
    if (!state) return '';
    let shortState = '';
    if (state in shortStates) {
      // @ts-ignore
      shortState = shortStates[state];
    } else {
      shortState = state;
    }
    return shortState;
  }

  // первая буква кадлого слова с большой буквы
  capitalize(value: string): string {
    value = value?.toLowerCase();
    return value.replace(/( |^)[а-яёa-z]/g, (el) => el.toUpperCase());
  }


  // === STRING ======================================================
  // =================================================================================
  // !!! addDashInsteadOfSpace = если надо добавить тире вместо пробела
  strCase(string: any, type: TypeStringCase, addDashInsteadOfSpace = false): string {
    // if (!string?.trim()) return '';
    // string = string?.replace('_', ' ')?.replace('-', ' ');
    // const result: string = this.lodashS.getStrCase(string, type);
    // if (addDashInsteadOfSpace) return result?.replace(' ', '-');
    // else return result;
    return UtilsService.strCase(string, type, addDashInsteadOfSpace);
  }

  strCase_forArray(arrString: Array<string>, type: TypeStringCase): Array<string> {
    if (!arrString?.length) return arrString;
    return arrString.map((el) => {
      return this.strCase(el, type);
    });
  }

  // является ли строка емайлом
  isCorrectEmail(str?: string): boolean {
    if (!str) return false;
    return !!str.match(patternEmail);
  }

  //   delete
  toTitleCase(str: string): string {
    if (!str) return '';
    return _.capitalize(str);
    // return str.replace(
    //   /\w\S*/g,
    //   function(txt) {
    //     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    //   },
    // );
  }

  toTitleCaseForObj(obj: any): any {
    let res = { ...obj };
    for (const k in res) {
      if (typeof res[k] === 'string') res[k] = this.toTitleCase(res[k]);
    }
    return res;
  }

  includes(arr?: Array<any>, str?: any): boolean { // arr == Array<string | TReportStatus | TGameStatus | TPayoutStatus> // str == string
    // 1 вариант: должен равняться одному из массива
    // DUE => (status == "DUE") || (status == "DRAFT") => true
    // OPEN => (status == "DUE") || (status == "DRAFT") => false
    // return ["DUE","DRAFT"].includes(DUE)  => true
    // return ["DUE","DRAFT"].includes(OPEN) => false
    // 2 вариант: НЕ должен равняться НИ одному из массива
    // DUE => (status != "DUE") && (status != "DRAFT") => false
    // OPEN => (status != "DUE") && (status != "DRAFT") => true
    // return !["DUE","DRAFT"].includes(DUE)  => false
    // return !["DUE","DRAFT"].includes(OPEN) => true
    if (!arr?.length || !str || typeof str != 'string') return false;
    return !!arr?.map(el => el?.toLowerCase()).includes(str.toLowerCase());
  }

  cutStringBySymbol(str: string, symbol: string): Array<string> {
    const idx = str.indexOf(symbol);
    const startStr = str.substring(0, idx);
    const endStr = str.substring(idx).replace(symbol, '');
    return [startStr, endStr];
  }

  // взять только первое и последнее слово из строки. Тоесть всё что посередине - убрать
  getStartEndWordFromString(str: string): string {
    if (!str) return str;
    const arr = str.split(' ');
    return [arr[0], arr[arr.length - 1]].join(' ');
  }

  // взять только первую и последнюю букву из строки. Тоесть всё что посередине - убрать
  getStartEndLetterFromString(str: string): string {
    if (!str) return str;
    return str[0] + str[str.length - 1];
  }

  // оставить только цифры. Все буквы и символы удаляются
  getOnlyNumbersFromStr(str: string): string {
    if (!str) return str;
    return str.replace(new RegExp(/[^\d]/, 'g'), '');
  }

  unmaskAmount(str?: string | number | null): number {
    if (!str) return 0;
    let initialValue = str.toString()?.replace(/[^0-9.]*/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');
    if (initialValue === '') initialValue = '0';
    if (initialValue[0] === '.') initialValue = '0' + initialValue;
    return +initialValue;
  }

  // amount == 2500 // return $2500
  dollarPlusAmount(amount?: string | number): string {
    // console.log('dollarPlusAmount :', typeof amount, amount)
    // if (!amount) return '';
    // if (typeof amount == 'number' && !amount) return '';
    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    // return formatter.format(+amount);
    if (amount) {
      // return formatter.format(+amount);
      return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(Number(amount));
    } else {
      // return formatter.format(0);
      return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(0);
    }
  }

  removeDollar(amount?: string | number): string {
    if (!amount) return '0';
    return amount?.toString()?.replace('$', '');
  }

  // запретить вторую точку в строке . Это для amount
  banTwoDots(valueStr: string): string {
    let existDot = false;
    let yyy = valueStr?.split('').map((el) => {
      if (existDot && el === '.') return '';
      if (el === '.') existDot = true;
      return el;
    });
    let newStr = yyy.filter(el => !!el)?.join('');
    return newStr;
  }

  // строку в число преобразовать
  amountToNumber(amount: string | number | null): string | number | null {
    if (amount && +amount && typeof +amount == 'number') return +amount;
    // return 0;
    return amount;
  }

  // !!! пока не удалять example Group-assignor || Group assignor => return GROUP_ASSIGNOR
  prepareConstStr(str: string, type: 'toUpper' | 'toLower'): string {
    if (!str?.trim()) return '';
    if (type == 'toUpper') {
      return str?.toUpperCase()?.replace('-', ' ')?.replace('/', ' ')?.replaceAll(' ', '_');
    } else if (type == 'toLower') {
      return str?.trim()?.toLowerCase();
    } else {
      return str?.trim();
    }
  }

  getAgeGenderLevel(data?: ClassGame | TransferModel | ClassTransfer, type?: 'ClassGame' | 'TransferModel' | 'ClassTransfer'): string {
    if (!data) return '';
    let result: string = '';
    if (type == 'ClassGame') {
      let game: ClassGame = data as ClassGame;
      const gameAgeDescription = game?.ageGroup?.gameAgeDescription || 'TBD';
      const gender = this.getFirstLetter(game?.gender) || 'TBD';
      const level = this.getLevelValue(game?.levels?.level, 1);
      // result = `${gameAgeDescription} - ${gender} - ${level}`;
      result = `${gameAgeDescription} - ${gender}`;
      // result = result + (level && level !== 'TBD' ? ` - ${level}` : ''); // !!! Миша сказал если левела нет или если левел равен TBD, то не показывать его
      result = result + (level ? ` - ${level}` : ''); // !!! Миша сказал если левела нет или если левел равен TBD, то не показывать его
    }
    if (type == 'TransferModel' || type == 'ClassTransfer') {
      let transferModel: TransferModel = data as TransferModel;
      const gameAgeDescription = transferModel?.gameAgeDescription || 'TBD';
      const gender = this.getFirstLetter(transferModel?.gender) || 'TBD';
      const level = this.getLevelValue(transferModel?.level, 1);
      // result = `${gameAgeDescription} - ${gender} - ${level}`;
      result = `${gameAgeDescription} - ${gender}`;
      // result = result + (level && level !== 'TBD' ? ` - ${level}` : ''); // !!! Миша сказал если левела нет или если левел равен TBD, то не показывать его
      result = result + (level ? ` - ${level}` : ''); // !!! Миша сказал если левела нет или если левел равен TBD, то не показывать его
    }
    return result;
  }

  // variant 1. for list    if (N/A || ALL || TBD || '') => return ''
  // variant 2. for detail  if (N/A || ALL || TBD || '') => return N/A
  getLevelValue(level: string | undefined, variant: 1 | 2): string | TypeNA {
    if (!level || level == const_NA || level == 'ALL' || level == 'TBD' || level == '') {
      const result: '' | TypeNA = variant == 1 ? '' : const_NA;
      return result;
    } else {
      return level;
    }
  }

  // !!! если null то показывать TBD. // если ALL то N/A.
  // !!! если hiddenIfNull==true && ('TBD' || N/A) => return ''
  // !!! addDash => если надо добавить тире перед/после
  // getLevelValue(level?: string, hiddenIfNull = false, addDash?: 'before' | 'after'): string {
  //   const result = level?.includes('ALL') ? 'N/A' : (level ? level : 'TBD');
  //   if (hiddenIfNull && (result?.includes('ALL') || result?.includes('N/A'))) return '';
  //   if (addDash == 'before') return result ? ' - ' + result : result;
  //   if (addDash == 'after') return result ? result + ' - ' : result;
  //   return result;
  // }

  // !!! example => 'Monica Jon'|getFirstLetter => 'MJ'
  // !!! amountLetters сколько букв взять из слова
  getFirstLetter(value?: string, amountLetters = 1, forTest?: string): string {
    if (!value) return '';
    let result = '';

    value?.trim()?.split(' ')?.filter(Boolean)?.forEach((el: string, i: number) => {
      if (i > (amountLetters - 1)) return; // чтобы максимум 2 буквы показывать в иконке
      result += el[0]?.toUpperCase();
    });
    return result;
  }

  // andrei delete later
  getUserName(user?: ClassUser): string {
    if (!user) return '-';
    if (user.firstName || user.secondName) {
      return (user.firstName || '') + ' ' + (user.secondName || '');
    } else {
      return user?.email || '-';
    }
  }

  // с сервера поступает 1992-11-19 => return MM/dd/YYY // 1974-07-10 => 07/10/1974  // Y M D => M D Y
  getFormatDateOfBirth(dateOfBirth?: string): string {
    if (!dateOfBirth) return '';
    const arr = dateOfBirth?.split('-');
    const result: string = arr[1] + '/' + arr[2] + '/' + arr[0];
    return result;
  }

  // === OBJECT ======================================================
  // =================================================================================
  removePlusFromPhone(user: ClassUser): ClassUser {
    if (user.emergencyPhoneNumber) user.emergencyPhoneNumber = user.emergencyPhoneNumber.replaceAll('+', '');
    if (user.phone) user.phone = user.phone.replaceAll('+', '');
    return user;
  }

  // object == {cardNumber: false, cardExpiry: false, name: false, cardCvc: false}
  // проверка всех полей в объекте // если valid => вернется true
  checkValidAllFieldsFromObject(object: any): boolean {
    let valid = true;
    Object.values(object)?.forEach(el => {
      if (!el) valid = false;
    });
    return valid;
  }

  // object == {cardNumber: 'aaa', cardExpiry: 'bbb', name: '', cardCvc: 'ddd'}
  // если есть текст, то вернется массив => например ['aaa', 'bbb', 'ddd']
  getArrStringFromObject(object: any): Array<string> {
    const arrString: Array<string> = [];
    Object.values(object)?.forEach((el) => {
      if (el) arrString.push(el as string);
    });
    return arrString;
  }

  // mergeObjects(): void {
  //   Object.entries(this.filters).forEach(el => {
  //     if (typeof el[1] === 'object' && el[1]?.length) { // array
  //       el[1].push(...this.tempFilters[el[0]])
  //       // this.filters[el[0]].push(...el[1])
  //     } else if (typeof el[1] === 'object' && !el[1]?.length) { // object
  //
  //     } else {
  //       el[1] = this.tempFilters[el[0]]
  //     }
  //   })
  // }

  removeTrueKeys(object: any): any {
    if (!object) return false;
    const result = this.deepClone(object);
    for (const key of Object.keys(result)) {
      if (result[key]) delete result[key];
    }
    return result;
  }

  removeFalseKeys(object: any): any {
    if (!object) return false;
    const result = this.deepClone(object);
    for (const key of Object.keys(result)) {
      if (!result[key]) delete result[key];
    }
    return result;
  }

  // Есть ли хотя бы один элемент true ?
  leastOneElementTrue(object: any): boolean {
    const result = this.removeFalseKeys(object);
    return !!Object.values(result)?.length;
  }

  // Есть ли хотя бы один элемент false ?
  leastOneElementFalse(object: any): boolean {
    const result = this.removeTrueKeys(object);
    return !!Object.values(result)?.length;
  }

  // Все элементы true ?
  allElementTrue(object: any): boolean {
    return !this.leastOneElementFalse(object);
  }

  // Все элементы false ?
  allElementFalse(object: any): boolean {
    return !this.leastOneElementTrue(object);
  }

  // !!! если все значения у объектов одинаковы, то возвращается true
  // !!! arrKeys если передал массив ключей, то нужно сравнивать только по эти ключам из массива arrKeys
  compareTwoObjects<T extends object, K extends keyof T>(obj_1: T, obj_2: T, arrKeys?: Array<K>): boolean {
    // return JSON.stringify(obj_1) === JSON.stringify(obj_2);
    // return _.isEqual(obj_1, obj_2);
    let result = false;
    if (arrKeys?.length) {
      const obj1_onlyPropertiesNeed = UtilsService.getObjectByKeys(obj_1, arrKeys); // !!! объект только с нуэными property по ключам из массива arrKeys
      const obj2_onlyPropertiesNeed = UtilsService.getObjectByKeys(obj_2, arrKeys); // !!! объект только с нуэными property по ключам из массива arrKeys
      result = _.isEqual(obj1_onlyPropertiesNeed, obj2_onlyPropertiesNeed);
    } else {
      result = _.isEqual(obj_1, obj_2);
    }
    return result;
  }

  checkAllValuesTrueForObj(object: any): boolean { // !!! only for object
    if (!object) return false;
    if (typeof object !== 'object') return false;
    if (!Object.values(object)?.length) return false;
    let result: boolean = true;
    Object.values(object)?.forEach((el) => {
      if (!el) result = false;
    });
    return result;
  }


  // === ARRAY === все операции с массивами ================================
  // =================================================================================
  //   delete
  getArrayTrueElem(arr: Array<any>): Array<any> {
    // return arr.filter(Boolean);
    return _.compact(arr);
  }

  // поменять местами два элемента массива
  // arrIdx - это индексы. если не передал то по дефолту arrIdx == [0, 1] поменяет местами первые 2 элемента в массиве
  swapTwoElementsInArray<T>(arr: Array<T>, arrIdx: Array<number> = [0, 1]): Array<T> {
    return [arr[arrIdx[0]], arr[arrIdx[1]]] = [arr[arrIdx[1]], arr[arrIdx[0]]];
  }

  // перенести элемент массива в самое начало (isStart==true) или в конец (isStart==false)
  // elem == элемент, который надо перенести
  moveElemInArray<T>(arr: Array<T>, elem: any, isStart = true): Array<T> {
    const idxCurrentElem = arr.findIndex((el: any) => el.id === elem.id);
    let result = this.deepClone(arr);
    if (isStart) {
      result.unshift(...result.splice(idxCurrentElem, 1));
    } else {
      result.push(...result.splice(idxCurrentElem, 1));
    }
    return result;
  }

  // разбить массив на подмассивы типа [[1,2,3],[4,5,6],[7,8]]. Size == количество элементов в подмассивах
  splitAnArrayIntoSubarrays(arr: Array<any>, size: number): Array<any> {
    let subarray = [];
    for (let i = 0; i < Math.ceil(arr.length / size); i++) {
      subarray[i] = arr.slice((i * size), (i * size) + size);
    }
    return subarray;
  }

  // Получить массив уникальных данных (примитивных)
  getArrayUniquePrimitive(arr: Array<any>): Array<any> {
    return [...new Set(arr)];
  }

  // Получить массив уникальных объектов // Парсим в строку и обратно в объект
  getArrayUniqueObjects<T extends object>(arr: Array<T>): Array<T> {
    if (!arr?.length) return arr;
    const mySet = new Set();
    arr.forEach(obj => mySet.add(JSON.stringify(obj)));
    return [...mySet].map((obj: any) => JSON.parse(obj));
  }

  // Получить массив уникальных объектов по ID проверка происходит
  getArrayUniqueObjectsByID<T>(arr: Array<T & { id?: string }>): Array<T> {
    if (!arr?.length) return arr;
    return arr.filter((el, idx, self) =>
      self.findIndex(obj => obj.id === el.id) === idx,
    );
  }

  getSelectItems<T>(arrContent?: Array<T & IForTable>): Array<T & IForTable> {
    if (!arrContent?.length) return [];
    let selectedItems: Array<T & IForTable> = [];
    arrContent.forEach(el => {
      if (el?.isSelect) selectedItems.push(el);
    });
    return selectedItems;
  }

  isSelectAllItems<T>(arrContent?: Array<T & IForTable>): boolean {
    if (!arrContent?.length) return false;
    // let selectedItems = 0;
    // arrContent.forEach(el => {
    //   if (el?.isSelect) selectedItems += 1;
    // });
    // return selectedItems === arrContent?.length;
    return this.getSelectItems(arrContent)?.length === arrContent?.length;
  }


  // Получить массив уникальных объектов по KEY проверка происходит
  // getArrayUniqueObjectsByKey(arr: Array<any>): Array<any> {
  //   if (!arr?.length) return arr;
  //   return arr.filter((el, idx, self) =>
  //     self.findIndex(obj => obj.id === el.id) === idx,
  //   );
  // }

  // передаем массив объектов. Получаем объект по ключу или false если не найдено
  // getElemFromArray(arr: Array<any>, key: string): object | false {
  // передаем массив объектов. Получаем объект по ключу или по ключу+значению
  getElemFromArray(arr: Array<any>, key: string, value?: any): any {
    if (!arr) return null;
    if (value) {
      let result;
      arr.forEach(el => {
        if (el[key] === value) result = el;
      });
      return result;
    } else {
      return arr.find(el => el[key]);
    }
  }

  // передаём массив idsSelected и массив объектов
  // возвращаем массив объектов, в которых есть id из массива idsSelected (тоесть возвращается массив только выбраных объектов)
  getSelectedObj(idsSelected: Array<string>, arrObj: Array<any>): Array<any> {
    const resultArrObj: Array<any> = [];

    arrObj.forEach(el => {
      if (idsSelected.includes(el.id)) resultArrObj.push(el);
    });

    return resultArrObj;
  }

  // изменить значения(value) по ключу(key)
  // если idElem есть, то только у одного этого элемента заменить значение. Иначе у всех елементов
  // возвращаем массив с измененными значениями
  // changeValueOfKeyInArray(arrObj: Array<any>, keys: Array<string>, values: Array<any>, idElem?: string): Array<any> {
  changeValueOfKeyInArray(arrObj: Array<any>, key: string, value: boolean | string | number, idElem?: string): Array<any> {
    const resultArrObj: Array<any> = this.deepClone(arrObj);

    // resultArrObj.forEach(el => {
    //   keys.forEach((key: string, i: number) => {
    //     if (idElem) {
    //       if (el.id === idElem) {
    //         el[key] = values[i];
    //       }
    //     } else {
    //       el[key] = values[i]
    //     }
    //   })
    // })

    resultArrObj.forEach(el => {
      if (idElem) {
        if (el.id === idElem) el[key] = value;
      } else {
        el[key] = value;
      }
    });

    return resultArrObj;
  }

  // заменить объекты в массиве. Поиск происходит по ID
  replaceItemsInArrayById<T extends IObject>(arr: Array<T>, arrObjects: Array<T>): Array<T> {
    const result = [...arr];
    arrObjects.forEach((obj) => {
      const index = result.findIndex(el => el?.id == obj?.id);
      if (index != -1) result.splice(index, 1, obj);
    });
    return result;
  }

  // заменить объект в массиве. Поиск происходит по ID
  replaceElemArrayById(arr: Array<any>, newObj: any): Array<any> {
    if (!arr?.length || !newObj?.id) return arr;
    let idx = arr?.findIndex(el => el.id == newObj.id);
    idx = idx >= 0 ? idx : 0;
    const result = [...arr];
    result[idx] = newObj;
    return result;
  }

  // заменить объект в массиве. Поиск происходит по Idx
  replaceElemArrayByIdx(arr: Array<any>, newObj: any, idx: number): Array<any> {
    if (!arr?.length || !newObj) return arr;
    // const idx = arr.findIndex(el => el.id == newObj.id);
    const result = [...arr];
    result[idx] = newObj;
    return result;
  }

  removeElemByIdx(arr: Array<any>, idx: number): Array<any> {
    return arr?.filter((el, i) => i != idx);
  }

  removeObjWithoutIdFromArr(arr: Array<any>): Array<any> {
    return arr.filter(el => !!el?.id);
  }

  // замена объекта в массиве по индексу
  replaceObjectByIdx(arr: Array<any>, obj: any, idx: number): Array<any> {
    arr[idx] = obj;
    return arr;
  }

  // добавление нового объекта в начало списка
  addNewObject(arr: Array<any>, obj: any): Array<any> {
    arr.unshift(obj);
    return arr;
  }

  // удаление объекта по индексу
  deleteObjectByIdx(arr: Array<any>, idx: number): Array<any> {
    return arr?.filter((el, i) => idx !== i);
  }

  // удаление объекта по ID
  deleteObjectById(arr: Array<any>, id: string): Array<any> {
    return arr?.filter((el) => id !== el?.id);
  }


  getArrDropItem(content: Array<any>, forTest: string): Array<any> {
    if (content?.length) return content.map((el: any) => ({ ...el, ...this.getDropItem(el, forTest) }));
    return content;
  }

  findDropItem(array?: Array<any>, item?: any): any {
    let result: any = null;
    array?.forEach(el => {
      if (el?.id && (el?.id == item?.id || el?.id == item?.value)) result = el;
      if (el?.value && (el?.value == item?.value || el?.value == item?.id)) result = el;
    });
    return result;
  }


  // getDropItemForString(str?: string, onlyDropItem = false): IDropItem {
  //   if (!str) return { name: '', title: '', value: '', id: '' };
  //   const result: IDropItem = { name: str, title: str, value: str, id: str };
  //   return this.getDropItem(result, onlyDropItem);
  // }

  // если onlyDropItem==true возвращается только IDrop
  // getDropItem(elem: any, onlyDrop = false): IDrop {
  getDropItem(elem: any, forTest: string): ClassDrop {
    if (!elem) return {}; // gameAgeDescription
    if (typeof elem === 'string') return getDropFromString(elem, forTest);
    const titleCase = elem.name || elem.title || elem.gameAgeDescription || elem.teamName || elem.level;
    return new ClassDrop({
      ...elem, titleCase,
      upperCase: elem.id || titleCase?.toUpperCase(),
    });
  }

  // если onlyDropItem==false другие поля объекта elem остаются не тронутыми
  // если onlyDropItem==true возвращается только IDropItem
  // например для ITeam используется name, а для дивизиона title
  // getDropItemForSend(elem: IDropItem, type: 'division' | 'team', onlyDropItem = false): IDropItem {
  //   if (!elem) return elem;
  //   const result: IDropItem = this.getDropItem(elem, onlyDropItem);
  //   if (type == 'division') delete result.name;
  //   if (type == 'team') delete result.title;
  //   delete result.value;
  //   return result;
  // }

  // sortingList(list?: Array<IDropItem>): Array<IDropItem | any> | undefined {
  //   if (!list?.length) return list;
  //   const tmpArr = list.map((el: IDropItem) => (this.getDropItem(el)));
  //   return tmpArr?.sort((el_1: any, el_2: any) => el_1.name.localeCompare(el_2.name));
  // }

  // === SELECT ITEM ==============================================
  // =================================================================================
  // возвращаем массив id выбраных
  selectItemAll(arrObj: Array<any>, selectAll: boolean): Array<string> {
    if (!arrObj) return [];
    let idsSelected: Array<string> = [];
    if (selectAll) arrObj.forEach((el) => idsSelected.push(el.id!));
    return idsSelected;
  }

  // если есть хоть один выбраный, то возвращается true
  checkSelectedIds(idsSelected: Array<string>): boolean {
    return !!idsSelected?.length;
  }

  // передаем объект репорта/игры/юзера и проверяем есть ли он в массиве выбраных idsSelected
  checkSelectedItem(obj: any, idsSelected: Array<string>): boolean {
    return idsSelected.includes(obj.id);
  }

  // возвращаем массив выбраных елементов (тоесть в существующий массив idsSelected добавляем ещё один выбраный или убираем из массива если выключили его)
  selectItem(obj: { idItem: string, isSelect: boolean }, idsSelected: Array<string>): Array<string> {
    // if (obj.isSelect) {
    //   this.idsReportSelected.push(obj.idReport);
    //   this.idsReportSelected = [...new Set(this.idsReportSelected)];
    // } else {
    //   this.idsReportSelected = this.idsReportSelected.filter(el => el !== obj.idReport);
    // }

    // let resultIdsSelected: Array<string> = idsSelected;
    if (obj.isSelect) {
      idsSelected.push(obj.idItem);
      idsSelected = this.getArrayUniquePrimitive(idsSelected); // return [...new Set(arr)]
    } else {
      idsSelected = idsSelected.filter(el => el !== obj.idItem);
    }
    return idsSelected;
  }

  // !!! если хотя бы 1 элемент в массиве isSelect==true, то вернется true
  isSelect<T>(array?: Array<T & IForTable>): boolean { // { checked?: boolean }
    return !!array?.some(el => el.isSelect); // el.checked
  }

  // === CHECKED ITEM ================================================================
  // =================================================================================
  // передаем массив например юзеров и массив ид юзеров и помечаем isSelect если в массиве идшников есть тако юзер
  // checkedItem(arr: Array<any>, ids: Array<string>): Array<any> {
  //   arr.forEach(el => {
  //     // if (ids.includes(el.id)) el.isSelect = true;
  //     el.isSelect = ids.includes(el.id);
  //   });
  //   return arr;
  // }

  // передаем массив например юзеров и массив выбраных selected юзеров и помечаем isSelect если в массиве selected есть тако юзер
  // checkedItemObj(arr: Array<any>, selected: Array<any>): Array<any> {
  //   arr.forEach(el => {
  //     el.isSelect = !!selected.find(item => item.id === el.id);
  //   });
  //   return arr;
  // }

  // найти пересечение 2х массивов
  arrIntersection(arr1: Array<string | number>, arr2: Array<string | number>): Array<string | number> {
    const result = [];
    for (let i = 0; i < arr1.length; i += 1) {
      if (arr2.includes(arr1[i])) result.push(arr1[i]);
    }
    return result;
  }


  // === compareWith ===========================================================
  // =================================================================================
  // для [compareWith]='compareObjects'
  compareObjects(obj_1: any, obj_2: any): boolean {
    if (obj_1?.id || obj_2?.id) return obj_1?.id === obj_2?.id;
    return obj_1?.value === obj_2?.value;
  }

  // NEW for DropdownComponent для [compareWith]='compareObjects'
  compareObj<T extends ClassDrop & { id: string }>(obj_1: T, obj_2: T): boolean {
    if (obj_1?.titleCase === defaultNameMatOption || obj_2?.titleCase === defaultNameMatOption) {
      return obj_1?.titleCase === obj_2?.titleCase; // !!! FOR values 'ALL'
    }
    if (obj_1?.id || obj_2?.id) return obj_1?.id === obj_2?.id;
    if (obj_1?.upperCase || obj_2?.upperCase) return obj_1?.upperCase === obj_2?.upperCase;
    return obj_1?.titleCase === obj_2?.titleCase;
  }

  compareObjectsById(o1: any, o2: any): boolean {
    // console.log('compareObjectsById  :', o1, o2)
    // console.log('compareObjectsById 2 :',  )
    // console.error('compareObjectsById :',  o1?.competitionName, '=>', o2?.competitionName, o1.id == o2.id) // competitionName
    // return true
    // return false

    // if (!o1?.id || !o2?.id) return false;
    // return o1?.id !== o2?.id;
    return o1?.id === o2?.id;
  }

  // для [compareWith]='compareString'
  compareString(o1: string, o2: string): boolean {
    // if (!o1 || !o2) return false;
    // return o1.toLowerCase() == o2.toLowerCase();
    return o1?.toLowerCase() === o2?.toLowerCase();
  }

  // для [compareWith]='compareStringCONST'
  compareStringCONST(o1: string, o2: string, forTest?: string): boolean {
    // const forTestCondition: boolean = o2 == 'COMPETITIVE_YOUTH' || o2 == 'COMPETITIVE YOUTH'
    // console.log('forTestCondition :', o1, o2)
    // if (forTestCondition) {
    // console.log('= :', o1, o2); // Competitive Youth => COMPETITIVE_YOUTH
    // }
    if (!o1 || !o2) return false;
    let str1 = o1?.trim()?.replace(' ', '')?.replace('/', '')?.replace('_', '')?.replace('-', '')?.toLowerCase();
    str1 = str1?.replace(' ', '')?.replace('/', '')?.replace('_', '')?.replace('-', '')?.toLowerCase();
    str1 = str1?.replace(' ', '')?.replace('/', '')?.replace('_', '')?.replace('-', '')?.toLowerCase();
    let str2 = o2?.trim()?.replace(' ', '')?.replace('/', '')?.replace('_', '')?.replace('-', '')?.toLowerCase();
    str2 = str2?.replace(' ', '')?.replace('/', '')?.replace('_', '')?.replace('-', '')?.toLowerCase();
    str2 = str2?.replace(' ', '')?.replace('/', '')?.replace('_', '')?.replace('-', '')?.toLowerCase();
    const result = str1 == str2;
    // if (test) {
    // if (forTestCondition) {
    // console.log('result :', result)
    // }
    return result;
  }

  //   Витя ===================
  // removeAllSimvols(str: string): string {
  //   const regExp = '/[^a-zA-ZА-Яа-я0-9\\s]/';
  //   // let result  =  str?.trim()?.replaceAll(' ', '')?.replaceAll('/', '')?.replaceAll('_', '')?.replaceAll('-', '')
  //   let result = str?.trim()?.replace('/[^a-zA-ZА-Яа-я0-9\\s]/', '');
  //   return result;
  // }

  // для [compareWith]='compareBoolean'
  compareBoolean(o1: boolean, o2: boolean): boolean {
    if (!o1 || !o2) return false;
    return o1 == o2;
  }

  // === FORM ===========================
  //  если control invalid & touched => true
  checkInvalidTouched(control: AbstractControl): boolean {
    return control?.invalid && control?.touched;
  }


  // === OTHER ===============================================================
  // =================================================================================
  isLoadingNotification = false;

  showError(message: string, test?: string): void {
    this.showNotification(false, undefined, new HttpErrorResponse({ error: { message }, status: 203 }));
  }

  showNotification(type: boolean, res?: INewRequest, err?: HttpErrorResponse, forTest?: string): void {
    let status: 'success' | 'error' | 'blue' = 'success'; // 200 зелёным, 202 синим, остальное красным

    if (err?.status == 202) status = 'blue';
    if (err?.status && err?.status > 202) status = 'error';

    let message = type ? res?.message : (err?.error?.message || err?.message);

    if (err?.status && err?.status >= 500) {
      if (err.error.message === 'Apply limit for competition is reached') {
        message = selfRequestErrText;
      } else {
        message = err?.error?.errorDetails || errText500;
      }
    }

    if (this.isLoadingNotification || !message) return;
    this.isLoadingNotification = true;

    const forPromise = res ? res : err;
    new Promise(resolve => resolve(forPromise)).then(() => {
      this.isLoadingNotification = false;
      this.notificationS.passNote(message, { status, icon: 'check-circle', tittleErr: 'Request limit reached:' });
    });
  }

  //   delete глубокое клонирование массива объектов и объекта
  deepClone(data: Array<any> | object): any {
    // return JSON.parse(JSON.stringify(data));
    return _.cloneDeep(data);
  }

  // обновить страницу
  reload(): void {
    // window.location.reload();
    // @ts-ignore
    if (window.location) window.location = window.location.href.split('?')[0]; // чтобы удалить queryParams
  }

  locationBack(): void {
    this._location.back();
  }

  // приголится когда в queryParams передаем boolean, а приходит уже строка
  checkBoolean(condition: string | boolean | undefined): boolean {
    if (typeof condition == 'string') {
      return condition === 'true';
    } else {
      return !!condition;
    }
  }

  // directive dollar не срабатывает призагрузке страницы. Поэтому вставил этот метод из директивы
  forInputRefFee(feeRef: any): void {
    const prefix: '$' | '' = '$';
    const suffix: 'ETH' | '' = '';
    const x = feeRef?.nativeElement.value || '';
    let initialValue = x.replace(/[^0-9.]*/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');

    if (initialValue === '') {
      feeRef && (feeRef.nativeElement.value = '');
    } else {
      if (initialValue[0] === '.') {
        initialValue = '0' + initialValue;
      }
      const dot = initialValue.indexOf('.');
      if (dot !== -1) {
        initialValue = initialValue.substring(0, dot + 3);
      }
      feeRef && (feeRef.nativeElement.value = prefix + initialValue.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,') + suffix);
    }
  }

  getContentFromHtmlCode(str: string): string {
    if (!str) return '';
    if (str?.includes('<img')) return str;
    // let result = str.replaceAll('&nbsp;', '').trim()
    let result = str.replace(/&nbsp;/g, '').trim();
    let i1 = result.indexOf('<');
    let i2 = result.indexOf('>');

    if ((i1 != -1) && (i2 != -1)) {
      let u = result.slice(i1, i2 + 1);
      result = result.replace(u, '').trim();
      return this.getContentFromHtmlCode(result);
    } else {
      return result;
    }
  }

  setGreeting(): string {
    let myDate = new Date();
    let hrs = myDate.getHours();

    let greet = '';

    if (hrs < 12) greet = 'Good morning';
    else if (hrs >= 12 && hrs <= 17) greet = 'Good afternoon';
    else if (hrs >= 17 && hrs <= 24) greet = 'Good evening';
    return greet;
  }

  // === for GameOfficial ==================
  // !!! возвращаются GameOfficials в которых есть заасайненные судьи (используется для отображения в играх для админа)
  getAssignGO(game?: ClassGame): Array<ClassGameOfficial> {
    return game?.gameOfficials?.filter((el) => el.official) || [];
  }

  // !!! возвращаются НЕзаасаненные GameOfficials (используется для отображения в играх для судьи)
  getNoAssignGO(game?: ClassGame): Array<ClassGameOfficial> {
    return game?.gameOfficials?.filter((el) => !el.official) || [];
  }

  getSvgForGoStatus(go: ClassGameOfficial): TSvgName {
    if (go.status === 'ACCEPTED') return 'circle_chx3&20'; // !!! зеленая галочка в белом круге
    if (go.status === 'UNACCEPTED' && go.selfAssigned) return 'circle_emptyOrange&20'; // !!! оранжевый круг
    if (go.status === 'UNACCEPTED' && !go.selfAssigned) return 'circle_emptyBlack&20'; // !!! почти черный(очень серый) круг с синей обводкой
    if (go.status === 'DECLINED') return 'circle_crossRed&20';
    if (go.status === 'UNASSIGNED') return 'circle_empty&20'; // !!! белый круг с синей обводкой
    if (go.status === 'UNPUBLISHED') return 'circle_emptyGrey&20'; // !!! серый круг с синей обводкой
    return 'circle_empty&20'; /////////////////////////
  }

  // 'PENDING' // For official. Такого статуса нет, но нужно показывать PENDING если в GameOfficial selfAssigned==true & status=='UNACCEPTED'
  isPendingGoForOfficial(go?: ClassGameOfficial): boolean {
    if (!go) return false;
    return !!go.selfAssigned && go.status == 'UNACCEPTED';
  }

  // !!! for official. Получить ClassGameOfficial в котором заасайнен
  getMeGoFromGame(meId: string, game?: ClassGame): ClassGameOfficial | undefined {
    if (!game) return undefined;
    return game?.gameOfficials?.find((el) => el.official?.id == meId);
  }

  // === LOCATION ===================================
  editTimezone(timezone?: ITimezone): ITimezone {
    if (!timezone) return timezone!;
    return {
      ...timezone,
      abbrev: this.getStartEndLetterFromString(timezone?.abbrev!),
      name: this.getStartEndWordFromString(timezone?.name!),
    };
  }

  getLocNameCourtName(game?: ClassGame): string {
    if (!game) return '';
    const locationName = game.location?.name || 'TBD';
    const currentCourtName = game.location?.currentCourtName ? `- ${game.location?.currentCourtName}` : '';
    return `${locationName}  ${currentCourtName}`;
  }

  getLocationString(location?: ILocation, fields: Array<keyof ILocation> = ['street', 'city', 'state', 'zipcode']): string {
    if (!location) return '';
    const arrValues: Array<string> = [];
    fields.forEach(keyOfLoc => {
      const valueField: string = location[keyOfLoc]?.toString()?.trim() || '';
      if (keyOfLoc == 'state') {
        arrValues.push(this.getShortState(valueField));
        return;
      }
      if (valueField) arrValues.push(valueField);
    });
    return arrValues?.join(', ');
  }

  // return {Location Name}, {Address Line 1}, {Address Line 2}, {City}, {State} {Zip Code}
  getMatTooltipForLocation(game?: ClassGame): string {
    return UtilsService.getMatTooltipForLocation(game);
  }

  // === алгоритмы =====================

  // пересечение 2х массивов. Если есть, возвращается true
  existMatchingTwoArrays(arr1: Array<any>, arr2: Array<any>): boolean {
    return !!this.getMatchingTwoArrays(arr1, arr2).length;
  }

  // пересечение 2х массивов. Если есть, возвращается массив найденых элементов-пересечений
  getMatchingTwoArrays(arr1: Array<any>, arr2: Array<any>): Array<any> {
    return arr1.filter(x => arr2.indexOf(x) !== -1);
  }

  // === FOR FORM ======================
  // !!! example this.ctrlReset([this.ctrl.gameType, this.ctrl.location], true)
  // !!! или так example this.ctrlReset([this.ctrl.gameType, this.ctrl.location], [true,false]);
  ctrlReset(arrCtrl: Array<FormControl<any>>, isRequired: Array<boolean> | boolean = false,
    emitEvent: { emitEvent: boolean, onlySelf: boolean } | undefined = { emitEvent: false, onlySelf: true },
    updateValueAndValidity = false): void {
    arrCtrl?.forEach((el, idx) => {
      if (!el) return;
      el.reset(undefined, emitEvent);
      if (typeof isRequired == 'boolean' && isRequired) {
        el?.addValidators(Validators.required);
      } else if (!isRequired) {
      } else { // !!! для массива [true, false]
        if (isRequired[idx]) el?.addValidators(Validators.required);
      }
      if (updateValueAndValidity) el?.updateValueAndValidity();
    });
  }


  // === OTHER =================
  goToBack(): void {
    window.history.back();
  }

}

